import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { MenuIconWiz }  from './Menu-icon.styled';

import { toggleMenu, menuIcon, menuClose } from "./../../actions/index";

function mapDispatchToProps(dispatch) {
  return {
    toggleMenu: menu => dispatch(toggleMenu(menu)),
    menuIconUpdate: menu => dispatch(menuIcon(menu)),
    updateMenuClose: menu => dispatch(menuClose(menu))
  };
}

const mapStateToProps = state => {
  return {menuState: state.menu, iconState: state.menuIcon, loading: state.loading, menuClose: state.menuClose };
};

const MenuIcon = ( { isArticle, notFound, isPortfolio, toggleMenu, color, isWhite, isAbsolute, menu, menuState, menuIconUpdate, iconState, loading, menuClose, updateMenuClose } ) => {
  const  [ click, updateClick ] = useState( true )
  const  [ opened, updateOpened ] = useState( false )
  const  [ first, updateFirst ] = useState( false )
  const loc = localStorage.getItem('showLoader');
  const showMenu = () => {
    if( click ) {
      // console.log('click')
      toggleMenu( true )
      menuIconUpdate( true )
      updateOpened(false)
      updateClick(false)
    } else {
      // console.log('not click')
    }
  }

  useEffect(() => {

    const load = () => {
      if( loading ) {
        // console.log('load')
      } else {
        // console.log('hello')
        if(click) {
          // updateClick(false)
          setTimeout(() => {
            updateOpened( true )
            // console.log('hide')
            updateMenuClose(false)
          }, menuClose ? 0 : 3200)
  
          updateClick(true)
          // console.log('open')
        }
        // console.log('not load')
      }
    }
    load()

    return () => load()

  }, [menuState, click, first, loading, menuClose])

  useEffect(() => {
    let time = 3500

    if(isArticle) {
      time = 0
    }

    if(notFound) {
      time = 1700
    }

    if(loading === false) {
      updateOpened(false)
      setTimeout(() => {
        updateOpened( true )
      }, time)
    }
  }, [loading])

  return (
    <MenuIconWiz isPortfolio={isPortfolio} menuClose={menuClose} isOpened={opened} isAbsolute={isAbsolute} isWhite={isWhite} color={color} onClick={showMenu}>
      <svg width="42" height="22" viewBox="0 0 42 22">
        <g transform="translate(-962 -69)">
          <rect className="menu-1" width="42" height="6" transform="translate(962 69)" fill="#232328"/>
          <rect className="menu-2" width="32" height="6" transform="translate(962 85)" fill="#232328"/>
        </g>
      </svg>
    </MenuIconWiz>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuIcon);