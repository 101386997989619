import styled from 'styled-components';
import { osName } from 'react-device-detect';

export const TitleHomeDot = styled.span`
  color: ${ props => props.color};
  transition: 0.7s;
`

export const TitleHome = styled.h1`
  color: #fff;
  pointer-events: none;
  font-family: Europa, sans-serif;
  font-weight: 700;
  font-size: 5.2vw;
  z-index: 2;
  margin: 0;
  margin-bottom: 1vw;

  @media (max-width: 1023px) {
    display: ${ props => props.hideMobile ? 'none' : 'flex' };
    justify-content: center;
    line-height: initial;
    font-size: 8.88vw;
    padding: 15.4vw 0 8.3vw;
    width: 100%;
    line-height: 13vw;
  }
`

export const TitleWrap = styled.h1`
  position: ${ props => props.absolute ? 'absolute' : 'fixed' };
  top: ${ osName === 'Mac OS' ? '51vh' : '50vh'};
  left: 50vw;
  transform: translate(-52%, calc(-50% - 0.28vw));
  pointer-events: none;
  font-family: Intro, sans-serif;
  font-weight: 700;
  text-transform: ${ props => props.isTransform ? 'none' : 'uppercase' };
  font-size: 5.2vw;
  line-height: ${ osName === 'Mac OS' ? '8.2vw' : '4.3vw'};
  color: ${ props => props.color ? props.color : '#232328' };
  z-index: 2;
  margin: 0;
  padding: 0 0.26vw;

  &:before {
    position: absolute;
    width: 4vw;
    height: 1vw;
    content: '';
    background: #00EAEA;
    top: -2px;
    left: 2px;
    z-index: 2;
    display: none;
  }

  &.hide-line {
    &:before {
      display: block;
    }
  }

  @media (max-width: 1023px) {
    position: initial;
    transform: none;
    display: ${ props => props.hideMobile ? 'none' : 'flex' };
    justify-content: center;
    line-height: initial;
    font-size: 8.88vw;
    padding: 15.4vw 0 8.3vw;
    width: 100%;
    line-height: 13vw;
    ${
      props => props.notFound && `
        position: absolute;
        left: 0;
        top: 0;
        text-transform: lowercase;
      `
    }
  }

  &.start-animation {
    #center-circle {
        animation-name: signet;
        animation-duration: 0.7s;
        animation-fill-mode: forwards;
    
        @keyframes signet {
          0% {transform: rotate(0deg)}
          100% {transform: rotate(-180deg)}
        }
      
    }
  }
`

export const TitleSide = styled.span`
  position: absolute;
  top: ${ osName === 'Mac OS' ? '0.1vw' : '1.1vw'};
  left: 0;
  width: 40vw;
  margin-left: -40vw;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  opacity: ${props =>  props.titleShow ? 1 : 0};
  letter-spacing: ${props => props.lessLetterSpace ? '-0.2vw' : '0.2vw'};

  @media (max-width: 1023px) {
    position: initial;
    margin-left: 0;
    width: auto;
    right: initial;
    letter-spacing: initial;
  }

  div {
    display: inline-block;
    font-family: 'Europa';
    margin-top: ${ osName === 'Mac OS' ? '-0.7vw' : '-0.2vw'};
  }
`

export const TitleSideRight = styled.span`
  position: absolute;
  top: ${ osName === 'Mac OS' ? '0.1vw' : '1.1vw'};
  right: 0;
  width: 50vw;
  margin-right: -50vw;
  text-align: left;
  opacity: ${props =>  props.titleShow ? 1 : 0};
  letter-spacing: ${props => props.lessLetterSpace ? '-0.2vw' : '0.2vw'};

  span {
    // opacity: 0;
    position: relative;
    display: inline-block;
  }

  @media (max-width: 1023px) {
    position: initial;
    width: auto;
    margin-right: 0;
    right: initial;
    letter-spacing: 0;
  }
`

export const TitleMobile = styled.span`
  opacity: 0;
  animation-name: example;
  animation-duration: 0.1s;
  animation-fill-mode: forwards;

  @keyframes example {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  span {
    opacity: 0;
    position: relative;
    display: inline-block;

    ${
      props => props.notFound && `
        &:first-of-type {
          display: none;
        }
    
        &:first-of-type + span {
          text-transform: uppercase
        }
      `
    }
    
  }
`

export const TitleCenter = styled.span`
  position: relative;
  ${ props => props.notFound ? `
    display: block;
    transform-origin: 50% 45%;
  ` : ''}

  ${ props => props.notFound &&  osName !== 'Mac OS' ? `
    display: block;
    transform-origin: 50% 60%;
    font-size: 5vw;
  ` : ''}
  
  span {
    @media (max-width: 1023px) {
      display: none;
    }
  }

  strong {
    display: none;
    
    @media (max-width: 1023px) {
      display: block;
    }
  }
`