import styled from 'styled-components';

export const BackMobile = styled.div`
  position: fixed;
  left: 8.3vw;
  top: 8vw;
  z-index: 4;

  svg {
    width: 4.44vw;
    height: 4.44vw;
  }

  @media (min-width: 1023px){
    display: none;
  }
`