import styled from 'styled-components';

export const RodoContact = styled.div`
  top: 60%;
  position: fixed;
  left: 50vw;
  right: 13%;
  font-family: europa, sans-serif;
  color: #232328;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1023px) {
    position: initial;
    padding-left: 8.3vw;
    padding-right: 8.3vw;
    text-align: center;
  }
`;

export const RodoContactHalf = styled.div`
  width: 50%;

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 13.8vw;
  }

  p {
    font-size: 1.09375vw;
    line-height: 1.875vw;
    margin: 0;

    @media (min-width: 1800px) {
      font-size: 0.82vw;
      line-height: 1.4vw;
    }
    @media (max-width: 1023px) {
      font-size: 4.44vw;
      line-height: 8.5vw;
    }
  }

  strong, b, h1, h2, h3, h4, h5, h6 {
    font-size: 1.09375vw;
    line-height: 1.475vw;
    letter-spacing: 1.75px;
    margin: 0;

    @media (min-width: 1800px) {
      font-size: 0.82vw;
      line-height: 1.1vw;
    }
    @media (max-width: 1023px) {
      font-size: 4.44vw;
      line-height: 5.5vw;
      font-weight: 700
    }
  }

  a {
    color: #232328;
    text-decoration: none;
  }
`;

export const RodoContactFull = styled.div`
  width: 100%;
  margin-top: 1.5vw;

  @media (max-width: 1023px) {
    margin-bottom: 13.8vw;
  }

  p {
    font-size: 0.9375vw;
    line-height: 1.875vw;
    margin: 0;

    @media (max-width: 1023px) {
      font-size: 3.88vw;
      line-height: 5.5vw;
    }
  }
  
`;