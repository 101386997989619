import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import MainTitle from '../../components/ui/title/title';
import Routes from '../../routes/routes';
import MenuIcon from '../../components/menu-icon/menu-icon';
import TextMobile from '../../components/text-mobile/text-mobile';
import { BG } from '../../components/grid/bg/bg.style';
import TextDesktopInfo from '../../components/text-desktop-info/text-desktop-info';
import { Container } from '../../components/grid/container/Container.styled';
import { MIN_ANIMATION } from '../../config/config';
import SEO from '../../components/seo/seo';
import Cookies from '../../components/cookies/Cookies';

const mapStateToProps = state => {
  return {colors: state.colors, production: state.production, onScrollProduction: state.onScrollProduction};
};

const Production = ({colors, production, onScrollProduction}) => {
  return (
    <BG 
      id="wrapBg"
      color={ colors.produkcja ? colors.produkcja.color : '' }
      forMobileMiddle={true}
      overflow="hidden"
    >
      <div className="menu-production">
        <MenuIcon
          color={ colors.produkcja ? colors.produkcja.color : '' }
        />
      </div>
      
      <div
          className="contact-production animated fadeInLeft faster" 
          style={{
            animationDelay: `${MIN_ANIMATION + 0.5}s`,
            zIndex: 4,
            position: 'fixed',
          }}
      ><Link style={{animationDelay: `${MIN_ANIMATION + 0.7}s`}} className="contact-button--animation contact-button contact-button--dark" exact to={Routes.kontakt.slug}>kontakt</Link>
      </div>
      <div  className="title-production">
        <MainTitle
          absolute={true}
          delay={onScrollProduction ? 0.3 : 0}
          left='m'
          right='ŻNA?&nbsp;MOŻNA!'
        />
      </div>
      
      {production.length > 0 && 
        <Container>
          <TextMobile list={production} />
          <TextDesktopInfo spaceBetween={70} list={production} />
        </Container>
      }
      <Cookies
        fixed={true}
        color={'#232328'}
        background={colors.produkcja ? colors.produkcja.color : ''}
      />
    </BG>
  )
}

export default connect(mapStateToProps, null)(Production);