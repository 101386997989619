import { createGlobalStyle } from 'styled-components';


export const AmicaGlobal = createGlobalStyle`

  .amica-section {

    &__folder {
      margin-bottom: 7.8125vw;
    }

    &__folder-img1 {
      margin-top: 7.8125vw;
      margin-right: -100px; 
    }
    /*  */

    &__printy {
      margin-bottom: 7.8125vw;
    }

    &__printy-text {
      text-align: right !important;
    }

    &__printy-img1 {
      margin-top: 7.8125vw;
      margin-right: -40px;
    }

    &__printy-img2 {
      margin-top: 2.8125vw;
    }


    &__outdoor {
      margin-bottom: 7.8125vw;
    }

    &__outdoor-img1 {

    }


    &__pos {
      margin-bottom: 6.56vw;
    }

    &__pos-text {
      text-align: right;
    }

    &__pos-img1 {
      margin-top: 3.645vw;
    }

    &__pos-img2 {
      margin-top: 8.2vw;
      margin-right: 100px;

      @media (max-width: 1023px){
        margin-right: 0px;
      }
    }


    &__motion {
      margin-bottom: 11.45vw;

      @media (max-width: 1023px){
        margin-bottom: 0px;
      }
    }

    &__opakowania {
      margin-bottom: 7.8125vw;
    }

    &__opakowania-text {
      text-align: right !important;
    }

    &__opakowania-img {
      margin-top: 5.2vw;
      margin-right: -20px;
    }

  }

`