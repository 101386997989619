import styled from 'styled-components';
import { osName } from 'react-device-detect';


export const ArticleImg = styled.img`
  width: 100%;
  padding-bottom: 1.375vw;
`

export const ArticleText = styled.div`
  padding-bottom: 1.375vw;
  font-family: europa, sans-serif;
  text-align: ${ props => props.right ? 'right' : 'left' };
  color: ${ props => props.color ? props.color : '#232328' };

  @media (max-width: 1023px){
    text-align: center;
  }

  p, ul, li {
    font-size: 1.09375vw;
    line-height: 1.875vw;
    letter-spacing: 1.75px;

    @media (min-width: 1800px) {
      font-size: 0.82vw;
      line-height: 1.4vw;
    }

    @media (max-width: 1023px){
      font-size: 3.88vw;
      line-height: 5.5vw;
    }
  }

  h1,h2,h3,h4,h5,h6 {
    font-size: 1.875vw;
    line-height: 2.29vw;
    letter-spacing: 2px;

    @media (max-width: 1023px){
      font-size: 5.5vw;
      line-height: 6.9vw;
    }
  }
`

export const ArticleTitle = styled.div`
  padding-bottom: ${ props => props.hasCharts ? '3.5vh' : '3.5vh' };
  margin-bottom: ${ props => props.hasCharts ? '-3.125vw;' : '0'};

  @media (max-width: 1023px){
    padding-bottom: 0;
  }
`

export const ArticleTitleWrap = styled.div`
  ${ props => props.hasCharts ? `
    // padding-right: 5.2vw;
    // padding-top: 15vh;
    position: absolute;
    padding-right: 5.2vw;
    padding-top: 10vh;
  ` : `padding-top: 0 vh;`}
  ${ props => props.isAuthor ? 'padding-top: 0;' : ''};
  color: ${ props => props.color ? props.color : '#232328' };

  @media (max-width: 1023px){
    padding-top: 0px;
    padding-right: 0;
    text-align: center;
    position: relative;
  }
`

export const ArticleTitleSecond = styled.h3`
  font-family: europa, sans-serif;
  font-weight: 700;
  font-size: 1.875vw;
  line-height: 2.29vw;
  letter-spacing: 2px;
  margin-bottom: 0;

  @media (max-width: 1023px){
    font-size: 5.5vw;
    line-height: 6.9vw;
  }
`

export const ArticleTitleTitle = styled.h1`
  font-family: europa, sans-serif;
  font-weight: 700;
  font-size: 3.22vw; //62px
  line-height: 3.33vw; //64px
  margin-bottom: 0;
  /* margin-bottom: 1vw; //80px */
  ${ props => props.isAuthor ? 'margin-top:0;' : ''}


  @media (max-width: 1023px){
    font-size: 7.88vw;
    line-height: 10.5vw;
    margin-bottom: 4.88vw;
    margin-top: -2vw;
  }

  h1 {
    ${ props => props.isAuthor ? 'margin-top:7px;' : ''}
    font-family: europa, sans-serif;
    font-weight: 700;
    font-size: 3.22vw; //62px
    line-height: 3.33vw; //64px
    margin-bottom: 1vw; //80px

    @media (max-width: 1023px){
      font-size: 7.88vw;
      line-height: 10.5vw;
      margin-bottom: 4.88vw;
      margin-top: -10vw;
    }
  }
`

export const ArticleTitleDescription = styled.p`
  font-family: europa, sans-serif;
  font-size: 1.09375vw;
  line-height: 1.875vw;
  letter-spacing: 1.75px;

  @media (min-width: 1800px) {
    font-size: 0.82vw;
    line-height: 1.4vw;
  }

  @media (max-width: 1023px){
    font-size: 3.88vw;
    line-height: 5.5vw;
    margin-bottom: 9.88vw;
  }
`
export const ArticleTitleDonuts = styled.div`
  display: flex;
  /* justify-content: space-around;
  padding-top: 3.3vw; */
  padding-bottom: 3.3vw;
  justify-content: space-around;
  padding-top: calc(50vh - 3.3vw);
  // padding-top: calc(${ osName === 'Mac OS' ? '50vh - 3.3vw' : '57vh - 3.3vw'});
  
  @media (max-width: 1023px){
    padding-top: 5.27vw;
    padding-bottom: 8.3vw;
  }
`

export const ArticleFirstScreen = styled.div`
  padding-top: 5.2vw;
  height: calc(100vh - 5.2vw - 15vh);
`

export const ArticleBottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3vw;
`

export const ArticleTop = styled.div`
  font-family: europa, sans-serif;
  font-size: 1.09375vw;
  line-height: 1.875vw;
  letter-spacing: 1.75px;
  font-weight: 300;
  padding-top: 18.3vh;

  @media (min-width: 1800px) {
    font-size: 0.82vw;
    line-height: 1.4vw;
  }

  @media (max-width: 1023px){
    font-size: 3.88vw;
    line-height: 5.5vw;
    margin-bottom: 9.88vw;
    text-align: center;
    padding-top: 0;
  }
`

export const ArticleLogo = styled.img`
  max-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0px, -56%);
  left: 11.625%;
  text-align: right;
  margin: 0;
  max-width: 60%;

  ${
    props => props.mobile && `
      position: relative;
      max-height: 100px;
      top: 0;
      left: 0%;
    `
  }
`

export const GoldenArrow = styled.div`
  display: flex;
  padding-top: 18.3vh;
  @media (min-width: 1023px) {
    margin-bottom: -11.3vh;
  }
  @media (max-width: 1023px) {
    padding-top: 0vh;
    flex-wrap: wrap;
  }
`

export const GoldenArrowWrapper = styled.div`
  padding-left: 1vw;

  @media (max-width: 1023px) {
    text-align: center;
  }
`
export const GoldenArrowImage = styled.img`
  width: 100%;
  margin-left: -10%;

  @media (max-width: 1023px){
    margin-left: 0;
    width: 50%;
    margin: 0 auto;
  }
`

export const GoldenArrowDescription = styled.p`
  font-family: europa, sans-serif;
  font-weight: 500;
  font-size: 1.3125vw;
  line-height: 2.25vw;
  letter-spacing: 1.75px;
  margin-bottom: 0vw;
  margin-top: 0.4vw;

  @media (min-width: 1800px) {
    font-size: 0.984vw;
    line-height: 1.68vw;
  }

  @media (max-width: 1023px){
    font-size: 4.656vw;
    line-height: 6.6vw;
    margin-bottom: 9.88vw;
  }
`

export const GoldenArrowTitleDescription = styled.p`
  font-family: europa, sans-serif;
  font-size: 1.09375vw;
  line-height: 1.875vw;
  letter-spacing: 1.75px;
  margin: 0;

  @media (min-width: 1800px) {
    font-size: 0.82vw;
    line-height: 1.4vw;
  }

  @media (max-width: 1023px){
    font-size: 3.88vw;
    line-height: 5.5vw;
  }
`

export const GoldenArrowImageWrapper = styled.div`
  width: 17%;
  @media (min-width: 1800px){
      width: 14%;
  }
  @media (max-width: 1023px){
    width: 100%;
    text-align: center;
  }
`

export const GoldenArrowTitle = styled.div`
  font-family: europa, sans-serif;
  font-weight: 700;
  font-size: 3.22vw; //62px
  line-height: 3.33vw; //64px
  margin-bottom: 0.4vw;
  margin-top: 0.4vw;
  /* margin-bottom: 1vw; //80px */

  @media (max-width: 1023px){
    font-size: 7.88vw;
    line-height: 10.5vw;
  }
`
