

import React from 'react';

const Brita = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="256.696" height="53.657" viewBox="0 0 256.696 53.657">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_23" data-name="Rectangle 23" width="256.696" height="53.657" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_115" data-name="Group 115" clip-path="url(#clip-path)">
    <path id="Path_114" data-name="Path 114" d="M56.9,65.39a8.536,8.536,0,0,0,0-17.072,8.536,8.536,0,1,0,0,17.072" transform="translate(-11.733 -11.734)" />
    <path id="Path_115" data-name="Path 115" d="M32.7,65.39a8.536,8.536,0,0,0,0-17.072,8.536,8.536,0,1,0,0,17.072" transform="translate(-5.857 -11.734)" />
    <path id="Path_116" data-name="Path 116" d="M8.395,65.39a8.537,8.537,0,1,0,0-17.072,8.537,8.537,0,0,0,0,17.072" transform="translate(0 -11.734)" />
    <path id="Path_117" data-name="Path 117" d="M56.9,17.074A8.593,8.593,0,0,0,65.39,8.443a8.537,8.537,0,0,0-17.072,0A8.611,8.611,0,0,0,56.9,17.074" transform="translate(-11.733 0)" />
    <path id="Path_118" data-name="Path 118" d="M32.742,17.072a8.532,8.532,0,0,0,8.489-8.63,8.537,8.537,0,0,0-17.072,0,8.611,8.611,0,0,0,8.583,8.63" transform="translate(-5.867 0)" />
    <path id="Path_119" data-name="Path 119" d="M8.395,17.074a8.632,8.632,0,0,0,8.678-8.631A8.534,8.534,0,0,0,8.395,0,8.351,8.351,0,0,0,0,8.443a8.453,8.453,0,0,0,8.395,8.631" transform="translate(0 0)" />
    <path id="Path_120" data-name="Path 120" d="M56.9,41.233A8.593,8.593,0,0,0,65.39,32.6a8.537,8.537,0,0,0-17.072,0A8.611,8.611,0,0,0,56.9,41.233" transform="translate(-11.733 -5.867)" />
    <path id="Path_121" data-name="Path 121" d="M32.742,41.231a8.532,8.532,0,0,0,8.489-8.63,8.537,8.537,0,0,0-17.072,0,8.611,8.611,0,0,0,8.583,8.63" transform="translate(-5.867 -5.867)" />
    <path id="Path_122" data-name="Path 122" d="M8.395,41.233A8.632,8.632,0,0,0,17.072,32.6a8.534,8.534,0,0,0-8.678-8.443A8.351,8.351,0,0,0,0,32.6a8.453,8.453,0,0,0,8.395,8.631" transform="translate(0 -5.867)" />
    <path id="Path_123" data-name="Path 123" d="M242.88,31.062l6.255-16.619,6.349,16.619ZM204.32,53.1H222.9l.84-2.708c-2.988-.093-4.575-1.214-4.575-4.762V5.947h9.8c2.8,0,3.267.28,3.827,1.587h2.147l1.774-6.442H196.01l-1.96,6.442h2.334c1.214-1.307,2.521-1.587,4.762-1.587h8.59V45.721c0,2.614-1.588,4.481-4.575,4.668Zm-30.064,0h18.58l.84-2.708c-3.454-.093-5.321-1.214-5.321-4.762V8.468c0-2.614,1.307-4.482,3.921-4.668l.84-2.708H174.443L173.6,3.8c3.454.093,5.322,1.214,5.322,4.761v37.16c0,2.614-1.308,4.481-3.828,4.668ZM142.325,25.647V8.374c0-2.427.934-3.361,3.548-3.361,5.882,0,9.43,4.108,9.43,10.27a10.13,10.13,0,0,1-9.9,10.363ZM128.227,53.1h18.58l.84-2.708c-3.455-.093-5.322-1.214-5.322-4.762V29.475h.653c2.521,0,3.362.934,4.015,2.147l5.415,10.363c3.547,6.722,5.6,8.776,8.309,11.017l.187.093h10.737l.84-2.708c-3.361-.373-6.162-3.641-10.643-11.951l-4.108-7.563a13.212,13.212,0,0,0-2.8-3.734c4.855-1.4,10.83-5.7,10.83-12.7,0-8.776-7.75-13.351-18.113-13.351H128.414l-.84,2.708c3.455.093,5.322,1.214,5.322,4.761v37.16c0,2.614-1.307,4.481-3.828,4.668ZM100.5,23.966V8.468c0-2.521.934-3.454,3.548-3.454,5.415,0,8.216,3.827,8.216,9.616,0,5.7-3.267,9.337-8.029,9.337ZM86.4,53.1h19.327C117.77,53.1,125.8,47.027,125.8,37.5c0-6.255-5.508-10.83-11.11-12.23,3.827-2.054,7.189-5.976,7.189-11.017,0-9.15-7.1-13.164-17.272-13.164H86.586L85.746,3.8c3.454.093,5.321,1.214,5.321,4.761v37.16c0,2.614-1.307,4.481-3.827,4.668Zm18.579-4.108c-2.708,0-4.482-1.494-4.482-4.481V27.887h4.576c6.255,0,9.99,3.455,9.99,10.084,0,6.815-3.921,11.017-9.8,11.017ZM225.794,53.1h14.659l.84-2.708c-2.894-.187-4.2-.934-4.2-2.8a12.957,12.957,0,0,1,1.121-3.828l2.988-7.936h16.246l3.454,8.5a12.383,12.383,0,0,1,1.121,3.641c0,1.307-.934,2.24-3.361,2.334l-.84,2.8h18.86l.84-2.708c-3.175-.373-4.3-1.587-6.723-7.656L253.9,1.092h-4.762L233.356,42.546c-1.96,5.135-3.268,7.469-6.722,7.843Z" transform="translate(-20.822 -0.265)" />
  </g>
</svg>
  )
}

export default Brita
