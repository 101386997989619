import styled from 'styled-components';

export const Wrap = styled.div`
  position: relative;
  pointer-events: none;
  font-family: Intro, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 3vw;
  line-height: 3.2vw;
`

export const Side = styled.div`
  position: absolute;
  top: -1px;
  left: 0;
  width: 30vw;
  margin-left: -30vw;
  text-align: right;
`

export const SideRight = styled.div`
  position: absolute;
  top: -1px;
  right: 0;
  width: 30vw;
  margin-right: -30vw;
  text-align: left;
`

export const Center = styled.div`
  position: relative;

  span {
    display: none;
  }
`