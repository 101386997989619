import React from 'react';

function Nivea () {
  return (
    <svg version="1.0" id="NIVEA_Logo_2020" xmlns="http://www.w3.org/2000/svg" x="0px"
	 y="0px" width="183" height="45" viewBox="0 0 183 45.3" enable-background="new 0 0 183 45.3">
      <g id="NIVEA_x5F_Logo_x5F_2020">
        <path id="NIVEA_wordmark_2020"  d="M36.4,2.3h-10v21.2C17.7,15.7,8.9,7.8,0,0v43h10V21.8
          c8.3,7.4,17.3,15.7,26.4,23.5V2.3z M58.6,43h-10V2.3h10V43z M84.5,45.3C77.4,32,70.3,16.7,63.2,2.3h11.2l10,22l9.6-22h11
          C98.2,16.7,91.3,32,84.5,45.3z M136.6,11.2h-16.9v7h16.9v8.9h-16.9v7h16.9V43h-26.9V2.3h26.9V11.2z M162,0
          c-6.9,13.6-13.7,28.2-20.6,43h10.4l2.1-4.9h16.2l2.1,4.9H183C175.9,28.2,168.8,13.3,162,0z M156.8,30.5l5.2-11.9l5,11.9H156.8z"/>
      </g>
    </svg>
  )
}

export default Nivea;