import styled from 'styled-components';

export const VideoWrap = styled.div`
  position: relative;
  margin-right: ${props => props.production ? '0' : '5.2vw'};;
  margin-top: ${props => props.nonMarginable ? '0' : '60px'};
  z-index: 3;
  margin-left: ${props => props.production ? '0' : '3.64vw'};
  overflow: hidden;
  padding-top: ${props => props.nonMarginable ? '50%' : '56.25%'};
  ${props => props.width && `
    width: 100%;
  `}
  @media (max-width: 1023px){
    margin: ${props => props.nonMarginable ? '0' : '16.66vw'} ${props => props.production ? '0' : '8.33vw'};
  }
`;

export const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

export const PlayVideo = styled.button`
  font-variant-ligatures: none;
`;

export const FullScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
`;