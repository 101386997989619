import React, { useState, useEffect } from 'react';
import slugify from 'slugify';
import { withRouter } from 'react-router-dom'
import { Link } from "react-router-dom";

import { More } from '../ui/more/more.styled';
import MainTitle from '../ui/title/title';
import { TextMobileWrapper, TextMobileItem, TextMobileContent } from './text-mobile.styled';
import { Container } from '../grid/container/Container.styled';
import { ShowMobile } from '../grid/showHide/showHide.style';
import { ShowHideButton, ShowHideButtonInside, ShowHideButtonInsideState } from '../ui/show-hide-button/show-hide-button.styled';
import { MIN_ANIMATION } from '../../config/config';
import Hrefs from '../hrefs/hrefs';
import ProductionSection from '../produkcja/produkcja';
import Routes from '../../routes/routes';

const TextMobile = ( {list, history, contact} ) => {
  const [position, updatePosition] = useState(false);
  let elements = React.createRef();
  let url = window.location.pathname.split('/')

  const setActiveSlideText = () => {
    
    const childs = list
    let current = 0
    childs.map((el, i) => {
      if( slugify(el.title, '-') === url[2] ) {
        current = i;
      }
    })
    const offfsetTop = elements.current.children[current].offsetTop
    if( current > 3) {
      window.scrollTo({
        top: offfsetTop,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    
    updatePosition(current)
  }

  useEffect(()=> {
    setActiveSlideText()
    let url = window.location.pathname.split('/')

    if (url[1] === Routes.produkcja.flat) {
      document.getElementById('wrapBg').classList.add('production-section')
    }
  }, [])

  const changePosition = (el) => {
    const id = parseInt(el.target.dataset.id)
    let pos = false
    if ( id === parseInt(position) ) {
      updatePosition(false)
      pos = false
    } else {
      pos = parseInt(el.target.dataset.id)
      updatePosition(parseInt(el.target.dataset.id))
      if(contact &&  el.target.dataset.slug == '/kontakt/kontakt') {
        history.push('/kontakt/')
      } else {
        history.push(`${el.target.dataset.slug}`)
      }
      
    }

    let url = window.location.pathname.split('/')

    if(url[1] === Routes.produkcja.flat) {
      if(pos === false) {
        document.getElementById('wrapBg').classList.remove('production-section')
      } else if(id === 0 && position === 0) {
        document.getElementById('wrapBg').classList.add('production-section')
      } else {
        document.getElementById('wrapBg').classList.remove('production-section')
      }
    }
  }
  return (
    <ShowMobile>
      {contact && (
          <MainTitle 
            left={position == 0 ? 'P' : ''}
            right={position == 0 ? 'ROZMAWIAJMY!' : ''}
          />
        )}
      <Container forMobileAutoHeight={true}>
        <TextMobileWrapper
          ref={elements}
        >
          {
            list.map((el, i) => {
              let l = MIN_ANIMATION + 0.1 + (i /10)
              return (
                <TextMobileItem
                  key={i}
                  className="carousel__item animated fadeInUp faster"
                  style={{
                    animationDelay: `${l}s`
                  }}
                >
                  <ShowHideButton>
                    <ShowHideButtonInside
                      className="show-hide-mobile-produkcja"
                      data-id={i}
                      onClick={changePosition}
                      data-slug={`/${url[1]}/${slugify(el.title, '-')}`}
                    >
                      {el.title}
                      <ShowHideButtonInsideState 
                        active={position === i}
                      />
                    </ShowHideButtonInside>
                  </ShowHideButton>
                  <TextMobileContent
                    active={position === i}
                    >
                    {(position === 0 && url[1] === Routes.produkcja.flat ) && <ProductionSection />}
                    
                    { (position > 0 || url[1] !== Routes.produkcja.flat ) && <div style={{padding: '0 8.333333333vw'}}>
                      {!contact && <Hrefs  text={el.descriptio} />}
                      {contact && el.descriptio}
                    </div>}
                    {el.link && <More 
                    className="more-produkcja"
                    dontHideBr={true} color="#005FFF">
                      <Link
                        to={el.link}>czytaj dalej! <svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
                        <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={'#005FFF'}/>
                      </svg>
                      </Link>
                    </More>
                    }
                  </TextMobileContent>
                </TextMobileItem>
              )
            })
          }
        </TextMobileWrapper>
      </Container>
    </ShowMobile>
  )
}

export default withRouter(TextMobile);