import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from "react-ga";

import { ContactButton } from './components/ui/contact-button/contact-button.styled';
import Menu from './components/menu/menu';
import { randColors } from './colors/colors';
import { loading, addColors, articles, pomarancza, production, rodo, careers, aboutUsList, landings, contact, intro, clients } from "./actions/index";
import Routes from './routes/routes';
import { API_URL, API_ARTICLES, API_LANDINGS, API_PAGES, API_CLIENTS } from './config/config';
import { Overflow } from './components/overflow/overflow.styled';
import '../node_modules/animate.css/animate.min.css'
import Loading from './components/loading/loading';
import { Landscape } from './components/landscape/landscape';

function mapDispatchToProps(dispatch) {
  return {
    addColors: colors => dispatch(addColors(colors)),
    articles: colors => dispatch(articles(colors)),
    pomarancza: colors => dispatch(pomarancza(colors)),
    production: colors => dispatch(production(colors)),
    rodo: colors => dispatch(rodo(colors)),
    careers: colors => dispatch(careers(colors)),
    clients: items => dispatch(clients(items)),
    aboutUsList: items => dispatch(aboutUsList(items)),
    loadingNewState: items => dispatch(loading(items)),
    landings: items => dispatch(landings(items)),
    contact: items => dispatch(contact(items)),
    intro: items => dispatch(intro(items))
  };
}

const mapStateToProps = state => {
  return { loadingState: state.loading};
};

ReactGA.initialize("UA-46376319-1");

const GATerm = () => {
  let location = useLocation();

  useEffect(() => {
    // console.log('listean')
    // console.log(location.pathname)
    ReactGA.pageview(location.pathname)
  }, [location])

  return false
}

const App = ( { addColors, articles, pomarancza, production, rodo, careers, aboutUsList, loadingState, loadingNewState, landings, contact, intro, clients } ) => {
  const [showLoader, updateShowLoader] = useState(false)
  const [math, updateMath] = useState(false)

  useEffect(() => {
    
    const loc = localStorage.getItem('showLoader');
    if (!loc) {
      updateShowLoader(true)
      localStorage.setItem('showLoader', true);
    } else {
      updateShowLoader(true)
      setTimeout(() => {
        updateShowLoader(true)
      }, 500)
      setTimeout(() => {
        loadingNewState(false)
      }, 500)
      localStorage.setItem('showLoader', false);
    }
  }, [])

  useEffect(() => {
    var x = new XMLHttpRequest();
    x.open("GET", "/sitemap.xml", true);
    x.onreadystatechange = function () {
      if (x.readyState == 4 && x.status == 200)
      {
        var doc = x.responseXML;
        const el = doc.getElementsByTagName("loc")

        for(let i = 0; i < el.length; i++) {
          const path = new URL(el[i].innerHTML).pathname
          const currentPath = window.location.pathname
          if(path === currentPath) {
            updateMath('math')
          }
        }
      }
    };
    x.send(null);
  },[])

  useEffect(() => {
    window.prevUrl = window.location.pathname
  })

  useEffect(() => {

    let vh = window.innerHeight * 0.01;
    let vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${parseInt(vw)}px`);
    document.documentElement.style.setProperty('--fvw', `${parseInt(window.innerWidth)}`);

    window.addEventListener('resize', () => {
      let vw = window.window.innerWidth * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--vw', `${parseInt(vw)}px`);
      document.documentElement.style.setProperty('--fvw', `${parseInt(window.innerWidth)}`);
    });

    window.addEventListener('orientationchange', () => {
      setTimeout(() => {
        let vh = window.innerHeight * 0.01;
        let vw = window.innerWidth * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.documentElement.style.setProperty('--vw', `${parseInt(vw)}px`);
        document.documentElement.style.setProperty('--fvw', `${parseInt(window.innerWidth)}`);
      }, 100)
    })

    async function api(endpoint) {
      const response = await fetch(`${API_URL + endpoint}`)
    
      return response.json()
    }

    const getLandings = async () => {
      const response = await api(API_LANDINGS)
      const landings_list = []
      for(let i = response.length - 1; i >= 0; i--) {
        landings_list.push(response[i].acf)
      }
      landings(landings_list);
    }
    
    const getClients = async () => {
      let response = await api(API_CLIENTS)
      const clients_list = []
      response = response.reverse()
      for(let i = 0; i < response.length ; i++) {
        clients_list.push(response[i])
      }
      clients(clients_list);
    }

    const getArticles = async () => {
      let response = await api(API_ARTICLES)
      const articles_list = []
      response = response.slice().sort(function(a,b){
        const aDate = a.acf.date.split('.')
        const bDate = b.acf.date.split('.')
        return new Date(`${bDate[1]}.${bDate[0]}.${bDate[2]}`) - new Date(`${aDate[1]}.${aDate[0]}.${aDate[2]}`);
      });
      
      for(let i = 0; i < response.length ; i++) {
        articles_list.push(response[i])
      }
      articles(articles_list);
    }

    const getPages = async () => {
      const response = await api(API_PAGES)
      for(let i = 0; i < response.length; i++) {
        const id = response[i].id
        switch (id) {
          case 45: // AboutUS
            let newArr = []
            for(let k = 0; k < response[i].acf.persons.length / 4 ; k++ ) {
              for(let j = 0; j < response[i].acf.persons.length ; j++) {
                newArr.push(response[i].acf.persons[j])
              }
            }
            aboutUsList({
              description: response[i].acf.description,
              seo: response[i].acf.seo,
              persons: newArr
            });
            break
          case 36: // Careers
            careers(response[i].acf)
            break
          case 13: // Kompetencje/Pomarancza
            pomarancza(response[i].acf.texts);
            break;
          case 15: //Production
            production(response[i].acf.texts);
            break;
          case 85: //RODO
            rodo(response[i].acf);
            break;
          case 437: //437
            intro(response[i].acf);
            break;
          case 435: //Contact
            contact(response[i].acf);
            break;
          default:
            console.warn(`API WARN: Not found Redux function for ${response[i].id}:`, response[i])
        }
      }
    }

    getPages()
    getArticles()
    getLandings()
    getClients()
  }, []);

  useEffect(() => {
    const subscription = addColors( randColors() );
    return () => {
      subscription();
    }
  }, [addColors]);
  return (
    <Router>
      { (showLoader && math) ? <Loading /> : '' }
      <Overflow />
      <Menu/>
      <GATerm />
      <ContactButton/> 
      <Landscape />
      <Switch>
        {
          Object.keys(Routes).map(el => {
            return (
              <Route exact={Routes[el].exact} key={Routes[el].id} path={Routes[el].slug} component={Routes[el].component} />
            )
          })
        }
      </Switch>
    </Router>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
