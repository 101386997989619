import styled from 'styled-components';


export const ShowHideButton = styled.div`
  font-family: europa, sans-serif;
  font-weight: 700;
  font-size: 5.55vw;
  line-height: 9vw;
  ${props => props.career && `
    text-align: center;
    padding: 15vw 0 11.11vw;
    display: none;

    @media (max-width: 1023px){
      display: block;
    }
  `}
`

export const ShowHideButtonInside = styled.button`
  position: relative;
  apperance: none;
  border: none;
  background: transparent;
  color: #232328;
  font-variant-ligatures: none;

  @media (max-width: 1023px){
    font-weight: 700;
    padding: 0 6vw;
    line-height: 9vw;
  }
`
export const ShowHideButtonInsideState = styled.div`
  position: absolute;
  top: -1%;
  right: 3vw;
  color: #005FFF;
  pointer-events: none;

  &:after {
    position: absolute;
    content: '+';
    width: 2.77vw;
    height: 0.55vw;
    opacity: ${ props => props.active ? '0' : '1' };
  }

  &:before {
    position: absolute;
    content: '–';
    transition: .3s;
    opacity: ${ props => props.active ? '1' : '0' };
  }

  &.active {
    &:before {
      opacity: 0;
    }
  }
`