import styled from 'styled-components';

export const BlockQuoteContainer = styled.div`
  display: flex;
  width: 100%;
  font-family: europa, sans-serif;
  flex-wrap: wrap;
`

export const BlockQuoteContent = styled.div`
  color: #F9F8F6;
  font-size: 1.04vw; //20px
  line-height: 1.82vw; //35px
  letter-spacing: 1.60px;
  font-style: italic;
  width: 100%;
  text-align: ${ props => props.isRight ? 'left' : 'right'};
  padding-left: ${ props => props.isRight ? '0' : '20%'};

  @media (max-width: 1023px){
    text-align: center;
    padding-left: 0;
  }

  @media (max-width: 1023px){
    font-size: 3.88vw;
    line-height: 5.55vw;
    padding-left: 0;
    color: ${props => props.color ? props.color : '#F9F8F6'};
  }
`

export const BlockQuoteAutorName = styled.div`
  font-weight: 700;
  ${
    props => props.isProduction ? '' : `
      @media (max-width: 1023px){
        color: #fff;
      }
    `
  }
  
`

export const BlockQuoteAutor = styled.div`
  margin-top: 1.82vw;
  width: 100%;
  
  color: ${props => props.color ? props.color : '#F9F8F6'};

  @media (max-width: 1023px){
    margin-top: 9.72vw;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  svg {
    padding-right: 0.83vw;
    width: 2.89vw;
    height: 1.95vw;

    @media (max-width: 1023px){
      padding-right: 0;
      width: 37px;
      height: 25px;

      g {
        fill: #fff;
      }

      path {
        stroke: #fff;
      }
    }
  }
`

export const BlockQuoteCompany = styled.div`
  font-weight: 300;
  display: flex;
  width: 100%;
  
  justify-content: flex-start;
`

export const BlockQuoteAutorWrap = styled.div`
  display: inline-block;
  float: right;
  justify-content: flex-end;
  font-size: 1.09vw;
  line-height: 1.61vw;
  letter-spacing: 1.75px;

  @media (max-width: 1023px){
    font-size: 3.88vw;
    line-height: 5.55vw;
    text-align: center;
  }
`;