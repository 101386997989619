import React from 'react';

const Dots = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="11" viewBox="0 0 44 11">
      <g data-name="Group 213" transform="translate(-426 -601.406)">
        <circle cx="5.5" cy="5.5" r="5.5" data-name="Ellipse 4" transform="translate(426 601.406)"/>
        <circle cx="5.5" cy="5.5" r="5.5" data-name="Ellipse 5" transform="translate(443 601.406)"/>
        <circle cx="5.5" cy="5.5" r="5.5" data-name="Ellipse 6" transform="translate(459 601.406)"/>
      </g>
    </svg>
  )
}

export default Dots