import React, { useState, useEffect }  from 'react'

import ImgSection from './../img-section/Img-section';
import * as Styled from './imgSlider.styled';

const ImgSlider = ({images, time}) => {
  const [slide, updateSlide] = useState(null);

  const slider = React.createRef()

  useEffect(()=> {
    const children = slider.current.children
    const length = children.length
    let currentSlide = 0
    // console.log('work')
    for(let i = 1; i < length; i ++) {
      children[i].style.opacity = 0
    }

    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    }

    const id = setInterval(() => {
      if (currentSlide === length - 1) {
        children[currentSlide].style.opacity = 0
        children[0].style.opacity = 1
        setTimeout(() => {
          children[0].style.opacity = 1
        }, 100)

        currentSlide = 0
      } else {
        children[currentSlide].style.opacity = 0
        setTimeout(() => {
          children[currentSlide].style.opacity = 1
        }, 100)
        currentSlide = currentSlide + 1
      }
    },time ? time + 2000 : getRandomInt(20, 23) * 100)

    return () => clearInterval(id)
  }, [])

  return (
    <Styled.Wrapper ref={slider}>
      {
        images.map((el) => <Styled.Img
          style={{
            transition: '0.5s'
          }}
        >
        <ImgSection
          baseImg={el}
        />
      </Styled.Img>)
      }
    </Styled.Wrapper>
  )
}

export default ImgSlider