import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { Link } from "react-router-dom";

import { TitleWrap, TitleCenter } from '../../../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, NiveaGlobal } from './../nivea/nivea.styled';
import { Title } from '../../title/Title.styled';
import { Text } from '../../text/text.styled';
import { SubTitle, SubTitleNumber } from '../../sub-title/sub-title.styled';
import BlockQuote from '../../block-quote/block-quote';
import ImgSection from '../../img-section/Img-section';

import DonutChart from '../../../ui/donat-chart/donat-chart';
import Player from '../../video/video';
import RightMenu from '../../right-menu/right-menu';
import Routes from '../../../../routes/routes';

import { Contact } from '../../button/button.styled';
import { PortfolioMobileLogo, PortfolioMobileText } from '../../portfolio-mobile/portfolio-mobile.styled';
import { BackMobile } from '../../../ui/back-mobile/back-mobile.styled';
import { Next } from '../../../ui/next/next.styled';

import folder_1_A from '../../../../assets/case-study/amica/folder/folder_01_A.png';
import folder_1_B from '../../../../assets/case-study/amica/folder/folder_01_B.png';
import folder_1_C from '../../../../assets/case-study/amica/folder/folder_01_C.png';

import Broszura_IFA_1_A from '../../../../assets/case-study/amica/printy/broszura_IFA_1_A.png';
import Broszura_IFA_1_B from '../../../../assets/case-study/amica/printy/broszura_IFA_1_B.png';
import Broszura_IFA_1_C from '../../../../assets/case-study/amica/printy/broszura_IFA_1_C.png';

import Niezbednik_1_A from '../../../../assets/case-study/amica/printy/niezbednik_01_A.png';
import Niezbednik_1_B from '../../../../assets/case-study/amica/printy/niezbednik_01_B.png';

import Billboard_1_A from '../../../../assets/case-study/amica/outdoor/billboard_01_A.png';
import Billboard_1_B from '../../../../assets/case-study/amica/outdoor/billboard_01_B.png';

import Ekspozytory_1_A from '../../../../assets/case-study/amica/pos/ekspozytory_01_A.png';
import Ekspozytory_1_B from '../../../../assets/case-study/amica/pos/ekspozytory_01_B.png';
import Ekspozytory_1_C from '../../../../assets/case-study/amica/pos/ekspozytory_01_C.png';

import Ekspozytory_2_A from '../../../../assets/case-study/amica/pos/ekspozytory_02_A.png';
import Ekspozytory_2_B from '../../../../assets/case-study/amica/pos/ekspozytory_02_B.png';
import Ekspozytory_2_C from '../../../../assets/case-study/amica/pos/ekspozytory_02_C.png';

import Opakowania_1_A from '../../../../assets/case-study/amica/opakowania/opakowania_01_A.png';
import Opakowania_1_B from '../../../../assets/case-study/amica/opakowania/opakowania_01_B.png';
import Opakowania_1_C from '../../../../assets/case-study/amica/opakowania/opakowania_01_C.png';
import { AmicaGlobal } from './amica.styled';
import Amica from '../../carousel/logo/amica';
import SEO from '../../../seo/seo';
import Cookies from '../../../cookies/Cookies';

function ProjectWrapper () {
  const [visibility, setVisibility] = useState(false);
  const color = Routes.portfolio.childs.amica.textColor;
  const colorBg = Routes.portfolio.childs.amica.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  useEffect(() => {
    document.documentElement.style.setProperty('--portfolio-color-active', '#ffffff' );
    document.documentElement.style.setProperty('--portfolio-color', color );
    document.body.style.background = colorBg
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
      // window.location.hash = event.target.dataset.slug;
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round(new Date(date).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 5) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      <SEO 
        title={Routes.portfolio.childs.amica.name}
        description={Routes.portfolio.childs.amica.description}
        keywords={Routes.portfolio.childs.amica.keywords}
      />
      <BackMobile>
        <Link to={Routes.portfolio.childs.home.slug}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
            <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke="#FFFFFF" strokeWidth="2.3"/>
          </svg>
        </Link>
      </BackMobile>
      <TitleWrap hideMobile={true} color="#F9F8F6">
        <TitleCenter color="#F9F8F6">
          <span style={{
              color: "#F9F8F6"
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <NiveaGlobal />
      <AmicaGlobal />
      <RightMenu 
        color={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'intro',
          },
          {
            slug: 'brand',
            name: 'brand',
          },
          {
            slug: 'print',
            name: 'print',
          },
          {
            slug: 'outdoor',
            name: 'outdoor',
          },
          {
            slug: 'pos',
            name: 'POS',
          },
          {
            slug: 'motion',
            name: 'motion',
          },
          {
            slug: 'packaging',
            name: 'packaging',
          },
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
          className="nivea-section__knowledge"
        >
          <PortfolioMobileLogo
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <Amica />
          </PortfolioMobileLogo>
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            w wielkim skrócie: BTL w AGD., czyli przegląd naszej współpracy z AMICA. wiemy, jak spełniać oczekiwania i potrzeby top marek!
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title>
                  w wielkim skrócie: BTL w AGD
                </Title>
                <Text>
                  czyli przegląd naszej współpracy z AMICA. wiemy, jak spełniać oczekiwania i potrzeby top marek!
                </Text>
              </div>
              
              <div 
                className="nivea-intro__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2012}
                  max={new Date().getFullYear() - 2000}
                  undertext="lat"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2019-12-03', 480, 2)}
                  max={getProjects('2019-12-03', 480, 2)}
                  undertext="projektów"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={270}
                  max={360}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
              <div
                className="animated fadeInUp faster"
                style={{
                  animationDelay: `1.8s`
                }}
              >
                <BlockQuote 
                  color={color}
                  content="Nasza współpraca to setki projektów z wielu obszarów wspierających markę Amica w komunikacji BTL, POS, materiałach drukowanych oraz korporacyjnych, komunikacyjnych i produktowych interaktywnych prezentacjach i animacjach oraz w prestiżowych wydarzeniach rangi europejskiej, w których uczestniczy."
                />
              </div>
              
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="brand"
          id="brand"
          className="animated fadeInUp faster amica-section__folder"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>1.</SubTitleNumber>
              niech się na nas poznają
            </SubTitle>
            <Text>
              katalogi wizerunkowe to jedno z głównych narzędzi wykorzystywanych przez AMICA na największych targach branżowych w europie: IFA Berlin. 
            </Text>
          </TextWrap>
          <div className="amica-section__folder-img1">
            <ImgSection
              baseImg={folder_1_B}
              secondImg={folder_1_A}
              thirdImg={folder_1_C}
              layerUp={true}
            />
          </div>
          <TextWrap>
          <Text>
            nasze wydania towarzyszą AMICA od kilku lat wprowadzając targowych gości w świat marki.
          </Text>
          </TextWrap>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="print"
          id="print"
          className="amica-section__printy"
        >
          <TextWrap
            
          >
            <SubTitle className="amica-section__printy-text">
              <SubTitleNumber color={color}>2.</SubTitleNumber>
              ukazało się w druku
            </SubTitle>
            <Text className="amica-section__printy-text">
              zrealizowaliśmy dziesiątki projektów drukowanych, w tym przewodnik o objętości ponad 130 kreatywnych, indywidualnie projektowanych stron. projekt doczekał się także wersji dedykowanej na rynek hiszpański.
              dziesiątki projektów to foldery i katalogi dla produktów lub linii spełniające różnorodne potrzeby komunikacyjne marki AMICA.
            </Text>
          </TextWrap>
          <div className="amica-section__printy-img1">
            <ImgSection
              baseImg={Niezbednik_1_A}
              secondImg={Niezbednik_1_B}
            />
          </div>
          <div className="amica-section__printy-img2">
            <ImgSection
              baseImg={Broszura_IFA_1_C}
              secondImg={Broszura_IFA_1_B}
              thirdImg={Broszura_IFA_1_A}
              layerUp={true}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="outdoor"
          id="outdoor"
          className="amica-section__outdoor"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>3.</SubTitleNumber>
              outdoor czy out of home?
            </SubTitle>
            <Text>
              no a co za różnica? krótko mówiąc: billboardy projektujemy. dobre, ładne, pomysłowe, skuteczne i z dobrym copy.
            </Text>
          </TextWrap>
          <div className="amica-section__outdoor-img1">
            <ImgSection
              baseImg={Billboard_1_A}
              thirdImg={Billboard_1_B}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="pos"
          id="pos"
          className="amica-section__pos"
        >
          <TextWrap>
            <SubTitle className="amica-section__pos-text">
              <SubTitleNumber color={color}>4.</SubTitleNumber>
              z POSami dookoła świata
            </SubTitle>
            <Text className="amica-section__pos-text">
              ekspozytory na event dla vipów w pałacowych wnętrzach paryskiej ambasady. zaprojektowane, wyprodukowane i zamontowane w miejscu akcji uświetniały to wyjątkowe wydarzenie promujące markę AMICA na francuskim rynku.
            </Text>
          </TextWrap>
          <div className="amica-section__pos-img1">
            <ImgSection
              baseImg={Ekspozytory_1_A}
              secondImg={Ekspozytory_1_B}
              thirdImg={Ekspozytory_1_C}
            />
          </div>
          <TextWrap>
            <Text className="amica-section__pos-text">
              a to ekspozytory do elektromarketów w gruzji. tam nas dotychczas nie było :)
            </Text>
          </TextWrap>
          <div className="amica-section__pos-img2">
            <ImgSection
              baseImg={Ekspozytory_2_A}
              secondImg={Ekspozytory_2_B}
              thirdImg={Ekspozytory_2_C}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="motion"
          id="motion"
          className="amica-section__motion"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>5.</SubTitleNumber>
              motion & prezentacje
            </SubTitle>
            <Text>
              opracowywanie prezentacji w power point tak, by nie wyglądały jak power point? wiemy jak to zrobić, ale jest znacznie łatwiej, kiedy możemy je podkręcić animacjami wideo i motion design, takimi jak ta.
            </Text>
          </TextWrap>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/143970823',
            }}
          />
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="packaging"
          id="packaging"
          className="amica-section__opakowania"
        >
          <TextWrap>
            <SubTitle className="amica-section__opakowania-text">
              <SubTitleNumber color={color}>6.</SubTitleNumber>
              opakowania to też branding
            </SubTitle>
            <Text className="amica-section__opakowania-text">
              opakowania dla małego AGD: odkurzaczy, czajników i innych tosterów. cała gama różnorodnych produktów połączona spójną linią projektową. by budować brand AMICA przy półce.            </Text>
          </TextWrap>
          <div className="amica-section__opakowania-img">
            <ImgSection
              baseImg={Opakowania_1_A}
              secondImg={Opakowania_1_B}
              thirdImg={Opakowania_1_C}
            />
          </div>
        </SectionProject>
      </Observer>

      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path id="Path_94" data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
          <Next color="#fff">
            <Link to={Routes.portfolio.childs.altax.slug}>
              następny klient<br/>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill="#fff"/>
              </svg>
            </Link>
          </Next>
        </TextWrap>
      </SectionProject>
      <Cookies
        fixed={true}
        color={'#ffffff'}
        background={colorBg}
      />
    </ProjectWrapperContainer>
  )
}

export default ProjectWrapper;