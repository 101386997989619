import React from 'react'
import { ScrollWrapper, ScrollMobile, ScrollDesktop } from './scroll.styled';

const Scroll = (props) => {
  return(
    <ScrollWrapper>
      <ScrollDesktop 
        isAbsolute={props.isAbsolute}
        isWhite={props.isWhite}
      />
      <ScrollMobile
        isAbsolute={props.isAbsolute}
      >
        <svg width="22px" height="21px" viewBox="0 0 22 21">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fill={props.isWhite ? '#F9F8F6' : '#232328'} fillRule="nonzero">
                    <g id="scroll-1"  transform="translate(0.000000, 10.000000)">
                        <polygon points="0 1.546 1.3 0 10.779 8.12 20.336 0 21.636 1.546 10.782 10.806"></polygon>
                    </g>
                    <g id="scroll-2">
                        <polygon points="0 1.546 1.3 0 10.779 8.12 20.336 0 21.636 1.546 10.782 10.806"></polygon>
                    </g>
                </g>
            </g>
        </svg>
      </ScrollMobile>
    </ScrollWrapper>
  )
}

export default Scroll;