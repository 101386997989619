import styled from 'styled-components';
import { isChrome } from 'react-device-detect';

export const FormWrap = styled.div`
  @media (max-width: 1023px){
    display: ${props => props.showMobile ? 'block' : 'none' };
  }
`

export const FormBug = styled.div`
  display: none;
  color: #232328;
  font-size: 1.09375vw;
  line-height: 1.5625vw;
  letter-spacing: 1.75;
  border: 1px solid #232328;
  font-weight: 300;
  position: absolute;
  right: -2.08vw;
  top: 50%;
  transform: translate(100%, -50%);
  padding: 18px 14px 16px 22px;
  background: ${props => props.color};
  z-index: 5;
  text-align: left;

  @media (min-width: 1800px) {
    font-size: 0.82vw;
    line-height: 1.4vw;
  }

  @media (max-width: 1023px){ 
    font-size: 4.77vw;
    line-height: 6.33vw;
    padding: 1.66vw;
    right: 50%;
    top: -10px;
    transform: translate(50%, -100%);
    text-align: center;
  }
  &::before {
    content: '';
    height: 25px;
    width: 4px;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translate(0, -50%);
    background: ${props => props.color};
    z-index: 2;
    @media (max-width: 1023px){
      height: 4px;
      width: 39px;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 50%;
      transform: translate(-50%,0);
    }
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-18px);
    left: 0;
    fill: ${props => props.color};

    @media (max-width: 1023px){ 
      transform: rotate(-90deg) translateY(-50%) translateX(-25.5px);
      left: 50%;
    }
  }
`

export const FormContainer = styled.div`
  font-family: europa, sans-serif;
  padding-left: 10%;
  padding-right: 20%
  top: 53vh;
  transform: translateY(-50%);
  position: absolute;

  @media (max-width: 1023px){
    position: relative;
    top: initial;
    padding: 0 8.33vw;
    transform: none;
    padding-bottom: 40px;
    background: ${props => props.color};
  }
`

export const FormContainerForm = styled.div`
  text-align: right;

  @media (max-width: 1023px){
    text-align: left;
  }

  .svg-file {
    @media (max-width: 1023px){
      width: 2.5vw;
      height: auto;
    }
  }

  p {
    font-weight: 700;
    font-size: 1.875vw;
    line-height: 2.8125vw;
    margin-bottom: 1.04vw;
    margin-top: 0;
    
    @media (max-width: 1023px){ 
      font-size: 5.55vw;
      line-height: 6.66vw;
      margin-bottom: 3.33vw;
    }
  }

  .icon-attach {
    margin-bottom: -10px;
  }
`

export const FormContainerFormLabel = styled.label`
  color: #005FFF;
  font-weight: 400;
  position: relative;
`

export const FormContainerFormLabelText = styled.span`
  cursor: pointer;

  .svg-file__file {
    width: 0.78125vw;
    height: 1.61vw;

    @media (max-width: 1023px){
      width: 2.16vw;
      height: 4.6vw;
    }
  }
`

export const FormContainerFormLabelInput = styled.input`
  color: #005FFF;
  font-weight: 400;
  border: none;
  background: transparent;
  outline: 0;
  text-align: right;
  margin-left: 0;
  line-height: 2.8125vw;
  padding: 0;

  @media (max-width: 1023px){ 
    line-height: 7.2vw;
  }

  @media (max-width: 1023px){
    margin-left: 0;
    text-align: left;
  }

  &::placeholder {
    color: #005FFF;
    opacity: 1;
  }
`

export const FormContainerInfo = styled.div`
  margin-top: -10px;
  font-size: 0.625vw;
  line-height: 0.9375vw;
  letter-spacing: 0.75px;
  font-weight: 700;
  text-align: right;

  p {
    margin-bottom: 1.04vw;
  }

  a {
    color: #005FFF;
    text-decoration: none;
  }

  @media (min-width: 1800px) {
    font-size: 0.6vw;
    line-height: 2.5;

    p {
      margin-bottom: 0.5vw;
    }
  }

  @media (max-width: 1023px){
    font-size: 3.88vw;
    line-height: 5.55vw;
    text-align: left;
    margin-top: 3.33vw;
  }
`;


export const FormContainerSubmit = styled.button`
  apperance: none;
  border: none;
  background: transparent;
  font-size: 2.5vw;
  line-height: 6.22vw;
  outline: 0;
  cursor: pointer;
  float: right;
  font-weight: 700;
  transition: .3s;
  color: rgb(35, 35, 40);
  font-variant-ligatures: none;

  @media (max-width: 1023px){
    font-size: 7.77vw;
    color: #005FFF;
    float: none;
    padding: 0;
    margin-top: 12.77vw;
    margin-bottom: 11.66vw;
    line-height: 10vw;
  }

  svg {
    transition: .3s;
    width: 3.17vw;
    height: 2.21vw;
    padding-left: 0.9375vw;
    margin-top: 2vw;
    fill: rgb(35, 35, 40);

    @media (max-width: 1023px){
      width: 10.55vw;
      height: 7.22vw;
      float: left;
      padding-right: 3.33vw;
      padding-left: 0;
      fill: #005FFF;
    }
  }

  &:hover {
    color: #005FFF;

    svg {
      fill: #005FFF;
    }
  }
`;

export const FormRodo = styled.div`
  font-family: europa, sans-serif;
  color: #232328;
`

export const FormRodoContainer = styled.div`
  text-align: right;

  @media (max-width: 1023px){
    text-align: left;
  }

  strong, b {
    line-height: 2.29vw;
    font-size: 2.8125vw;
    font-weight: 700;

    @media (max-width: 1023px){ 
      font-size: 7.77vw;
      line-height: 6.66vw;
      text-transform: initial;
    }
  }

  p {
    font-size: 1.875vw;
    line-height: 2.8125vw;
    font-family: europa, sans-serif;
    letter-spacing: 2px;
    font-weight: 700;

    @media (max-width: 1023px){
      font-size: 5.55vw;
      line-height: 6.66vw;
    }
  }
`

export const FormWrapButtons = styled.div`
  display: inline-block;
  width: 100%;
`

export const FormRodoButtons = styled.div`
  @media (max-width: 1023px){
    margin-bottom: 11.66vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`

export const FormRodoBack = styled.button`
  apperance: none;
  border: none;
  background: transparent;
  font-size: 2.5vw;
  line-height: 5.22vw;
  outline: 0;
  cursor: pointer;
  float: left;
  font-weight: 700;
  display: flex;
  color: #232328;
  font-variant-ligatures: none;
  
  svg {
    width: 1.66vw;
    height: 1.66vw;
    padding-right: 1vw;
  }

  @media (max-width: 1023px){
    font-size: 7.77vw;
    line-height: 10.77vw;
    padding: 0;

    svg {
      width: 4.72vw;
      height: 4.72vw;
      padding-right: 3.61vw;
    }
  }
`

export const FormRodoSubmit = styled.button`
  apperance: none;
  border: none;
  background: transparent;
  font-size: 2.5vw;
  line-height: 5.22vw;
  outline: 0;
  cursor: pointer;
  float: right;
  font-weight: 700;
  display: flex;
  color: #232328;
  font-variant-ligatures: none;

  svg {
    margin-left: 15px;
    width: 2.03vw;
    height: 1.66vw;
  }

  @media (max-width: 1023px){
    font-size: 7.77vw;
    line-height: 10.77vw;
    padding: 0;

    svg {
      width: 5.83vw;
      height: 4.72vw;
      padding-left: 3.88vw;
      margin-left: 0;
    }
  }
`