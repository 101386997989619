import styled from 'styled-components';

export const Next = styled.div`
  font-family: europa, sans-serif;
  display: block;
  margin: 0 auto;
  font-size: 4.16vw;
  line-height: 5.8vw;
  font-weight: 700;
  text-align: center;
  ${ props => props.client ? 'padding-bottom: 12.2vw;' : ''}

  a {
    color: ${ props => props.color };
    text-decoration: none;
  }
  svg {
    padding: 3vw 0 ${ props => props.client ? '0' : '12.2vw' };
    width: ${ props => props.client ? '7.5vw' : '4.44vw'};
    height: ${ props => props.client ? '7.5vw' : '7.5vw'};
  }
  display: none;

  @media (max-width: 1023px){
    display: block;
  }
`