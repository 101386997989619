import React, { useState, useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser';

import { HalfFull } from '../../components/grid/half/Half.styled';
import { Link } from "react-router-dom";
import { connect } from "react-redux";


import { TitleWrap, TitleHome, TitleCenter, TitleHomeDot } from '../../components/ui/title/title.styled';
import MenuIcon from '../../components/menu-icon/menu-icon';
import { Container } from '../../components/grid/container/Container.styled';
import { colors } from '../../colors/colors';
import { homeAnimation } from "./../../actions/index";

import { HomeMobile, HomeMobileTitle, HomeMobileTitleMain } from './../../pages/home/home.style';
import { BG, BGWrapperSecond, BGGradientSecond } from '../grid/bg/bg.style';
import Scroll from '../ui/scroll/scroll';
import Routes from '../../routes/routes';
import SEO from '../seo/seo';
import Cookies from '../cookies/Cookies';
import { MIN_ANIMATION } from '../../config/config';
import { HomeText, HomeWrapText, HomeInsideText } from './home-main.styled';

const titles = [
  {
    left: '<div>strategicznie&nbsp;i&nbsp;</div>K',
    right: 'MPLEKSOWO',
    mobileLeft: 'strategicznie&nbsp;i&nbsp;',
    mobileRight: 'KOMPLEKSOWO'
  },
  {
    left: '<div>spełniamy</div>&nbsp;P',
    right: 'TRZEBY',
    mobileLeft: 'spełniamy',
    mobileRight: 'POTRZEBY'
  },
  {
    left: '<div>komunikacji&nbsp;w</div>&nbsp;',
    right: 'NLINE',
    mobileLeft: 'komunikacji&nbsp;w',
    mobileRight: 'ONLINE'
  },
  {
    left: '<div>komunikacji&nbsp;w</div>&nbsp;',
    right: 'FFLINE',
    mobileLeft: 'komunikacji&nbsp;w',
    mobileRight: 'OFFLINE'
  },
  {
    left: '<div>tworzymy</div>&nbsp;PR',
    right: 'DUKTY',
    mobileLeft: 'tworzymy',
    mobileRight: 'PRODUKTY'
  },
  {
    left: '<div>marki</div>&nbsp;TW',
    right: 'RZYMY',
    mobileLeft: 'marki',
    mobileRight: 'TWORZYMY'
  },
  {
    left: '<div>kreatywnie</div>&nbsp;PR',
    right: 'JEKTUJEMY',
    mobileLeft: 'kreatywnie',
    mobileRight: 'PROJEKTUJEMY'
  },
  {
    left: '<div>wszystko</div>&nbsp;PR',
    right: 'DUKUJEMY',
    mobileLeft: 'wszystko',
    mobileRight: 'PRODUKUJEMY'
  },
  {
    left: '<div>jesteśmy</div>&nbsp;NIE',
    right: 'GRANICZENI',
    mobileLeft: 'jesteśmy',
    mobileRight: 'NIEOGRANICZENI'
  }
]

const text = `<p>i mamy na to dowody.<br/>
20 lat zaufania najbardziej rozpoznawalnych marek na rynku. tysiące projektów dla ich brandów, produktów, usług i akcji. produkcja i komunikacja w każdym możliwym kanale. 
<br/>nie przekonaliśmy cię? wejdź i poznaj nieograniczonych.</p>`

const mapStateToProps = state => {
  return { homeAnimationState: state.dontShowHomeAnimation, loading : state.loading, intro : state.intro };
};

function mapDispatchToProps(dispatch) {
  return {
    updateHomeAnimation: animation => dispatch(homeAnimation(animation))
  };
}

const HomeMain = ( {homeAnimationState, updateHomeAnimation, showHide, animationOut, loading, intro } ) => {
  const [current, updateCurrent] = useState(0); // false
  const [color, updateColor] = useState(0);
  const [slide, updateSlide] = useState(false);
  const [slideUp, updateSlideUp] = useState(false);
  const [show, updateShow] = useState(false);

  const colorsMap = [
    colors[6],
    colors[0],
    colors[3],
    colors[5],
    colors[2],
    colors[4],
    colors[1],
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      if ( color > colors.length - 2 ) {
        updateColor( 0 );
      } else {
        updateColor(color + 1 );
      }
      document.documentElement.style.setProperty('--home-color', colorsMap[color + 1] ? colorsMap[color + 1].color : colorsMap[0].color );
    }, 1500)

    return () => clearInterval(interval);
  }, [color, slide]);

  useEffect(()=>{
    setTimeout(()=>{
      updateShow(true)
    }, 2000)
  },[])

  useEffect(() => {
      if ( loading === false && show ) {
        // console.log('let show')
        setTimeout(() => {
          updateCurrent(1)
        }, 1500)
        setTimeout(() => {
          updateSlideUp(true)
          updateSlide(true)
          
        }, 2500)
        const slide1 = document.getElementById('slide-1')
          const slide2 = document.getElementById('slide-2')
          const slide3 = document.getElementById('slide-3')
          let i = 1;

          setTimeout(() => {
            setTimeout(() => {
              slide1.style.visibility = 'visible'
              slide1.style.opacity = 1
              slide3.style.visibility = 'hidden'
            }, 400)
            
            slide3.style.opacity = 0
            i = 2

            setInterval(() => {
              if ( i === 1 ) {
                setTimeout(() => {
                  slide1.style.visibility = 'visible'
                  slide1.style.opacity = 1
                  slide3.style.visibility = 'hidden'
                }, 400)
                
                slide3.style.opacity = 0
                i = 2
              } else if ( i === 2 ) {
                setTimeout(() => {
                  slide2.style.visibility = 'visible'
                  slide2.style.opacity = 1
                  slide1.style.visibility = 'hidden'
                }, 400)
                
                slide1.style.opacity = 0
                i = 3
              } else if ( i === 3 ) {
                setTimeout(() => {
                  slide3.style.visibility = 'visible'
                  slide3.style.opacity = 1
                  slide2.style.visibility = 'hidden'
                }, 400)
                
                slide2.style.opacity = 0
                i = 1
              }
            }, 9000)
          }, 2000)
          
          
        // const interval = setInterval(() => {
        //   if ( current > titles.length - 2 ) {
        //     clearInterval(interval);
        //     updateSlideUp(true)
        //     updateSlide(true)
        //     updateHomeAnimation(true)
        //   } else {
        //     updateCurrent(current + 1 );
        //   }
        // }, 1000)
  
        // return () => clearInterval(interval);
      }

  // eslint-disable-next-line
  }, [current, homeAnimationState, loading, show]);

  return (
    <BGWrapperSecond>
      <BGGradientSecond>
      <BG
        // className={`
        //   ${showHide ? 'animated fadeIn faster' : ''}
        //   ${animationOut ? 'animated fadeOut faster' : ''}
        // `}
        style={{
          zIndex: 1,
          // background: 'rgb(35, 35, 40)',
          display: showHide ? 'block' : 'none'
        }}
        maxHeight={true}
      >
      
      <SEO 
        title={intro.seo ? intro.seo.title : ''}
        description={intro.seo ? intro.seo.description : ''}
        keywords={intro.seo ? intro.seo.keywords : ''}
       />
      <MenuIcon 
        isWhite={true}
      />
      <Container
        isMinHeight={true}
      >
        <Scroll 
          isAbsolute={true}
          isWhite={true} 
        />
        <Link style={{animationDelay: `${MIN_ANIMATION + 0.7}s`}} className="contact-button--animation contact-button contact-button--home-main contact-button--absolute" to={Routes.kontakt.slug}>kontakt</Link>
        <TitleWrap 
          hideMobile={true}
          color="#F9F8F6"
          isTransform={true}
          style={{
            opacity: 0,
            animationDelay: '.7s',
          }}
          className={`animated ${current ? 'fadeIn' : '' } faster`}
        >
          <TitleCenter >
            <span style={{
              color: colorsMap[color].color,
              transition: '.7s',
            }} className="icon-signet"></span>
          </TitleCenter>
        </TitleWrap>
        <HalfFull floatRight={true} mobile100vh={true} positionInitialMobile={true} fullHeight={true}>
          <HomeMobile slideUp={slide}>
            <svg  width="76.124" height="98.956" fill={colorsMap[color].color} viewBox="0 0 76.124 98.956">
              <g>
                <path d="M38.062 22.832a38.062 38.062 0 1 0 38.062 38.055 38.1 38.1 0 0 0-38.062-38.055zm0 60.895A22.837 22.837 0 1 1 60.9 60.887a22.856 22.856 0 0 1-22.838 22.84z"/>
                <path d="M70.546 0H5.564a1.838 1.838 0 0 0-1.757 1.907v11.4a1.838 1.838 0 0 0 1.757 1.907h64.982a1.836 1.836 0 0 0 1.757-1.907v-11.4A1.836 1.836 0 0 0 70.546 0z"/>
              </g>
            </svg>
            <HomeMobileTitle>
            </HomeMobileTitle>
          </HomeMobile>
          <HomeWrapText
            className={`animated ${slideUp ? 'fadeInUp' : '' } faster`}
            style={{
              animationDelay: `0s`,
              opacity: slideUp ? 1 : 0
            }}
          >
            <TitleHome>
              {intro.start_title && intro.start_title}<TitleHomeDot color={colorsMap[color].color}>.</TitleHomeDot>
            </TitleHome>
            <HomeInsideText>
              <HomeText
                id="slide-1"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              >
                {ReactHtmlParser(intro.start_carousel[0].content)}
              </HomeText>
              <HomeText
                id="slide-2"
                style={{
                  visibility: 'hidden',
                  opacity: 0,
                }}
              >
                {ReactHtmlParser(intro.start_carousel[1].content)}
              </HomeText>
              <HomeText
                id="slide-3"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  visibility: 'hidden',
                  opacity: 0,
                }}
              >
                {ReactHtmlParser(intro.start_carousel[2].content)}
              </HomeText>
            </HomeInsideText>
            
            <HomeText main isBott>
              {ReactHtmlParser(intro.start_cta)}
            </HomeText>
          </HomeWrapText>
          
          {/* <TextText 
            homeMobileSmall={true}
            color="#F9F8F6"
            className={`animated ${slideUp ? 'fadeInUp' : '' } faster`}
            style={{
              animationDelay: `0s`,
              opacity: slideUp ? 1 : 0
            }}
          >
            { ReactHtmlParser(text) }
          </TextText> */}
        </HalfFull>
      </Container>
      <Cookies
        color={colorsMap[color].color}
        background={'rgb(35, 35, 40)'}
      />
    </BG>
    </BGGradientSecond>
    </BGWrapperSecond>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeMain)