import React from 'react';
import { RightMenuWrap, RightMenuText, RightMenuContainer, RightMenuItem } from './right-menu.styled';
import { ShowDesktop } from '../../grid/showHide/showHide.style';

const RightMenu = (props) => {
  let getActive = props.elements.filter( (element) => element.slug === props.active);
  
  const scroll = (slug) => {
    const el = document.querySelectorAll(`#${slug}`)

    // console.log('top', document.querySelectorAll(`#${slug}`))
    // console.log('top', document.querySelectorAll('#'.slug)[document.querySelectorAll('#'.slug).length - 1].getBoundingClientRect())
    window.scroll({
      top: el[el.length -1].offsetTop - 80, 
      left: 0, 
      behavior: 'smooth'
    });
  };

  return (
    <ShowDesktop
    
    >
      <RightMenuWrap
          
      >
        <RightMenuText style={{
          color: props.customColor
        }} onClick={scroll}>
          {
            getActive.map( (element) => element.name )
          }
        </RightMenuText>
        <RightMenuContainer>
          {
            props.elements.map( (element, i) => {
              let e = 0.2 + (i / 10)

              return (
                <RightMenuItem className="animated fadeInUp faster"
                style={{
                  animationDelay: `${e}s`
                }} onClick={() => scroll(element.slug)} href={`#${element.slug}`} key={element.slug} color={props.customColor || props.color} isActive={element.slug === props.active} />
              )
            })
          }
        </RightMenuContainer>
      </RightMenuWrap>
    </ShowDesktop>
  )
}

export default RightMenu;