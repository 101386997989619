import styled from 'styled-components';

export const Carousel = styled.div`
  padding-right: 31%;
  position: relative;
  height: 100vh;
  color: #232328;

  .swiper-container {
    height: 50vh;
    overflow: visible;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const CarouselText = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: europa, sans-serif;
  font-weight: 700;
  font-size: 2.5vw;
  line-height: 2.5vw;

  &.swiper-slide-active {
    > {
      &:before {
        width: calc(100% + 1.666vw);
      }
    }
  }
`;

export const CarouselTextInside = styled.span`
  position: relative;
  cursor: pointer;

  &:before {
    width: 0;
    margin-left: -0.833vw;
    height: 11%;
    content: '';
    position: absolute;
    background-color: #005FFF;
    top: calc(50% - 1px);
    transition: 0.3s;
    margin-top: 0.208vw;
    z-index: -1;
  }

  ${
    props => props.isStart && `
      &:before {
        width: 0 !important;
      }
    `
  }
`