import styled, { createGlobalStyle } from 'styled-components';

export const ProjectWrapperContainer = styled.div`
  // padding-left: 70px;
  // padding-right: 100px;
  // padding-top: 180px;
`;

export const SectionProject = styled.div`
`

export const TextWrap = styled.div`
  padding-left: ${props => props.leftZero ? '0' : '3.64vw'}; //70px
  padding-right: ${props => props.rightZero ? '0' : '5.2vw'}; //100px
  
  @media (max-width: 1023px){
    padding: 0 30px;
  }
`

export const TopFirst = styled.div`
  // padding-top: 8.7vh;
`

export const NiveaGlobal = createGlobalStyle`
  .nivea-section {
    &__knowledge {
      padding-bottom: 10.729vw;

      @media (max-width: 1023px){
        padding-bottom: 81px;
      }
    }

    &__support {
      padding-top: 5.729vw;

      @media (max-width: 1023px){
        padding-top: 90px;
      }
    }

    &__action {
      padding-top: 10.41vw;

      @media (max-width: 1023px){
        padding-top: 90px;
      }
    }

    &__events {
      padding-top: 6.25vw;
    }

    &__relations {
      padding-top: 3vw;
    }

    &__work {
      padding-top: 6.25vw;

      @media (max-width: 1023px){
        padding-top: 90px;
      }
    }

    &__read {
      padding-top: 7.8125vw;

      @media (max-width: 1023px){
        padding-top: 50px;
      }
    }

    &__video {
      padding-top: 3vw;
      @media (max-width: 1023px){
        padding-top: 50px;
      }
    }

    &__but {
      padding-top: 3vw;  
    }
  }

  .nivea-intro {
    &__titles {
      position: absolute;
      padding-right: 5.2vw;
      padding-top: 10vh;

      @media (max-width: 1023px){
        display: none;
      }
    }

    &__donuts {
      display: flex;
      justify-content: space-around;
      padding-top: calc(50vh - 3.3vw);
      margin-bottom: 5.729vw;

      @media (max-width: 1023px){
        padding-top: 40px;
        margin-bottom: 45px;
      }
    }
  }

  .nivea-img {
    &__section_1 {
      padding-top: 3.125vw;
      padding-bottom: 4.125vw;
      margin-left: -4vw;
      z-index: 3;
      position: relative;

      @media (max-width: 1023px){
        padding-top: 33px;
        padding-bottom: 50px;
      }
    }

    &__opakowania_2 {
      padding-bottom: 5vw;

      @media (max-width: 1023px){
        padding-top: 40px;
        padding-bottom: 50px;
      }
    }

    &__opakowania_4_text {
      padding-top: 3.5vw;
      padding-left: 50% !important;

      @media (max-width: 1023px){
        padding-left: 0 !important;
      }
    }

    &__opakowania_3 {
      margin-right: -6.25vw;
      z-index: 3;
      position: relative;
      padding-top: 3vw;

      @media (max-width: 1023px){
        padding-top: 40px;
        padding-bottom: 60px;
      }
    }

    &__opakowania_4 {
      padding-top: 1vw;

      @media (max-width: 1023px){
        padding-top: 60px;
        padding-bottom: 30px;

        img {
          width: 150%;
          margin-left: -25%;
        }
      }
    }

    &__opakowania_3_text {
      padding-right: 30% !important;

      @media (max-width: 1023px){
        padding-right: 0 !important;
      }
    }

    &__opakowania_2_text {
      padding-left: 30% !important;
      text-align: right !important;

      @media (max-width: 1023px){
        padding-left: 0 !important;
      }
    }

    &__opakowania_5_text {
      padding-top: 3.64vw;
      padding-right: 40%;

      @media (max-width: 1023px){
        padding-top: 60px;
        padding-right: 0%;
      }
    }

    &__opakowania_5 {
      padding-right: 3.125vw;
      z-index: 3;
      position: relative;

      @media (max-width: 1023px){
        padding-top: 60px;
      }
    }
  }

  .nivea-support {
    &__title {
      padding-left: 20% !important;

      @media (max-width: 1023px){
        padding-left: 0% !important;;
      }
    }

    &__text_1 {
      @media (max-width: 1023px){
        text-align: left !important;;
      }
    }

    &__img {
      padding-top: 6.5625vw !important;
      padding-bottom: 3.125vw !important;

      @media (max-width: 1023px){
        padding-top: 50px !important;
        padding-bottom: 50px !important;
      }
    }

    &__text_2 {
      padding-right: 40% !important;

      @media (max-width: 1023px){
        padding-right: 0 !important;
        text-align: right !important;
      }
    }

    &__img_2 {
      margin-top: -3.64vw;
      padding-bottom: 3vw;

      @media (max-width: 1023px){
        padding-top: 50px;
        padding-bottom: 30px;

        img {
          width: 125%;
          margin-left: 0%;

        }
      }
    }

    &__text_3 {
      padding-left: 30% !important;
      padding-bottom: 2.60vw !important;
      padding-top: 1vw;

      @media (max-width: 1023px){
        padding-left: 0 !important;
      }
    }

    &__img_3 {
      margin-left: -2vw;
      margin-right: -1vw;
      z-index: 3;
      position: relative;
    }
  }

  .nivea-events {
    &__title {
      width: 60%;

      @media (max-width: 1023px){
        width: 80%;
      }
    }

    &__text {
      padding-right: 50%;

      @media (max-width: 1023px){
        padding-right: 0 !important;
      }
    }
    
    &__img {
      margin-top: -7.458vw !important;

      @media (max-width: 1023px){
        margin-top: 0 !important;
        padding-top: 30px;
        padding-bottom: 50px;
      }
    }

    &__text_2 {
      padding-right: 30%;

      @media (max-width: 1023px){
        text-align: right !important;
        padding-right: 0 !important;
      }
    }
  }

  .nivea-relations {
    &__title {
      width: 50%;

      @media (max-width: 1023px){
        width: 100%;
      }
    }

    &__text {
      padding-right: 30%;

      @media (max-width: 1023px){
        padding-right: 0 !important;
      }
    }

    &__img {
      margin-right: -6.25vw;
      margin-left: -9.5vw;
      margin-top: -3.208vw;
      z-index: 3;
      position: relative;

      @media (max-width: 1023px){
        margin-top: 0;
        padding-top: 20px;
      }
    }
  }

  .nivea-work{
    &__title {
      text-align: right !important;
      padding-left: 40%;

      @media (max-width: 1023px){
        text-align: left !important;
        padding-left: 0%;
      }
    }
    &__text {
      text-align: right !important;
      padding-left: 10% !important;

      @media (max-width: 1023px){
        padding-left: 0 !important;
        text-align: left !important;
      }
    }
    
    &__text_2 {
      padding-top: 3vw;
      padding-right: 40%;
      padding-bottom: 1.0416vw;

      @media (max-width: 1023px){
        padding-right: 0 !important;
        text-align: right !important;
      }
    }

    &__img {
      margin-right: -3.125vw;
      z-index: 3;
      position: relative;

      @media (max-width: 1023px){
        padding-top: 40px;
      }
    }
  } 

  .nivea-read {
    
    &__text {
      padding-left: 20% !important;

      @media (max-width: 1023px){
        padding-left: 0 !important;
        text-align: left !important;
        maring-bottom: 20px;
      }
    }

    &__img {
      padding-right: 4.125vw;
      padding-top: 3.6458vw;
      z-index: 3;
      position: relative;

      @media (max-width: 1023px){
        padding-top: 40px;
      }
    }
  }

  .nivea-but {
    &__title {
      text-align: right !important;
      padding-left: 20% !important;

      @media (max-width: 1023px){
        padding-left: 0 !important;
        text-align: left !important;
        
      }
    }

    &__text {
      text-align: right !important;

      @media (max-width: 1023px){
        text-align: left !important;
        padding-bottom: 20px;
      }
    }

    &__img {
      padding-right: 4.125vw;
      z-index: 3;
      position: relative;
      padding-top: 40px;

      @media (max-width: 1023px){
        padding-top: 40px;
      }
    }
  }
`