import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

import Player from '../../components/portfolio/video/video';
import { Container } from '../grid/container/Container.styled';
import { Half, HalfFull } from '../grid/half/Half.styled';
import { Link } from "react-router-dom";
import MenuIcon from '../../components/menu-icon/menu-icon';
import { TitleWrap, TitleCenter } from '../../components/ui/title/title.styled';
import { HomeIntroImg, HomeIntroMobileImg, HomeIntroText, HomeIntroTitle, HomeIntroPlus, HomeIntroTeaser, HomeContactHover } from './home-intro.styled';
import { ShowDesktop, ShowMobile } from '../grid/showHide/showHide.style';
import { More } from '../ui/more/more.styled';
import { TextWrap } from '../portfolio/clients/nivea/nivea.styled';
import Scroll from '../ui/scroll/scroll';
import { fromLetterAnimation } from '../../letter-animation/letter-animation';
import Routes from '../../routes/routes';
import slugify from 'slugify';
import Cookies from '../cookies/Cookies';
import Hrefs from '../hrefs/hrefs';

const HomeIntro = (props) => {
  const [titleShow, updateTitleShow] = useState(false);

  useEffect(() => {
    if( titleShow ) {
      fromLetterAnimation(titleShow, 0, true)
    }
    
  }, [props.showHide])

  // imgMobile={el.acf.mobile_img}
  // mobileHeadlineVimeo={el.acf.mobile_headline_vimeo}
  // headlineVimeo={el.acf.headline_vimeo}

  return(
    <div
    // className={`
    //   ${props.showHide ? 'animated fadeIn faster' : ''}
    //   ${props.animationOut ? 'animated fadeOut faster' : ''}
    // `}
    style={{
      background: props.dark ? '#232328' : '#F9F8F6',
      display: props.showHide ? 'block' : 'none'
    }}>
      <Container dontReverse={true} isMinHeight={true}>
        <Scroll 
          isAbsolute={true}
          isWhite={props.dark} 
        />
        <MenuIcon
          isAbsolute={true}
          isWhite={props.dark}
        />
        <HomeContactHover
          hoverColor={props.color}
        >
          <Link
            className={`
              ${props.showHide ? 'animated fadeIn faster' : ''}
              ${props.animationOut ? 'animated fadeOut faster' : ''}
              contact-button contact-button--absolute 
              ${ !props.dark ? 'contact-button--dark' : '' } `} 
            exact to={Routes.kontakt.slug}>kontakt</Link>
        </HomeContactHover>
        
        <TitleWrap 
          className={`
            ${props.showHide ? 'animated fadeIn faster' : ''}
            ${props.animationOut ? 'animated fadeOut faster' : ''}
          `}
        
          hideMobile={true} absolute={true} color="#F9F8F6" isTransform={true}>
          <TitleCenter color={props.color}>
            <span style={{
              color: props.color
            }} className="icon-signet"></span>
          </TitleCenter>
        </TitleWrap>  
        <Half fullHeight={false}>
          <HomeIntroTitle
            color={props.color}>
            <div
              className={`
              ${props.showHide ? 'animated fadeInUp faster' : ''}
              ${props.animationOut ? 'animated fadeOutUp faster' : ''}
            `}
            style={{
              animationDelay: `0.2s`
            }}
            >
              { ReactHtmlParser(props.title) }
            </div>
          </HomeIntroTitle>
          <HomeIntroTeaser
            dark={props.dark}
            className={`
              ${props.showHide ? 'animated fadeIn faster' : ''}
              ${props.animationOut ? 'animated fadeOut faster' : ''}
            `}
            style={{
              animationDelay: `0.3s`
            }}
          >
            {props.teaser}
          </HomeIntroTeaser>
          <HomeIntroPlus
            className={`
              ${props.animationOut ? 'animated fadeOut faster' : ''}
            `}
          >
            <svg 
              className={`
                ${props.showHide ? 'animated fadeIn faster' : ''}
              `}
              style={{
                animationDelay: `0.7s`
              }}
              xmlns="http://www.w3.org/2000/svg" width="44.44" height="44.439" stroke={props.color} viewBox="0 0 44.44 44.439">
              <g transform="translate(-771.5 -71)">
                <line x2="44.44" transform="translate(771.5 93.22)" fill="none" strokeWidth="10"/>
                <line x2="44.44" transform="translate(793.72 71) rotate(90)" fill="none" strokeWidth="10"/>
              </g>
            </svg>
          </HomeIntroPlus>
        </Half>
        <HalfFull
          className={`
            ${props.animationOut ? 'animated fadeOut faster' : ''}
          `}
          mobileAlign="center" mobileAuto={true} fullHeight={true}>
          <TextWrap leftZero={true} rightZero={true}>
            <ShowDesktop>
              <Link to={'/artykul/'+props.link}>
                {props.headlineVimeo && <Player 
                  className={`animated ${props.showHide ? 'fadeInUp' : ''} faster`}
                  style={{
                    animationDelay: `0.7s`
                  }}
                  video={{
                    src: props.headlineVimeo,
                  }}
                />}
                {!props.headlineVimeo && <HomeIntroImg 
                  className={`animated ${props.showHide ? 'fadeInUp' : ''} faster`}
                  style={{
                    animationDelay: `0.7s`
                  }}
                  src={props.img.toString()}
                  alt={props.title} />}
              </Link>
            </ShowDesktop>
            <ShowMobile>
              <Link to={'/artykul/'+props.link}>
                {!props.mobileHeadlineVimeo && <HomeIntroMobileImg 
                  className={`animated ${props.showHide ? 'fadeInUp' : ''} faster`}
                  style={{
                    animationDelay: `0.7s`
                  }}
                  imgFullMobile={props.imgFullMobile}
                  image={props.imgMobile || props.img}
                /> }
                {props.mobileHeadlineVimeo && <Player 
                  className={`animated ${props.showHide ? 'fadeInUp' : ''} faster`}
                  style={{
                    animationDelay: `0.7s`
                  }}
                  video={{
                    src: props.mobileHeadlineVimeo,
                  }}
                />}
              </Link>
            </ShowMobile>
            <HomeIntroText
              className={`animated ${props.showHide ? 'fadeInUp' : ''} faster`}
              style={{
                animationDelay: `0.8s`
              }}
              dark={props.dark}>
              <Hrefs text={props.description} />
            </HomeIntroText>
            <More 
              absolute dontHideBr={true} color={props.color}>
              <Link
                className={`animated ${props.showHide ? 'fadeInUp' : ''} faster`}
                style={{
                  animationDelay: `0.9s`
                }}
                to={'/artykul/'+ slugify(props.link, '-')}>czytaj dalej! <svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
                  <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={props.color}/>
                </svg>
              </Link>
            </More>
          </TextWrap>
        </HalfFull>
      </Container>
      <Cookies
        color={props.color}
        background={props.dark ? '#232328' : '#F9F8F6'}
        />
    </div>
    
  )
}

export default HomeIntro;