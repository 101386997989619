import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';

import { ImgSectionContainer, ImgSectionBase, ImgSectionOther, StaticImage } from './Img-section.styled';

import img_1a from './images/01A.png'
import img_1b from './images/01B.png'
import img_1c from './images/01C.png'
import img_1d from './images/01D.png'
import img_1e from './images/01E.png'

const RanisilverImage = (props) => {

  let scene = React.createRef();


  return (
    
      <ImgSectionContainer>
        <Controller>
          <Scene
            ref={scene}
            indicators={false}
            duration="200%"
            triggerHook="1"
          >
            {(progress, event) => (
              <div>
                
                <ImgSectionBase 
                  src={img_1a}
                />
                {
                  img_1b && <ImgSectionOther 
                    style={{
                      top: ((progress * (props.secondSpeed ? props.secondSpeed : 8)) - 5 ) + 'vw'
                    }}
                    src={img_1b}
                  />
                }
                {
                  img_1c && <ImgSectionOther
                    style={{
                      top: (((progress * (props.thirdSpeed ? props.thirdSpeed : 8)) - 5 ) * -1) + 'vw'
                    }}
                    src={img_1c}
                  />
                }
                {
                  img_1d && <ImgSectionOther 
                    style={{
                      top: ((progress * (props.secondSpeed ? props.secondSpeed : 8)) - 5 ) + 'vw'
                    }}
                    src={img_1d}
                  />
                }
                 {
                  img_1e && <ImgSectionOther
                    style={{
                      top: (((progress * (props.thirdSpeed ? props.thirdSpeed : 8)) - 5 ) * -1) + 'vw'
                    }}
                    src={img_1e}
                  />
                }
              </div>
            )}
          </Scene>
        </Controller>
        
      </ImgSectionContainer>
  )
}

export default RanisilverImage;