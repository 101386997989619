import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`

export const Img = styled.div`

  & + & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
`