import styled from 'styled-components';

export const TextMobileWrapper = styled.div`
  // padding: 0 8.33vw;
  color: ${ props => props.color ? props.color : '#232328' };
  font-family: europa, sans-serif;
  text-align: center;
`

export const TextMobileItem = styled.div`
  padding-bottom: 10vw;
`

export const TextMobileContent = styled.div`
  display: ${ props => props.active ? 'block' : ' none' };
  padding-top: 0.8333333333vw;

  p {
    font-weight: 300;
    font-size: 3.88vw;
    line-height: 5.55vw;
    margin: 0;
  }

  strong, b {
    font-size: 3.88vw;
    line-height: 5.55vw;
    display: block;
    font-weight: 700;
  }
`