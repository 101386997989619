import styled from 'styled-components';
import { isMobileSafari, isFirefox, isChrome, isEdge, isSafari, isMobile, isOpera } from 'react-device-detect';

export const Half = styled.div`
  width: 50vw;
  height: ${props => props.fullHeight ? '100%' : 'initial'};
  position: ${props => props.fullHeight ? 'fixed' : 'relative'};
  ${props => props.fullHeight ? 'left: 0' : ''};

  ${(isFirefox || isChrome || isEdge || isSafari || isOpera) && `
    min-height: 100vh;
    min-height: ${ isMobile ? 'calc(var(--vh, 1vh) * 100)' : '100vh'};
  `}

  ${ props => props.center && `
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    @media (max-width: 1023px) {
      justify-content: center;
    }
  `}

  ${ props => props.hideMobile && `
    @media (max-width: 1023px) {
      display: none;
    }
  `}

  ${props => props.notFixed && `
    position: relative;
  `}

  @media (max-width: 1023px) {
    width: 100vw;
    position: initial;
    height: ${ props => props.mobileInitial ? 'initial' : '100%' };
    ${
      props => props.mobile100vh && `
        height: 100vh;
      `
    }
    min-height: auto;
  }
`

export const HalfFull = styled.div`
  width: 50vw;
  height: 100%;
  position: relative;
  ${
    props => props.floatRight && `
      float: right;
    `
  }

  ${(isFirefox || isChrome || isEdge || isSafari || isOpera) && `
    height: 100vh;
    height: ${ isMobile ? 'calc(var(--vh, 1vh) * 100)' : '100vh'};
  `}

  @media (max-width: 1023px) {
    ${
      props => props.positionInitialMobile && `
        position: initial;
      `
    }
    ${
      props => props.mobile100vh && `
        height: 100vh;
        height: ${ isMobile ? 'calc(var(--vh, 1vh) * 100)' : '100vh'};
      `
    }
    ${isMobileSafari && `
        height: -webkit-fill-available;
      `
    }

    min-height: ${props => props.mobileAuto && 'auto'}; 
    width: 100vw;
    height: ${props => props.mobileAuto ? 'auto' : ''};
    text-align: ${props => props.mobileAlign ? props.mobileAlign : ''};
  }

  ${ props => props.hideMobile && `
    @media (max-width: 1023px) {
      display: none;
    }
  `}

  ${
    props => props.isPopUp && `
      @media (max-width: 1023px) {
        position: absolute;
        top: 0;
        z-index: 9999;
      }
    `
  }

  ${
    props => props.hardShowAtDesktop && `
    @media (min-width: 1023px) {
      display: block !important;
    }
    `
  }
`