import styled from 'styled-components';

export const ContactText = styled.div`
  font-family: europa, sans-serif;
  font-weight: 700;
  color: #232328;
  position: fixed;
  top: 57vh;
  padding-right: 10%;

  ${ props => props.mobileHide && `
    @media (max-width: 1023px){
      display: none;
    }
  `}

  @media (max-width: 359px){
    padding-top: 0;
  }

  p {
    font-size: 1.09375vw;
    line-height: 1.875vw;
    letter-spacing: 1.75px;

    @media (min-width: 1800px) {
      font-size: 0.82vw;
      line-height: 1.4vw;
    }
  }

  h1,h2,h3,h4,h5,h6,strong,b {
    font-size: 1.875vw;
    line-height: 2.29vw;
    letter-spacing: 2px;
  }

  span {
    color: #005FFF;
  }
`;