import styled from 'styled-components';

export const ScrollWrapper = styled.div`

`

export const ScrollMobile = styled.div`
  position: ${ props => props.isAbsolute ? 'absolute' : 'fixed'};;
  left: 50%;
  width: 22px;
  height: 21px;
  margin-left: -11px;
  bottom: 1.2vh;

  @media (min-width: 1023px) {
    display: none;
  }

  #scroll-1 {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: scroll-1;
  
    @keyframes scroll-1 {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  #scroll-2 {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: scroll-2;
  
    @keyframes scroll-2 {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  
`

export const ScrollDesktop = styled.div`
  position: ${ props => props.isAbsolute ? 'absolute' : 'fixed'};;
  left: 50%;
  width: 1.5625vw; // 30px
  height: 2.7vw; // 52
  margin-left: -0.78125vw; // -15
  bottom: 5vh;
  margin-top: -1.35vw; // -28
  box-shadow: inset 0 0 0 0.15625vw ${ props => props.isWhite ? '#F9F8F6' : '#232328'};
  border-radius: 0.98vw; // 19
  z-index: 2;

  @media (max-width: 1023px) {
    display: ${ props => props.isOnMobileAlso ? 'block' : 'none' };
  }
  @media (min-width: 1023px) {
    display: ${ props => props.isOnMobileAlso ? 'none' : 'block' };
  }

  &:before {
    position: absolute;
    left: 50%;
    content: '';
    width: 0.3125vw; // 6
    height: 0.3125vw; // 6
    background: ${ props => props.isWhite ? '#F9F8F6' : '#232328'};
    margin-left: -0.15625vw; // 3
    top: 0.3125vw; // 6
    border-radius: 50%;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: scroll;
  }

  @keyframes scroll {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(1.77vw); // 34
    }
  }
`

export const Scroll = styled.div`
  position: ${ props => props.isAbsolute ? 'absolute' : 'fixed'};;
  left: 50%;
  width: 30px;
  height: 52px;
  margin-left: -15px;
  bottom: 5vh;
  margin-top: -26px;
  box-shadow: inset 0 0 0 3px ${ props => props.isWhite ? '#F9F8F6' : '#232328'};
  border-radius: 19px;

  @media (max-width: 1023px) {
    display: ${ props => props.isOnMobileAlso ? 'block' : 'none' };
  }
  @media (min-width: 1023px) {
    display: ${ props => props.isOnMobileAlso ? 'none' : 'block' };
  }

  &:before {
    position: absolute;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    background: ${ props => props.isWhite ? '#F9F8F6' : '#232328'};
    margin-left: -3px;
    top: 6px;
    border-radius: 3px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: scroll;
  }

  @keyframes scroll {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(34px);
    }
  }
`

