import styled from 'styled-components';

export const Contact = styled.div`
  font-weight: 700;
  font-size: 2.5vw;
  line-height: 3.125vw;
  letter-spacing: 1.5px;
  font-family: europa, sans-serif;
  color: #F9F8F6;
  padding: 8.33vw 0 calc(50vh - 2.5vw);

  @media (max-width: 1023px){
    font-size: 5.55vw;
    line-height: 6.66vw;
    text-align: center;
    padding-top: 13.88vw;
    padding-bottom: 10vw;

    svg {
      display: none;
    }
  }

  a {
    color: ${ props => props.color};
    text-decoration: none;
    display: block;

    svg {
      padding-left: 1.04vw;
      width: 3.4375vw;
      height: 1.927vw;
    }
  }
`