import React,{ useState, useEffect} from 'react';
import { connect } from "react-redux";

import { loading } from "./../../actions/index";

import { LoadingContainer, LoadingContainerWrap, LoadingContainerText, LoadingContainerWrapSVG, LoadingContainerWrapText, LoadingContainerWrapTextSlide } from './loading.styled';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { colors } from '../../colors/colors';
import splitter from 'text-split'
import { toLetterAnimation, fromLetterAnimation } from '../../letter-animation/letter-animation';
import { isMobileOnly, isMobile } from 'react-device-detect';


function mapDispatchToProps(dispatch) {
  return {
    loadingNewState: items => dispatch(loading(items))
  };
}

const Loading = ({loadingNewState}) => {
  const [color, updateColor] = useState(0)
  const [stateLoading, updateStateLoading] = useState(true)
  const [status, updateStatus] = useState(0)
  const [slideText, updateSlideText] = useState(false)
  const [titleShow, updateTitleShow] = useState(false);

  let textRef = React.createRef();

  useEffect(()=> {
    const interval = setInterval(() => {
      if ( color > 6 - 1 ) {
        updateColor( 0 );
      } else {
        updateColor(color + 1 );
      }
    }, 1000)

    return () => clearInterval(interval);
  }, [color])


  useEffect(()=> {
    const interval = setInterval(() => {
      if ( status > 100 ) {
        clearInterval(interval);
        const letters = splitter(document.querySelector('.showtext'))
        fromLetterAnimation(letters, false, true)
        setTimeout( function () {
          
          updateSlideText(true)

          if (isMobile) {
            setTimeout( function () {
              updateStateLoading(false)
              setTimeout( function () {
                loadingNewState(false)
              }, 1200)
            }, 1000)
          } else {
            const home = window.location.pathname === '/'
            // console.log(home ? 'yes' : 'no')
            setTimeout( function () {
              
              toLetterAnimation(letters, false, true)
                setTimeout( function () {
                  updateStateLoading(false)
                 
                  setTimeout( function () {
                    // setTimeout(() => {
                      loadingNewState(false)
                    // }, 500)
                  }, isMobile ? 1500 : 100)
                }, 800)
            }, home ? 1800 : 600)
          }
          
        }, 300)
        
      } else {
        updateStatus(status + 1 );
      }
    }, 0.5)

    return () => clearInterval(interval);
  }, [status, loadingNewState])

  return (
    <LoadingContainer
      stateLoading={stateLoading}
    >
      <LoadingContainerWrap
        status={status < 100}
      >
        <LoadingContainerWrapSVG
          status={status >= 100}
        >
          <svg  width="76.124" height="98.956"  viewBox="0 0 76.124 98.956">
            <g>
              <path fill="rgb(226,134,49)" d="M38.062 22.832a38.062 38.062 0 1 0 38.062 38.055 38.1 38.1 0 0 0-38.062-38.055zm0 60.895A22.837 22.837 0 1 1 60.9 60.887a22.856 22.856 0 0 1-22.838 22.84z"/>
              <path fill="rgb(96,166,68)" d="M70.546 0H5.564a1.838 1.838 0 0 0-1.757 1.907v11.4a1.838 1.838 0 0 0 1.757 1.907h64.982a1.836 1.836 0 0 0 1.757-1.907v-11.4A1.836 1.836 0 0 0 70.546 0z"/>
            </g>
          </svg>
            <LoadingContainerWrapText
              titleShow={true}
            >
              <LoadingContainerWrapTextSlide
                slideText={slideText}
                className="showtext"
                ref={textRef}
                style={{
                  opacity: slideText ? 1 : 0
                }}
              >
                POMARAŃCZA
              </LoadingContainerWrapTextSlide>
          </LoadingContainerWrapText>
        </LoadingContainerWrapSVG>
        <CircularProgressbar
          className="progress" 
          value={status} 
          strokeWidth={isMobileOnly ? 20 : 19}
          styles={buildStyles({
            pathColor: colors[color].color
          })}
        />
      </LoadingContainerWrap>
      <LoadingContainerText
        status={status < 100}
      >
        {parseInt(status * 3.6) < 360 ? parseInt(status * 3.6) : 360}°
      </LoadingContainerText>
    </LoadingContainer>
  )
}

export default connect(null, mapDispatchToProps)(Loading);