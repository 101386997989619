import { createGlobalStyle } from 'styled-components';

export const ContactButton = createGlobalStyle`
  .contact-button {
    position: fixed;
    font-family: europa, sans-serif;
    font-weight: 400;
    font-size: 1.09375vw; 
    line-height: 1.875vw;
    letter-spacing: 2.75px;
    color: #F9F8F6;
    text-decoration: none;
    transform: rotate(-90deg)  translateX(-50%);
    top: 50vh;
    left: 1.458vw; //28px
    transform-origin: 0 0;
    z-index: 5;
    transition: .4s ease-in-out;

    @media (min-width: 1800px) {
      font-size: 0.82vw;
      line-height: 1.4vw;
    }
    
    &.contact-button--animation {
      left: -4vw;
      animation-name: example-icon;
      animation-duration: .3s;
      animation-fill-mode: forwards;

      @keyframes example-icon {
        from {left: -4vw;}
        to {left: 1.458vw;}
      }
    }

    @media (max-width: 1023px) { 
      display: none;
    }

    &--absolute {
      position: absolute;
    }

    &--dark {
      color: #232328;
    }

    &:hover {
      color: #005FFF;
    }

    &--home-main {
      &:hover {
        color: var(--home-color);
      }
    }

    &--portfolio-color {
      color: var(--portfolio-color-active);
      &:hover {
        color: var(--portfolio-color);
      }
    }
  }
  
`