import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

import { Container } from '../../components/grid/container/Container.styled';
import { Half } from '../../components/grid/half/Half.styled';
import { Link } from "react-router-dom";
import MenuIcon from '../../components/menu-icon/menu-icon';
import { TitleWrap, TitleCenter } from '../../components/ui/title/title.styled';
import { HomeIntroTitle, HomeIntroPlus } from '../../components/home-intro/home-intro.styled';
import { ArticleImg, ArticleText, ArticleTitle, ArticleTitleSecond, ArticleTitleWrap, ArticleTitleTitle, ArticleTitleDescription, ArticleTitleDonuts } from './../article/article.styled';
import { TextWrap } from '../../components/portfolio/clients/nivea/nivea.styled';
import DonutChart from '../../components/ui/donat-chart/donat-chart';
import { BG } from '../../components/grid/bg/bg.style';
import { ShowDesktop } from '../../components/grid/showHide/showHide.style';
import { BackMobile } from '../../components/ui/back-mobile/back-mobile.styled';
import { Next } from '../../components/ui/next/next.styled';
import { More } from '../../components/ui/more/more.styled';
import Hrefs from '../../components/hrefs/hrefs';
import SEO from '../../components/seo/seo';
import NotFound from '../not-found/not-found';
import Cookies from '../../components/cookies/Cookies';
import Player from '../../components/portfolio/video/video';

const mapStateToProps = state => {
  return {articles: state.landingsList};
};

const Landing = ({ match, articles, history }) => {
  const [undefinied, updateUndefinied] = useState(false)
  function find(slug) {
    return articles.find(p => p.link === slug);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  useEffect(() => {
    setTimeout(() => {
      updateUndefinied(true)
    }, 3500)
  }, [])

  const previusPage = () => {
    const prev = document.referrer
    const currentHost = window.location.hostname
    if (prev.search(currentHost) &&  prev !== '') {
      window.history.back()
    } else {
      history.push('/')
    }
  }

  let article = find(match.params.slug);
  return (
    <BG
      color={article !== undefined ? article.bg_color : '#F9F8F6'}
    >
      {
        (undefinied && article === undefined) && (
          <NotFound />
        )
      }
      { article !== undefined && 
        <Container dontReverse={true} isMinHeight={true}>
          <SEO 
            title={article.seo && article.seo.title}
            description={article.seo && article.seo.description}
            keywords={article.seo && article.seo.keywords}
          />
          <MenuIcon color={article !== undefined ? article.bg_color : '#F9F8F6'} />
          <BackMobile>
            <Link to={article.landing_link}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
                <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke={article.special_color} strokeWidth="2.3"/>
              </svg>
            </Link>
          </BackMobile>
          <Link 
            className={`contact-button contact-button--dark`} 
            exact to="/">kontakt</Link>
          <TitleWrap isTransform={true}>
            <TitleCenter color={article.special_color}>
            <span style={{
              color: article.special_color
            }} className="icon-signet"></span>
            </TitleCenter>
          </TitleWrap>  
          <Half mobileInitial={true} fullHeight={true}>
            <ShowDesktop>
              <HomeIntroTitle color={article.special_color}>
                { article.title }
              </HomeIntroTitle>
              <HomeIntroPlus>
                <svg xmlns="http://www.w3.org/2000/svg" width="44.44" height="44.439" stroke={article.special_color} viewBox="0 0 44.44 44.439">
                  <g transform="translate(-771.5 -71)">
                    <line x2="44.44" transform="translate(771.5 93.22)" fill="none" strokeWidth="10"/>
                    <line x2="44.44" transform="translate(793.72 71) rotate(90)" fill="none" strokeWidth="10"/>
                  </g>
                </svg>
              </HomeIntroPlus>
            </ShowDesktop>
          </Half>
          <Half mobileInitial={true} fullHeight={false}>
            <div>
              {
              article.article && article.article.map(el => {
                  switch (el.acf_fc_layout) {
                    case 'text':
                      return (
                        <TextWrap>
                          <ArticleText color={ article.text_color } right={el.right}>
                            <Hrefs text={el.content} />
                          </ArticleText>
                        </TextWrap>
                      )
                    case 'link' :
                      return (
                        <TextWrap>
                          <More style={{
                            marginTop: `-1.2vw`,
                            paddingBottom: '0.5vw'
                          }} key={el}
                            dontHideBr={true} color={article.special_color ? article.special_color : ''}>
                            {el.external_url ? <a style={{
                              color: article.special_color ? article.special_color : '',
                            }} href={el.link}>{el.link_text} <svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
                                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={article.special_color ? article.special_color : ''}/>
                              </svg></a> : <Link
                              style={{
                                color: article.special_color ? article.special_color : '',
                              }}
                              to={el.link}>{el.link_text} <svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
                                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={article.special_color ? article.special_color : ''}/>
                              </svg>
                            </Link>}
                          </More>
                        </TextWrap>
                        
                      )
                    case 'title':
                      return (
                        <TextWrap>
                          <ArticleTitle
                            hasCharts={el.charts}
                          >
                            <ArticleTitleWrap
                              hasCharts={el.charts}
                              color={ article.text_color }
                            >
                              {el.change_to_h3 ? (
                                <ArticleTitleSecond>{el.title}</ArticleTitleSecond>
                              ) : (
                                <ArticleTitleTitle>{el.title}</ArticleTitleTitle>
                              ) }
                              {el.description && <ArticleTitleDescription><Hrefs text={el.description} /></ArticleTitleDescription>}
                            </ArticleTitleWrap>
                            {
                              el.charts === true && <ArticleTitleDonuts>
                                {
                                  el.donuts.map(donut => {
                                    return (
                                      <DonutChart 
                                        size={117}
                                        stroke={article.text_color ? article.text_color : article.color}
                                        bgColor={article !== undefined ? article.bg_color : '#ffffff'}
                                        text={donut.in_chart}
                                        max={donut.in_max}
                                        specialChar={donut.special_char}
                                        textColor={article.text_color ? article.text_color : '#232328'}
                                        undertext={donut.under_chart}
                                      />
                                    )
                                  })
                                }
                              </ArticleTitleDonuts>
                            }
                          </ArticleTitle>
                        </TextWrap>
                      )
                    case 'vimeo':
                      return (<Player video={{
                        src: el.url,
                      }} nonMarginable />)
                    case 'img':
                      return <TextWrap rightZero={true}><ArticleImg src={el.img.url} alt={el.img.alt} /></TextWrap>
                    default:
                      return false;
                  }
                })
              }
            </div>
            <TextWrap>
              <More article={true} landing color={article.special_color}>
                <Link to={article.landing_link}><svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
                    <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={article.special_color}/>
                  </svg> powrót
                </Link>
              </More>
            </TextWrap>
          </Half>
        </Container>
      }
      <Cookies
        color={article !== undefined ? article.special_color : ''}
        background={article !== undefined ? article.bg_color : '#F9F8F6'}
      />
    </BG>
  )
}

export default withRouter(connect(mapStateToProps, null)(Landing));