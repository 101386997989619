import { createGlobalStyle } from 'styled-components';


export const ProdukcjaGlobal = createGlobalStyle`
  .produkcja-section {

    &__section {
      margin-top: 6vw;

      @media (max-width: 1023px) {
        margin-top: 12vw;
      }
    }

    &__img {
      margin-top: 3vw;

      @media (max-width: 1023px) {
        margin-top: 6vw;
      }
    }

    &__text {
      margin-top: 1.5vw;

      @media (max-width: 1023px) {
        margin-top: 3vw;
        padding: 0;
        text-align: center;
      }
    }

    &__text-right {
      margin-top: 1.5vw;
      text-align: right;

      @media (max-width: 1023px) {
        margin-top: 3vw;
        padding: 0;
        text-align: center;
      }
    }

    &__pos {
      &-text {
        text-align: right;
      }
    }

    &__section4 {
      &-text {
        text-align: right;
      }
    }

    &__section6 {
      &-text {
        text-align: right;
      }
    }
  }
`