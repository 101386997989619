import styled from 'styled-components';
import {isMobile} from 'react-device-detect';

/*
czerwony-magenta, 
magenta-fiolet, 
fiolet-niebieski, 
niebieski-zielony, 
zielony-limonka, 
limonka-żółty

rgba(255, 75, 75, 0.25) - rgba(255, 0, 122, 0.25) 
rgba(255, 0, 122, 0.25) - rgba(196, 55, 255, 0.25)
rgba(196, 55, 255, 0.25) - rgba(0, 234, 234, 0.25)
rgba(0, 234, 234, 0.25) - rgba(3, 234, 134, 0.25)
rgba(3, 234, 134, 0.25) - rgba(207, 229, 0, 0.25)
rgba(196, 55, 255, 0.25) - rgba(255, 229, 0, 0.25)

*/

export const BGWrapper = styled.div`
  position: relative;
  background: rgb(35, 35, 40);
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 50%;
    height: 50%;
    border-radius: 100%;
    bottom: -50%;
    left: 25%;
    box-shadow: 0 600px 1200px rgba(255, 75, 75, 0.25);
    animation: animate_4 40s infinite;
    transform: rotate(-180deg);
    z-index: 0;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 50%;
    height: 50%;
    border-radius: 100%;
    top: -75%;
    left: 25%;
    box-shadow: 0 600px 1200px rgba(207, 229, 0, 0.0);
    animation: animate_1 40s infinite;
    z-index: 0;
    transform: scale(1.5);
  }

  /* animate-1 */
  @keyframes animate_1 {
    12% {
      box-shadow: 0 600px 1200px rgba(207, 229, 0, 0);
    }

    25% {
      box-shadow: 0 600px 1200px rgba(255, 0, 122, 0.25);
    }

    37% {
      box-shadow: 0 600px 1200px rgba(255, 229, 0, 0);
    }

    50% {
      box-shadow: 0 600px 1200px rgba(0, 234, 234, 0.25);
    }

    63% {
      box-shadow: 0 600px 1200px rgba(3, 234, 134, 0.25);
    }

    75% {
      box-shadow: 0 600px 1200px rgba(255, 75, 75, 0);
    }

    88% {
      box-shadow: 0 600px 1200px rgba(196, 55, 255, 0.25);
    }

    100% {
      box-shadow: 0 600px 1200px rgba(207, 229, 0, 0);
    }
  }

  /* animate-1 */
  @keyframes animate_4 {
    12% {
      box-shadow: 0 600px 800px rgba(255, 75, 75, 0.25);
    }

    25% {
      box-shadow: 0 600px 1200px rgba(255, 75, 75, 0);
    }

    37% {
      box-shadow: 0 600px 1200px rgba(196, 55, 255, 0.25);
    }

    50% {
      box-shadow: 0 600px 1200px rgba(255, 75, 75, 0);
    }

    63% {
      box-shadow: 0 600px 1200px rgba(255, 229, 0, 0);
    }

    75% {
      box-shadow: 0 600px 1200px rgba(255, 229, 0, 0.25);
    }

    88% {
      box-shadow: 0 600px 1200px rgba(255, 229, 0, 0);
    }

    100% {
      box-shadow: 0 600px 800px rgba(255, 75, 75, 0.25);
    }
  }

`

export const BGGradient = styled.div`
  position: relative;

&:after {
    content: "";
    display: block;
    position: absolute;
    width: 50%;
    height: 100%;
    border-radius: 100%;
    top: 0%;
    right: -75%;
    bottom: -1000px;
    box-shadow: 0 0 850px 800px rgba(255, 0, 122, 0.25);
    animation: animate_3 40s infinite;
    z-index: 0;
    transform: scale(1.5);
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 50%;
    height: 100%;
    border-radius: 100%;
    top: 0%;
    left: -75%;
    box-shadow: 0 0 850px 800px rgba(255, 75, 75, 0);
    animation: animate_2 40s infinite;
    z-index: 0;
    transform: scale(1.5);
  }

  /* animate-2 */
  @keyframes animate_2 {
    12% {
      box-shadow: 0 0 850px 800px rgba(255, 75, 75, 0);
    }

    25% {
      box-shadow: 0 0 850px 750px rgba(255, 0, 122, 0);
    }

    37% {
      box-shadow: 0 0 850px 800px rgba(0, 234, 234, 0.25);
    }

    50% {
      box-shadow: 0 0 850px 750px rgba(255, 0, 122, 0);
    }

    63% {
      box-shadow: 0 0 850px 800px rgba(207, 229, 0, 0.25);
    }

    75% {
      box-shadow: 0 0 850px 750px rgba(255, 0, 122, 0);
    }

    88% {
      box-shadow: 0 0 850px 800px rgba(255, 0, 122, 0.25);
    }

    100% {
      box-shadow: 0 0 850px 800px rgba(255, 75, 75, 0);
    }
  }

  @keyframes animate_3 {
    12% {
      box-shadow: 0 0 850px 800px rgba(255, 0, 122, 0.25);
    }

    25% {
      box-shadow: 0 0 850px 750px rgba(196, 55, 255, 0.25);
    }

    37% {
      box-shadow: 0 0 850px 700px rgba(3, 234, 134, 0);
    }

    50% {
      box-shadow: 0 0 850px 750px rgba(3, 234, 134, 0.25);
    }

    63% {
      box-shadow: 0 0 850px 700px rgba(3, 234, 134, 0);
    }

    75% {
      box-shadow: 0 0 850px 750px rgba(196, 55, 255, 0.25) ;
    }

    88% {
      box-shadow: 0 0 850px 700px rgba(3, 234, 134, 0);
    }

    100% {
      box-shadow: 0 0 850px 800px rgba(255, 0, 122, 0.25);
    }
  }
`
export const BGWrapperSecond = styled.div`
  position: relative;
  background: rgb(35, 35, 40);
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 50%;
    height: 50%;
    border-radius: 100%;
    bottom: -50%;
    left: 25%;
    box-shadow: 0 600px 1200px rgba(255, 75, 75, 0.33);
    animation: animate_41 40s infinite;
    transform: rotate(-180deg);
    z-index: 0;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 50%;
    height: 50%;
    border-radius: 100%;
    top: -75%;
    left: 25%;
    box-shadow: 0 600px 1200px rgba(207, 229, 0, 0);
    animation: animate_11 40s infinite;
    z-index: 0;
    transform: scale(1.5);
  }

  /* animate-11 top */
  @keyframes animate_11 {
    12% {
      box-shadow: 0 600px 1200px rgba(207, 229, 0, 0);
    }

    25% {
      box-shadow: 0 600px 1200px rgba(255, 0, 122, 0.33);
    }

    37% {
      box-shadow: 0 600px 1200px rgba(255, 229, 0, 0);
    }

    50% {
      box-shadow: 0 600px 1200px rgba(0, 234, 234, 0.333);
    }

    63% {
      box-shadow: 0 600px 1200px rgba(3, 234, 134, 0.33);
    }

    75% {
      box-shadow: 0 600px 1200px rgba(255, 75, 75, 0);
    }

    88% {
      box-shadow: 0 600px 1200px rgba(196, 55, 255, 0.33);
    }

    100% {
      box-shadow: 0 600px 1200px rgba(207, 229, 0, 0);
    }
  }

   /* animate-41 bottom */
  @keyframes animate_41 {
    12% {
      box-shadow: 0 600px 800px rgba(255, 75, 75, 0.33);
    }

    25% {
      box-shadow: 0 600px 1200px rgba(255, 75, 75, 0);
    }

    37% {
      box-shadow: 0 600px 1200px rgba(196, 55, 255, 0.33);
    }

    50% {
      box-shadow: 0 600px 1200px rgba(255, 75, 75, 0);
    }

    63% {
      box-shadow: 0 600px 1200px rgba(255, 229, 0, 0);
    }

    75% {
      box-shadow: 0 600px 1200px rgba(255, 229, 0, 0.33);
    }

    88% {
      box-shadow: 0 600px 1200px rgba(255, 229, 0, 0);
    }

    100% {
      box-shadow: 0 600px 800px rgba(255, 75, 75, 0.33);
    }
  }

`

export const BGGradientSecond = styled.div`
  position: relative;

&:after {
    content: "";
    display: block;
    position: absolute;
    width: 50%;
    height: 100%;
    border-radius: 100%;
    top: 0%;
    right: -75%;
    bottom: -1000px;
    box-shadow: 0 0 850px 800px rgba(255, 0, 122, 0.33);
    animation: animate_31 40s infinite;
    z-index: 0;
    transform: scale(1.5);
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 50%;
    height: 100%;
    border-radius: 100%;
    top: 0%;
    left: -75%;
    box-shadow: 0 0 850px 800px rgba(255, 75, 75, 0);
    animation: animate_21 40s infinite;
    z-index: 0;
    transform: scale(1.5);
  }

  /* animate-21 left */
  @keyframes animate_21 {
    12% {
      box-shadow: 0 0 850px 800px rgba(255, 75, 75, 0);
    }

    25% {
      box-shadow: 0 0 850px 750px rgba(255, 0, 122, 0);
    }

    37% {
      box-shadow: 0 0 850px 800px rgba(0, 234, 234, 0.33);
    }

    50% {
      box-shadow: 0 0 850px 750px rgba(255, 0, 122, 0);
    }

    63% {
      box-shadow: 0 0 850px 800px rgba(207, 229, 0, 0.33);
    }

    75% {
      box-shadow: 0 0 850px 750px rgba(255, 0, 122, 0);
    }

    88% {
      box-shadow: 0 0 850px 800px rgba(255, 0, 122, 0.33);
    }

    100% {
      box-shadow: 0 0 850px 800px rgba(255, 75, 75, 0);
    }
  }

  /* animate-31 right */
  @keyframes animate_31 {
    12% {
      box-shadow: 0 0 850px 800px rgba(255, 0, 122, 0.33);
    }

    25% {
      box-shadow: 0 0 850px 750px rgba(196, 55, 255, 0.33);
    }

    37% {
      box-shadow: 0 0 850px 700px rgba(3, 234, 134, 0);
    }

    50% {
      box-shadow: 0 0 850px 750px rgba(3, 234, 134, 0.33);
    }

    63% {
      box-shadow: 0 0 850px 700px rgba(3, 234, 134, 0);
    }

    75% {
      box-shadow: 0 0 850px 750px rgba(196, 55, 255, 0.33) ;
    }

    88% {
      box-shadow: 0 0 850px 700px rgba(3, 234, 134, 0);
    }

    100% {
      box-shadow: 0 0 850px 800px rgba(255, 0, 122, 0.33);
    }
  }
`

export const BG = styled.div`
  background: ${props => props.color};
  min-height: 100vh;
  min-height: ${ isMobile ? 'calc(var(--vh, 1vh) * 100)' : '100vh'};
  overflow: ${props=>props.overflow};
  transition: background .3s;
  position: relative;
  ${
    props => props.maxHeight ? `
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
    ` : ''
  }

  @media (max-width: 1023px) {
    ${ 
      props => props.forMobileMiddle && `
        display: flex;
        flex-wrap: wrap;
        align-content: center;
      `
    }

    ${
      props => props.mobileMinHeight && `
        min-height: 100vh;
        min-height: ${ isMobile ? 'calc(var(--vh, 1vh) * 100)' : '100vh'};
      `
    }
  }

  @media (max-width: 1023px) and (min-width: 767px) {
    display: flex;
    flex-wrap: wrap;
  }

  &.production-section {
    background: #232328;
    transition: .3s ease-in;

    .slider-produtction {
      color: #fff;
      span {
        &::before {
          background: var(--production-color);
        }
      }
    }

    .menu-production {
      rect {
        fill: #fff;
      }
    }

    .show-hide-mobile-produkcja {
      color: #ffff;

      div {
        color: var(--production-color);
      }
    }

    .contact-production {
      .contact-button  {
        color: #fff;
      }
    }

    .title-production {
      display: none;

      @media (max-width: 1023px) {
        display: block;
        text-align: center;
        color: #ffffff;
        width: 100%;

        h1 {
          color: #fff;
        }
      }
    }

    .more-produkcja {
      a {
        color: var(--production-color);
      }
  
      path {
        fill: var(--production-color); 
      }
    }
    
  }

  .title-production {
    width: 100%;
  }

  
`