import styled  from 'styled-components';
import { isTablet } from 'react-device-detect';

export const HomeIntroImg = styled.div`
  width: 100%;
  margin-top: 5.2vw;
  background-image: url('${props => props.src}');
  height: 50vh;
  width: 100%;
  background-size: cover;
  background-position: center;

  @media (min-height:767px) and (max-width: 1023px) {
    height: 100%;
    width: auto;
    max-height: 30vh;
    margin-top: 0;
    margin-bottom: 60px;
  }
`

export const HomeContactHover = styled.div`
  .contact-button {
    &:hover {
      color: ${ props => props.hoverColor }
    }
  }
`

export const HomeIntroMobileImg = styled.div`
  width: 100%;
  padding-bottom: calc(100% - 60px);
  background-image: url(${props => props.image ? props.image : ''});
  background-size: ${props => props.imgFullMobile ? 'cover' : '100%'};
  margin: -7.7vw 0 7.77vw;
  background-position: center;
  background-repeat: no-repeat;

  ${
    isTablet && `
      padding-bottom: calc(55% - 60px);
    `
  }

  @media (max-width: 359px) {
    padding-bottom: calc(100% - 150px);
  }
`

export const HomeIntroText = styled.div`
  font-family: europa, sans-serif;
  font-weight: 300;
  color: ${ props => props.dark ? '#F9F8F6' : '#232328'};
  padding-right: 10%;

  p {
    font-size: 1.09375vw;
    line-height: 1.875vw;
    letter-spacing: 1.75px;

    @media (min-width: 1800px) {
      font-size: 0.82vw;
      line-height: 1.4vw;
    }
  }

  h1,h2,h3,h4,h5,h6,strong,b {
    font-size: 1.09375vw;
    line-height: 1.875vw;
    letter-spacing: 2px;
    display: block;
    margin-bottom: 0.5208333333vw;
    font-weight: 700;

    @media (min-width: 1800px) {
      font-size: 0.82vw;
      line-height: 1.4vw;
    }
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

export const HomeIntroTitle = styled.h2`
  position: absolute;
  top: 50%;
  transform: translateY(-1.5625vw);
  right: 31.25%;
  left: 11.625%;
  text-align: right;
  color: ${ props => props.color };
  font-family: europa, sans-serif;
  font-weight: 700;
  font-size: 2.5vw;
  line-height: 3.125vw;
  margin: 0;

  span {
    position: relative;
    display: inline-block;
  }

  @media (max-width: 1023px) {
    position: relative;
    transform: none;
    top: initial;
    right: initial;
    left: initial;
    font-size: 9.16vw;
    line-height: 9.16vw;
    text-align: center;
    padding-top: 19.4vw;
    padding-left: 8.33vw;
    padding-right: 8.33vw;
  }
`

export const HomeIntroPlus = styled.div`
  position: absolute;
  top: 50%;
  right: 15.625%;
  transform: translateY(-50%);

  svg {
    width: 2.31vw;
    height: 2.31vw;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

export const HomeIntroTeaser = styled.div`
  font-family: europa, sans-serif;
  font-weight: 700;
  color: ${ props => props.dark ? '#F9F8F6' : '#232328'};
  font-size: 5.55vw;
  line-height: 6.94vw;
  padding: 0 8.3vw;
  text-align: center;
  display: none;
  margin-top: 7.5vw;
  @media (max-width: 1023px) {
    display: block;
  }

  @media (max-width: 1023px) and (orientation: landscape) {
    margin-top: 1.38w;
  }
`