import { createGlobalStyle } from 'styled-components';


export const FortunaGlobal = createGlobalStyle`

  .contact-button {
      color: #000000;
  }

  .custom-plus {
    stroke: #000000;
  }

  .color-fortuna {
    color: #000000;
  }

  .fortuna-logotype {
    svg {
      fill: #000000;
    }
  }

  .margin-top-extra {
    margin-top: 2.2vw;
  }

  .fortuna {
    &__comments_image_1 {
      margin-top: 3.2vw;
      padding-left: 7%;
      padding-right: 7%;
    }

    &__gifs {
      padding: 0 8%;
      margin-bottom: 2.2vw;

      > div {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        overflow: hidden;
      }

      img {
        margin-left: -2px;
        margin-bottom: -2px;
      }
    }

    &__leszek_image_1 {
      margin-top: 2.2vw;
      margin-bottom: 2.2vw;
      padding-left: 7%;
      padding-right: 12%;
    }

    &__donuts {
      padding-top: calc(50vh - 3.3vw);
      @media (max-width: 1023px){
        padding-top: 40px;
      }
    }

    &__komes_image_1 {
      margin-top: 2.2vw;
      margin-bottom: 2.2vw;
      margin-right: 4%;
      margin-left: 4%;
    }

    &__komes_image_2 {
      margin-top: 2.2vw;
      margin-bottom: 2.2vw;
      margin-right: -4%;
      margin-left: 4%;
    }

    &__miloslaw_image_1 {
      margin-top: 2.2vw;
      margin-bottom: 2.2vw;
      margin-right: -5%;
      margin-left: -5%;
    }
  }

  .synoptis {

    &__comments_image_1 {
      margin-top: 5.2vw;
      padding-left: 2%;
      padding-right: 2%;
    }

    &__intro {
      padding-bottom: 2vw;
    }

    &__donuts {
      padding-top: calc(70vh - 3.3vw);
      @media (max-width: 1023px){
        padding-top: 40px;
      }
    }

    &__section1_image_1 {
      margin-top: 5.2vw;
      margin-bottom: 5.2vw;
      padding-left: 6%;
      padding-right: 2%;
    }

    &__section1_image_2 {
      padding-right: 2%;
      margin-bottom: 5.2vw;
    }

    &__section2_image_1 {
      margin-left: -5.2vw;
    }

    &__section2_image_2 {
      margin-top: 5.2vw;
      margin-bottom: 5.2vw;
      padding-left: 6%;
      padding-right: 2%;
    }

    &__section2_image_3 {
      margin-left: -5.2vw;
    }

    &__section3_image_1 {
      padding-left: 6%;
      padding-right: 6%;
      margin-bottom: 5.2vw;
    }

    &__section3_image_2 {
      margin-top: 5.2vw;
      padding-left: 6%;
      padding-right: 6%;
    }

    &__section3_image_3 {
      padding-left: 8%;
      padding-right: 8%;
    }


    &__section3 {
      margin-bottom: 4.8125vw;
    }

    &__section4_image_1 {
      margin-top: 2.2vw;
      margin-bottom: 2.2vw;
      padding-left: 8%;
      padding-right: 8%;
    }

    &__section4_image_2 {
      margin-left: -5.2vw;
      padding-right: 6%;
    }
  }

  .text-right {
    text-align: right;
  }


`