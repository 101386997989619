import React from 'react';
import Swiper from 'react-id-swiper';
import { connect } from "react-redux";


import { AboutUsWrap, AboutUsWrapCarousel, AboutUsImg, AboutUsSlide } from './about-us-carousel.styled';
import { aboutUs } from "./../../actions/index";
import { colors } from '../../colors/colors';

function mapDispatchToProps(dispatch) {
  return {
    aboutUs: colors => dispatch(aboutUs(colors))
  };
}

const AboutUsCarousel = ({aboutUs, aboutUsList}) => {
  const params = {
    initialSlide: aboutUsList.persons.length / 2,
    lazy: true,
    loop: true,
    centeredSlides: true,
    direction: 'vertical',
    mousewheel: {
      eventsTarged: '#about-scroll',
      releaseOnEdges: true,
      sensitivity: 1,
    },
    simulateTouch: false,
    mousewheelControl: true,
    mousewheelForceToAxis: true,
    mousewheelReleaseOnEdges: false,
    freeMode: true,
    freeModeSticky: false,
    slidesPerView: 4,
    longSwipes: false,
    on: {
      init: function () {

        const hide = (e) => {
          if(e.target.nodeName !== 'IMG' && this.slides) {
            aboutUs(null)
            document.querySelector('#about-scroll').style.background = null
            Object.keys(this.slides).forEach(item => {
              const element = this.slides[item].childNodes
              if ( element ) {
                const n = element[0].parentNode.dataset.swiperSlideIndex
                element[0].classList.remove('bigger')
                element[0].classList.remove('smaller')
                element[0].src = aboutUsList.persons[n].foto_1.url
              }
            })
          }
        }

        document.querySelector('#about-scroll').addEventListener('wheel', hide)
        document.querySelector('#about-scroll').addEventListener('click', hide)
        Object.keys(this.slides).forEach(item => {
          this.eld = null
          const element = this.slides[item].childNodes

          
          if ( element ) {
            element[0].addEventListener('mouseenter', (el) => {
              const data = el.target.parentNode.dataset.swiperSlideIndex
              if (parseInt(this.eld) !== parseInt(data)) {
                el.target.src = aboutUsList.persons[data].foto_2.url
              }
              
            })
            element[0].addEventListener('mouseout', (el) => {
              const data = el.target.parentNode.dataset.swiperSlideIndex
              
              if (parseInt(this.eld) !== parseInt(data)) {
                el.target.src = aboutUsList.persons[data].foto_1.url
              }
            })
            element[0].addEventListener('click', (el) => {

              if(!el.target.classList.contains('bigger')) {
                const data = el.target.parentNode.dataset.swiperSlideIndex
                this.eld = data
                aboutUs(data)
                this.slideTo(item)
                const rand = Math.floor((Math.random() * 6))

                document.querySelector('#about-scroll').style.background = colors[rand].color
                Object.keys(this.slides).forEach(item => {
                  const element = this.slides[item].childNodes
                  if ( element ) {
                    const n = element[0].parentNode.dataset.swiperSlideIndex
                    if ( parseInt(n) === parseInt(data)) {
                      element[0].classList.add('bigger')
                      element[0].classList.remove('smaller')
                      element[0].src = aboutUsList.persons[n].foto_3.url
                    } else {
                      element[0].classList.add('smaller')
                      element[0].classList.remove('bigger')
                      element[0].src = aboutUsList.persons[n].foto_1.url
                    }
                  }
                })
              }
            })
          }
        })
      }
    }
  }
  const IsEven = (e) => {
    return e % 2 === 0 ? true : false
  }
  return(
    <AboutUsWrap>
      <AboutUsWrapCarousel>
        <Swiper {...params} className="carousel">
            {
              aboutUsList.persons.map((el, i) => {
                return (
                  <AboutUsSlide
                    key={i}
                  >
                    <AboutUsImg 
                      className={`${IsEven(i) && 'second-style'}`}
                      src={el.foto_1.url}
                      alt={el.foto_1.alt}
                    />
                  </AboutUsSlide>
                )
              })
            }
          </Swiper>
      </AboutUsWrapCarousel>
    </AboutUsWrap>
  )
}

export default connect(null, mapDispatchToProps)(AboutUsCarousel);