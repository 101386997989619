export const fromLetterAnimation = (words, delay, positionRight) => {
  words.forEach((el, i) => {
    const delayType = positionRight ? (i * .1) : (((i - words.length) * -1) * .1)
    el.style.animationDelay = `${(delay ?  delay + (delayType / 10) : delayType / 2)}s`
    el.classList.remove('fadeOut')
    el.classList.add('animated')
    el.classList.add('fadeIn')
    el.classList.add('faster')
  })
}

export const toLetterAnimation = (words, delay, positionRight) => {
  words.forEach((el, i) => {
    
    const delayType = positionRight ? (((i - words.length) * -1)* .1) : (i * .1)
    el.style.animationDelay = `${(delay ? delay + (delayType / 10 ) : delayType / 2)}s`
    el.classList.remove('fadeIn')
    el.classList.add('animated')
    el.classList.add('fadeOut')
    el.classList.add('faster')
  })
}