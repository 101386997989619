import { createGlobalStyle } from 'styled-components';


export const AltaxGlobal = createGlobalStyle`

  .altax-section {

    &__viva {
      margin-bottom: 6.5vw;
    }

    &__viva-img1 {
      margin-top: 5.2vw;
      margin-bottom: 5.2vw;
    }

    &__viva-text1 {
      text-align: right;
    }

    &__viva-img2 {
      margin-top: 5.2vw;
      margin-bottom: 6.5vw;
      margin-right: 90px;

      @media (max-width: 1023px){
        margin-right: 0;
      }
    }

    &__viva-text2 {

    }

    &__viva-img3 {
      margin-top: 5.2vw;
      margin-right: 70px;

      @media (max-width: 1023px){
        margin-right: 0;
      }
    }


    &__www {
      margin-bottom: 6.5vw;
    }

    &__www-img1 {
      margin-top: 3.125vw;
    }


    &__pr {
      margin-bottom: 6.5vw;
    }

    &__pr-text1 {
      text-align: right;
    }

    &__pr-img1 {
      margin-top: 6.5vw;
      margin-bottom: 0.5vw;
    }

    &__pr-img2 {

    }


    &__opakowania {
      margin-bottom: 7.8125vw;
    }

    &__opakowania-img1 {
      margin-top: 6.5vw;
    }

    &__pos {
      margin-bottom: 6.77vw;
    }

    &__pos-text1 {
      text-align: right;
    }

    &__pos-img1 {
      margin-top: 4.6875vw;
    }

    &__pos-img2 {
      margin-top: 7.8125vw;
    }

  }
`