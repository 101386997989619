import styled, { createGlobalStyle } from 'styled-components';

export const MainWrap = styled.div`
  transition: background 0.6s ease-in;
`

export const Global = createGlobalStyle`
  .fade-enter {
    opacity: 0;
    z-index: 1;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }
`