import styled from 'styled-components';

export const TextText = styled.div`
  color: ${ props => props.color ? props.color : '#232328' };
  font-family: europa, sans-serif;
  position: absolute;
  left: ${props => props.left ? props.left : '0'};
  font-weight: ${props => props.bold ? '700' : '300'};
  top: 60%;
  right: 14%;
  font-size: 1.09375vw;
  line-height: 1.875vw;
  letter-spacing: 1.75px;

  ${
    props => props.isSmallTitle && `
      h1 {
        font-size: 1.09375vw;
        line-height: 1.875vw;
        letter-spacing: 1.75px;
        padding-bottom: 0.38125vw;
        margin: .67em 0 0 0;

        @media (min-width: 1800px) {
          font-size: 0.82vw;
          line-height: 1.4vw;
        }

        @media (max-width: 1023px) {
          font-size: 4.25vw; 
          line-height: 8.33vw;
        }

        @media (max-width: 359px) {
          font-size: 12px;
        }
      }
    `
  }

  @media (min-width: 1800px) {
    font-size: 0.82vw;
    line-height: 1.4vw;
  }

  @media (max-width: 1023px) {
    font-size: ${props => props.homeMobileSmall ? '3.8vw' : '4.25vw'}; 
    line-height: 8.33vw;
    padding: 0 8.33vw;
    text-align: center;
    position: initial;
    top: initial;
    right: initial;
    padding-top: ${props => props.homeMobileSmall ? '0' : '8.33vw'};
    display: ${props => props.mobileHide ? 'none' : 'block'};

    ${ props => (props.homeMobileSmall && !props.clients ) && `
      position: absolute;
      top: calc(50% - 83px);
    `}

    ${
      props => props.mobileExtra && `
        display: block;
        line-height: 5.8vw;
        margin-top: -4vh;
      `
    }
  }

  @media (min-height:767px) and (max-width: 1023px) {
    padding-top: 2vw;
  }

  @media (max-width: 1023px) and (orientation: landscape) {
    padding-top: 0;
  }

  @media (max-width: 359px){
    padding-top: 0;
    font-size: 12px;
  }

  strong, b {
    display: block;
    padding-bottom: 0.38125vw;
    font-weight: 700;
  }

  

  a {
    color: #005FFF;
    text-decoration: none;
  }
`;