import styled from 'styled-components';


export const Email = styled.div`
  font-size: 2.6vw;
  line-height: 8.23vw;
  font-family: europa, sans-serif;
  font-weight: 700;
  z-index: 3;
  position: relative;
  letter-spacing: 1.5px;

  ${ props => props.mobile && `
    display: none;

    @media (max-width: 1023px) {
      display: block;
    }
  `}

  @media (max-width: 1023px) {
    font-size: 6.1vw;
    line-height: initial;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 10.55vw;

    ${ props => props.first && `
      padding-top: 16.6vw;
    `};
  }

  @media (max-width: 359px){
    padding-top: 0;
  }

  // @media (min-height:767px) and (max-width: 1023px) and (min-width: 450px) {
  //   padding-bottom: 0;
  //   padding-top: 0;
  // }

  a {
    color: #232328;
    text-decoration: none;
    transition: color .3s;

    @media (max-width: 1023px) {
      display: block;
      width: 100%;
      text-align: center;

      svg {
        display: none;
      }
    }

    &:hover {
      color: #005FFF;
    }
  }

  svg {
    width: 3.125vw;
    height: 2.21vw;
    margin-bottom: -0.2vw;

    @media (max-width: 1023px) {
      width: 7.7vw;
      height: 5.5vw;
    }
  }
`

export const EmialIcon = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`

export const PhoneIcon = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: block;

    svg {
      width: 10.78vw;
      height: 7.7vw;
    }
    
  }
`