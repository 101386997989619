import styled from 'styled-components';

export const ImgSectionContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const ImgSectionBase = styled.img`
  width: 100%;
  display: flex;
  transition: .3s;
  ${
    props => props.layerUp && `
      position: relative;
      z-index: 2;
    `
  }
  ${
    props => props.opacityScroll && `
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    `
  }
`;

export const ImgSectionOther = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: .3s;
`;
