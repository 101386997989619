import { createGlobalStyle } from 'styled-components';


export const AmicaGlobal = createGlobalStyle`

  .kruszwica {

    &__kujawski {
      margin-bottom: 5.8vw;

      &__img-1 {
        margin-left: 22%;
        margin-top: 1vw;
      }

      &__text-1 {
        max-width: 40%;
        margin-top: -5vw;
      }
    }

    &__smakowita {
      margin-bottom: 5.8vw;
      
      &__img-1 {
        margin-top: 1vw; 
        margin-right: 15%;
        margin-left: 10%;
      }

      &__img-2 {
        margin-top: 2vw;
        margin-left: -5%;
      }

      &__text-1 {
        max-width: 60%;
      }

      &__img-3 {
        margin-top: 1vw;
        margin-right: -5%;
      }
    }

    &__optima {
      margin-bottom: 5.8vw;

      &__img-1 {
        margin-top: 4vw;
        margin-bottom: 3vw;
        margin-right: 6%;
      }
    }

    &__eb {
      margin-bottom: 4vw;

      &__img-1 {
        margin-top: 1vw;
        margin-right: 5%;
        margin-left: 7%;
      }

      &__img-2 {
        margin-top: 1vw;
        margin-right: 5%; 
      }
    }

    &__right {
      text-align: right !important;
    }

    &__pos {

      &__img-1 {
        margin-top: 4.2vw;
        margin-bottom: -3vw;
        padding-left: 13%;
        padding-right: 11%;
      }
      &__img-2 {
        margin-top: 5vw;
        margin-right: 3%;
      }

      &__img-3 {
        margin-top: 5vw;
        margin-left: 30%;
      }
    }
  }

`