import styled from 'styled-components';
import { isTablet } from 'react-device-detect';


export const AboutUsMobileWrap = styled.div`
  display: none;
  @media (max-width: 1023px){
    display: block;
  }
`

export const AboutUsMobileCarousel = styled.div`
  margin-left: -13vw;

  .swiper-container {
    max-height: 80vw;
    margin-top: -40px;
    margin-left: -25px;
  }

  @media (min-height:767px) and (max-width: 1023px) {
    .swiper-container {
      max-height: 80vw;
      ${
        isTablet && `
        max-height: 50vh;
        margin-top: -30vw;
        `
      }
    }
  }


  &.start {
    .swiper-slide {
      width: 25% !important;

      &.swiper-slide-active {
        img {
          filter: grayscale(100%);
          width: 96%;
          opacity: 0.5;
        }

        + div {
          img {
            margin-left: -48%;
            width: 144%;
          }

          + div {
            img {
              margin-left: -48%;
              width: 176%;
            }
          }
        }
      }
    }
  }
`

export const AboutUsMobileCarouselItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  img {
    opacity: 0.5;
  }

  &.swiper-slide-prev,
  &.swiper-slide-next {
    z-index: 3;
  }

  &.swiper-slide-prev {
    img {
      margin-left: 0%;
    }
  }

  &.swiper-slide-next {
    img {
      margin-left: -10%;
    }
  }

  &.swiper-slide-active {

    width: 50vw !important;

    img {
      width: 136%;
      opacity: 1;
      transition: .6s;

      @media (min-height:767px) and (max-width: 1023px) {
        width: 104%;
      }
    }
  }
`

export const AboutUsMobileCarouselItemImg = styled.img`
  width: 40vw;
  transition: .3s;
  width: 120%;
  margin-left: -10%;
  pointer-events: none;

  @media (min-height:767px) and (max-width: 1023px) {
    width: 130%;
    margin-left: -5%
  }
`

export const AboutUsMobileText = styled.div`
  padding: 0 8.33vw;
  text-align: center;
  font-family: europa, sans-serif;
  color: #232328;
  margin-top: -2vw;

  p {
    font-size: 4.44vw;
    line-height: 5.55vw;
    margin: 0;
  }

  strong, b {
    display: block;
    font-size: 5.55vw;
    line-height: 5.55vw;
    letter-spacing: 1.75px;
    margin-bottom: 2.77vw;

    span {
      color: #005FFF;
    }
  }
`

export const AboutUsMobileTextPerson = styled.div`

`