import React from 'react';

import Main from '../../components/portfolio/Main';

const Portfolio = () => {
  return (
    <div>
      <Main></Main>
    </div>
  )
}

export default Portfolio;