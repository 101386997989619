import styled from 'styled-components';

export const More = styled.div`
  padding-top: ${ props => props.article ? '0' : '0.5208333333vw'};
  padding-bottom: ${props => props.article ? '6.25vw' : '0' };
  margin-top: ${props => props.article ? '-2vw' : ''};
  ${props => props.landing ? 'margin-top: 2vw': ''};
  a {
    font-family: europa, sans-serif;
    font-weight: 700;
    color: ${ props => props.color };
    text-decoration: none;
    font-size: 1.09375vw;
    line-height: 1.2vw;
    letter-spacing: 1.75px;
    cursor: pointer;
  }
  svg {
    width: ${ props => props.client ? '1.458' : '1.458' }vw;
    height: ${ props => props.client ? '1.458' : '1' }vw;
    margin-bottom: ${ props => props.client ? '-0.3' : '-0.1'}vw;
    transform: ${ props => props.article ? 'rotate(180deg)' : ''};
    ${ props => props.article && `
      top: 1px;
      position: relative;
      padding-left: 10px;
    `}
    ${ props => props.noneRotate ? 'transform: initial;padding-left: 0; padding-right: 10px; top: 2px;': ''};
  }

  @media (max-width: 1023px) {
    ${
      props => props.hideMobile ? 'display: none;' : ''
    }
    ${
      props => props.absolute ? `
        margin-top: 0;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        bottom: 9.33vw;
        text-align: center;
      ` : `margin-top: 3vw;`
    }
    
    ${props => props.article ? 'display: none' : ''};

    a {
      font-size: 4.16vw;
      line-height: 5.83vw;

      br {
        display: ${ props => props.dontHideBr ? 'block' : 'none'};
      }
    }

    svg {
      display: block;
      margin: 0 auto;
      margin-top: 3vw;
      height: 4.44vw;
      width: 7.5vw;
    }
  }

  @media (min-height:767px) and (max-width: 1023px) {
    svg {
      margin-top: 5px;
    }
  }

  @media (max-width: 1023px) and (orientation: landscape) {
    svg {
      margin-top: 5px;
    }
  }
`