import styled from 'styled-components';

export const TextCenter = styled.div`
  position: absolute;
  height: 49.5vh;
  text-align: right;
  width: 80%;
  bottom: 4.4vw;

  @media (max-width: 1023px) {
    position: relative;
    width: calc(100% - 16.6vw);
    padding: 0 8.3vw;
    bottom: initial;
  }
`;