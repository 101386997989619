import React from 'react'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import MenuIcon from '../menu-icon/menu-icon';
import { MainWrap, Global } from './Main.styled'
import { Half } from '../grid/half/Half.styled'
import { Container } from '../grid/container/Container.styled';
import Carousel from './carousel/carousel';
import Routes from '../../routes/routes';
import { MIN_ANIMATION } from '../../config/config';

const mapStateToProps = state => {
  return {colors: state.colors};
};

function Main ({ location, colors }) {
  const childs = Routes.portfolio.childs

  return (
    <MainWrap className="mainClassOfCaseStudy" style={{
      background: colors.portfolio ? colors.portfolio.color : '',
      minHeight: '100vh'
    }}>
      <Global />
      <MenuIcon 
        isPortfolio={true}
      />
      <div
          className="animated fadeInLeft faster" 
          style={{
            animationDelay: `1s`,
            zIndex: 4,
            position: 'fixed',
            left: 0,
          }}
      ><Link style={{animationDelay: `${MIN_ANIMATION + 0.7}s`}} className="contact-button--animation contact-button contact-button--dark contact-button--portfolio-color" to={Routes.kontakt.slug}>kontakt</Link></div>
      <Container>
        <Half fullHeight={true}>
          <Carousel color={colors.portfolio && colors.portfolio.color}/>
        </Half>
        <Half fullHeight={false}>
          <TransitionGroup>
            <CSSTransition
              classNames="fade"
              timeout={2000}
            >
              {
                Object.keys(childs).length > 0 && <Switch location={location}>
                  {
                    Object.keys(childs).map((el,i) => {
                      return (
                        <Route key={i} exact={childs[el].exact} path={childs[el].slug} component={()=>{return(childs[el].component)}}  />
                      )
                    })
                  }
                </Switch>
              }
            </CSSTransition>
          </TransitionGroup>
        </Half>
      </Container>
    </MainWrap>
  );
}

export default  connect(mapStateToProps, null)(Main);