import styled from 'styled-components';


export const RodoText = styled.div`
  font-family: europa, sans-serif;
  font-weight: 700;
  color: #232328;
  text-align: right;
  padding-right: 20%;
  padding-left: 13%;
  margin-top: calc(50vh - 1.545vw);
  padding-bottom: 50px;

  a {
    color: #005FFF;
    text-decoration: none;
  }

  @media (max-width: 1023px) {
    margin-top: 0;
    padding-left: 8.3vw;
    padding-right: 8.3vw;
    text-align: left;
  }

  h1,h2,h3,h4,h5,h6 {
    line-height: 2.29vw;
    font-size: 2.34375vw;
    letter-spacing: 3px;

    @media (max-width: 1023px) {
      font-size: 3.88vw;
      line-height: 5.5vw;
    }
  }

  p {
    font-size: 1.875vw;
    line-height: 2.29vw;
    letter-spacing: 3px;

    @media (max-width: 1023px) {
      font-size: 3.88vw;
      line-height: 5.5vw;
      font-weight: 400;
    }
  }
`