import styled from 'styled-components';

export const PortfolioMobileLogo = styled.div`
  justify-content: center;
  padding-top: 25.55vw;
  display: none
  @media (max-width: 1023px){
    display: flex;
  }

  svg {
    max-width: 50%;
    height: auto;
    fill: #fff;
  }
`

export const PortfolioMobileText = styled.div`
  font-family: europa, sans-serif;
  font-weight: 300;
  color: #F9F8F6;
  font-size: 5.55vw;
  line-height: 7.22vw;
  text-align: center;
  padding-left: 8.33vw;
  padding-right: 8.33vw;
  padding-top: 13.88vw;
  display: none
  @media (max-width: 1023px){
    display: block;
  }
`