import styled from 'styled-components';
import { osName, isAndroid } from 'react-device-detect';


export const LoadingContainer = styled.div`
  position: fixed;
  z-index: ${props => props.stateLoading ? 999 : -990};
  background: rgb(35, 35, 40);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${props => props.stateLoading ? 1 : 0};
  transition: .3s;
`

export const LoadingContainerWrap = styled.div`
  position: absolute;
  width: 3.958vw;
  top: 50%;
  left: 50%;
  transform: ${ osName === 'Mac OS' ? `translate(calc(-50% - 0.1vw), calc(-50% + 0.4vw))` : `translate(calc(-50% - 0.1vw), calc(-50% + 0.3vw))`};
  font-size: 5.6vw;
  line-height: 8.3vw;
  color: #F9F8F6;
  font-family: Intro,sans-serif;
  font-weight: 700;

  @media (max-width: 1023px) {
    width: 21.11vw;
    top: ${isAndroid ? '45vh' : '43.8vh'};
  }

  .progress {
    transition: .3s;
    opacity: ${props => props.status ? 1 : 0};
      path {
        transition: fill .5s ease-in;
      }
  }
`

export const LoadingContainerWrapText = styled.span`
  position: absolute;
  right: 0;
  width: 50vw;
  margin-right: -50.7vw;
  text-align: left;
  opacity: 1;
  overflow-x: hidden;
`
export const LoadingContainerWrapTextSlide = styled.span`
  transition: .3s;
  display: block;

  span {
    position: relative;
    display: inline-block;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

export const LoadingContainerWrapSVG = styled.div`
  position: absolute;
  transition: .3s;
  opacity: ${props => props.status ? 1 : 0};

  svg {
    width: 3.958vw;
    height: 5.15625vw;

    @media (max-width: 1023px) {
      width: 21.11vw;
      height: 27.77vw;
      transform: translate(0%,calc(-6.4vw));
    }
  }
`
export const LoadingContainerText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: ${ osName === 'Mac OS' ? 'translate(-50%, -65%)' : 'translate(-50%, -25%)'};
  color: #fff;
  font-size: 0.965vw;
  font-family: europa, sans-serif;
  transition: .3s;
  opacity: ${props => props.status ? 1 : 0};
  @media (max-width: 1023px) {
    top: ${isAndroid ? '45vh' : '43.8vh'};
    font-size: 3vw;
    transform: translate(-50%, -75%);
  }
`