import styled from 'styled-components';

export const MapBox = styled.div`
  font-size: 1.09375vw;
  line-height: 1.5625vw;
  letter-spacing: 1.75px;
  font-family: europa, sans-serif;
  font-weight: 300;
  color: #232328;
  z-index: 2;
  padding: 25px 0;
  position: fixed;
  top: 55vh;
  left: 50%;

  @media (min-width: 1800px) {
    font-size: 0.82vw;
    line-height: 1.25vw;
  }

  strong {
    font-weight: 700;
  }
`;