import { createGlobalStyle } from 'styled-components';


export const NutriciaGlobal = createGlobalStyle`

  .synoptis {

    &__intro {
      padding-bottom: 2vw;
    }

    &__donuts {
      padding-top: calc(70vh - 3.3vw);
      @media (max-width: 1023px){
        padding-top: 40px;
      }
    }

    &__section1_image_1 {
      margin-top: 5.2vw;
      margin-bottom: 5.2vw;
      padding-left: 6%;
      padding-right: 2%;
    }

    &__section1_image_2 {
      padding-right: 2%;
      margin-bottom: 5.2vw;
    }

    &__section2_image_1 {
      margin-left: -5.2vw;
    }

    &__section2_image_2 {
      margin-top: 5.2vw;
      margin-bottom: 5.2vw;
      padding-left: 6%;
      padding-right: 2%;
    }

    &__section2_image_3 {
      margin-left: -5.2vw;
    }

    &__section3_image_1 {
      padding-left: 6%;
      padding-right: 6%;
      margin-bottom: 5.2vw;
    }

    &__section3_image_2 {
      margin-top: 5.2vw;
      padding-left: 6%;
      padding-right: 6%;
    }

    &__section3_image_3 {
      padding-left: 8%;
      padding-right: 8%;
    }


    &__section3 {
      margin-bottom: 4.8125vw;
    }

    &__section4_image_1 {
      margin-top: 2.2vw;
      margin-bottom: 2.2vw;
      padding-left: 8%;
      padding-right: 8%;
    }

    &__section4_image_2 {
      margin-left: -5.2vw;
      padding-right: 6%;
    }
  }

  .text-right {
    text-align: right;
  }

  .nutricia-section {
    &__kampania {
      margin-bottom: 6.5vw;
    }

    &__kampania-text {
      margin-bottom: 1.5vw;
    }

    &__kampania-img1 {
      margin-top: 5.2vw;
      margin-bottom: 5.2vw;
      padding-left: 6%;
      padding-right: 2%;
    }

    &__kampania-img2 {
      margin-top: 5.2vw;
      margin-bottom: 5.2vw;
      padding-left: 7%;
      padding-right: 20%;

      @media (max-width: 1023px){
        padding-right: 7%;
      }
    }

    &__landing-page {
      margin-bottom: 6.5vw;
    }

    &__landing-page-text1 {
      text-align: right;
    }

    &__landing-page-img1 {
      margin-bottom: 5.2vw;
      padding-left: 4%;
      padding-right: 10%;

      @media (max-width: 1023px){
        padding-right: 4%;
      }
    }

    &__social-media {
      margin-bottom: 7.8125vw;
    }

    &__social-media-text1 {
      margin-bottom: 1.5vw;
    }

    &__social-media-img1 {
      margin-top: 4.5vw;
      margin-bottom: 2vw;
      padding-right: 8%;

      @media (max-width: 1023px){
        padding-right: 0%;
      }
    }

    &__social-media-text2 {
      text-align: right;
    }

    &__social-media-img2 {
      margin-top: 2vw;
      padding-right: 6%;

      @media (max-width: 1023px){
        padding-right: 0%;
      }
    }

    &__social-media-img3 {
      margin-top: 4vw;
      margin-bottom: 8vw;
    }

    &__print{
      margin-bottom: 4.5vw;
    }

    &__print-text1{
      text-align: right;
    }

    &__print-img1 {
      margin-top: 3.125vw;
      padding-left: 15%;
      padding-right: 15%;
    }

    &__pos {
      margin-bottom: 6.5vw;
    }

    &__pos-img1 {
      margin-top: 3.125vw;
      margin-bottom: 5vw;
      padding-left: 2%;
      padding-right: 10%;

      @media (max-width: 1023px){
        padding-left: 5%;
        padding-right: 5%;
      }
    }

    &__pos-text1 {
      text-align: right;
    }

    &__pos-img2 {
      margin-top: 3.125vw;
      margin-bottom: 5vw;
      padding-left: 2%;
      padding-right: 10%;

      @media (max-width: 1023px){
        padding-left: 5%;
        padding-right: 5%;
      }
    }

    &__pos-img3 {
      padding-left: 20%;
      padding-right: 30%;

      @media (max-width: 1023px){
        padding-right: 20%;
      }
    }

    &__kampania-2-text1 {
      padding-top: 1.5vw;
      padding-right: 32% !important;

      @media (max-width: 1023px){
        padding-right: 0 !important;
      }
    }

    &__kampania-2-img1 {
      padding-top: 1.5vw;
      margin-bottom: 5.2vw;
      padding-left: 6%;
      padding-right: 5%;

      @media (max-width: 1023px){
        padding-right: 0%;
      }
    }

    &__kampania-2-text2 {
      padding-right: 20% !important;

      @media (max-width: 1023px){
        padding-right: 0 !important;
      }
    }

    &__kampania-2-img2 {
      padding-top: 2.5vw;
      margin-bottom: 3vw;
      padding-left: 4%;
      padding-right: 10%;

      @media (max-width: 1023px){
        padding-right: 4%;
      }
    }

    &__kampania-2-img3 {
      padding-top: 2.5vw;
      margin-bottom: 3vw;
      padding-left: 4%;
      padding-right: 4%;

      @media (max-width: 1023px){
        padding-right: 0%;
      }
    }
  }
`