import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from "react-redux";

import { FormContainer, FormContainerForm, FormContainerFormLabel, FormContainerFormLabelText, FormContainerFormLabelInput, FormContainerInfo, FormContainerSubmit, FormRodo, FormRodoContainer, FormRodoButtons, FormRodoBack, FormRodoSubmit, FormWrapButtons, FormBug, FormWrap } from './form.styled';
import { ShowDesktop, ShowMobile } from '../../grid/showHide/showHide.style';
import { isMobileOnly } from 'react-device-detect';
import { Link } from 'react-router-dom';


const mapStateToProps = state => {
  return {careersState: state.careers};
};

const Form = ({showMobile, color, careersState}) => {
  const [showForm, updateShowForm] = useState(0);

  const [name, updateName] = useState(null);
  const [position, updatePosition] = useState(null);
  const [file, updateFile] = useState(null);
  const [filePortfolio, updateFilePortfolio] = useState(null);
  const [email, updateEmail] = useState(null);
  const [clicedSend, updateClicedSend] = useState(0);

  let refName = React.createRef();
  let refPosition = React.createRef();
  let refFile = React.createRef();
  let refEmail = React.createRef();

  let refNameField = React.createRef();
  let refPositionField = React.createRef();
  let refEmailField = React.createRef();

  function isNotEmpty (val, type) {
    const re = {
      // eslint-disable-next-line no-useless-escape
      email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      text: /^(?!\s*$).+/
    }
    return re[type].test(String(val).toLowerCase())
  }

  const submit = () => {

    const data = new FormData();  

    data.append('name', name);
    data.append('position', position);
    data.append('email', email);
    data.append('file', file[0])
    data.append('portfolio', filePortfolio ? filePortfolio[0] : null)
    data.append('reponse', document.getElementById('recaptchaResponse').value)

    // console.log('FIle', document.getElementById('recaptchaResponse').value)

    // let xhr = new XMLHttpRequest();
    // xhr.open("POST", 'http://test.pomarancza.pl/test.php', true);
    // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
    // xhr.send(data);
    axios({
      method: 'post',
      url: 'https://pomarancza.pl/test.php',
      data: data,
      responseType: false,
    }).then(function (response) {
      // console.log('Response from mail', response)
    });
    updateShowForm(2)
  }

  const send = () => {
    updateClicedSend(1)
    const isOkay = validate();
    if(isOkay) {
      updateShowForm(1)
    }
  } 

  const updateWidth = (element , notClick) => {
    var cs = getComputedStyle(element);
		var offset = 0;
    var empty;
    if (!element.value && element.placeholder && notClick) {
			empty = true;
			element.value = element.placeholder;
		}
    
    element.style.width = "1000px";

			if (element.offsetWidth) {
        
				element.style.width = "0";

				if (cs.boxSizing === "border-box") {
					offset = element.offsetWidth;
				}
				else if (cs.boxSizing === "padding-box") {
					offset = element.clientWidth;
				}
				else if (cs.boxSizing === "content-box") {
					offset = parseFloat(cs.minWidth);
				}

				var width = Math.max(offset, element.scrollWidth - element.clientWidth);

				element.style.width = width + "px";

				for (var i=0; i<10; i++) {
					element.scrollLeft = 1e+10;

					if (element.scrollLeft === 0) {
						break;
					}

					width += element.scrollLeft;

					element.style.width = width + "px";
        }

        if ( empty ) {
          element.value = ''
        }
			}
			else {
				element.style.width =element.value + 1 + "ch";
			}
  }

  useEffect(() => {
    updateWidth(refNameField.current, true)
    updateWidth(refPositionField.current, true)
    updateWidth(refEmailField.current, true)
    
  })

  useEffect(() => {
    var script = document.createElement("script");  // create a script DOM node
    script.src = 'https://www.google.com/recaptcha/api.js?render=6LcmiJsUAAAAAPOG7are4RdlCGtQaiy5rJn5Cv-0';  // set its src to the provided URL

    document.head.appendChild(script);

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      window.grecaptcha.ready(function () {
        // eslint-disable-next-line no-undef
        window.grecaptcha.execute('6LcmiJsUAAAAAPOG7are4RdlCGtQaiy5rJn5Cv-0', { action: 'contact' }).then(function (token) {
            var recaptchaResponse = document.getElementById('recaptchaResponse');
            recaptchaResponse.value = token;
        });
      });
    }, 7000)
  }, [])

  const hideError = (ref) => {
    ref.current.style.display = 'none';
  }

  const isValid = (field, type, ref) => {
    if ( field === null || ! isNotEmpty( field, type ) ) {
      ref.current.style.display = 'block';
      return false;
    } else {
      ref.current.style.display = 'none';
      return true;
    }
  }

  const validate = () => {
    if (!isValid( name, 'text', refName )) {
      return false;
    } else if (!isValid( position, 'text', refPosition )) {
      return false;
    } else if (!isValid( file, 'text', refFile )) {
      return false;
    } else if (!isValid( email, 'email', refEmail )) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <FormWrap showMobile={showMobile}>
      <FormContainer
        color={color}
        style={{
          display: showForm === 0 ? 'block' : 'none'
        }}
      >
        <FormContainerForm
          className={`${isMobileOnly ? '' : 'animated fadeInUp faster'}`}
          style={{
            animationDelay: isMobileOnly ? '0s' : `2.9s`
          }}
        >
          <p>jestem <FormContainerFormLabel>
              <FormContainerFormLabelInput
                ref={ refNameField }
                placeholder="Sajmon"
                onClick={(e)=> {
                  e.target.placeholder= ''
                }}
                onBlur={(e) => {
                  e.target.placeholder= 'Sajmon'
                }}
                onInput={(el) => isValid( el.target.value, 'text', refName)}
               
                onKeyUp={ (el) => {
                  updateWidth(el.target)
                  updateName(el.target.value)
                }}
                onFocus={() => hideError(refName)}
              />
              <FormBug
                ref={ refName }
                color={color}
              >
                <svg width="19.293" height="25.006" viewBox="0 0 19.293 25.006">
                  <path d="M0,18.115,12.086,0,24.174,18.115" transform="translate(0.9 24.59) rotate(-90)" stroke="#232328" strokeWidth="1"/>
                </svg>
                Ups.. czegoś<br/>zapomniałeś!
              </FormBug>
            </FormContainerFormLabel> i jestem super.</p>
          <p> z pewnością przydam wam się na stanowisku <FormContainerFormLabel>
              <FormContainerFormLabelInput
                placeholder={careersState.position}
                onClick={(e)=> {
                  e.target.placeholder= ''
                }}
                onBlur={(e) => {
                  e.target.placeholder= `${careersState.position}`
                }}
                ref={ refPositionField }
                onInput={(el) => isValid( el.target.value, 'text', refPosition)}
                onKeyUp={ (el) => {
                  updateWidth(el.target)
                  updatePosition(el.target.value)
                }}
                onFocus={() => hideError(refPosition)}
              />
              <FormBug
                ref={ refPosition }
                color={color}
              >
                <svg width="19.293" height="25.006" viewBox="0 0 19.293 25.006">
                  <path d="M0,18.115,12.086,0,24.174,18.115" transform="translate(0.9 24.59) rotate(-90)" stroke="#232328" strokeWidth="1"/>
                </svg>
                Ups.. czegoś<br/>zapomniałeś!
              </FormBug>
              </FormContainerFormLabel>.
          </p>
          <p>
          ślę swoje <FormContainerFormLabel>
              <FormContainerFormLabelInput
                style={{
                  display: 'none'
                }}
                type="file"
                onInput={(el) => isValid( el.target.value, 'text', refFile)}
                onChange={ (el) => {
                  updateFile(el.target.files)
                }}
                onFocus={() => hideError(refFile)}
              />
                <FormBug
                  ref={refFile}
                  color={color}
                >
                  <svg width="19.293" height="25.006" viewBox="0 0 19.293 25.006">
                    <path d="M0,18.115,12.086,0,24.174,18.115" transform="translate(0.9 24.59) rotate(-90)" stroke="#232328" strokeWidth="1"/>
                  </svg>
                  Ups.. czegoś<br/>zapomniałeś!
                </FormBug>
              <FormContainerFormLabelText>CV</FormContainerFormLabelText>
            </FormContainerFormLabel> i <FormContainerFormLabel>
              <FormContainerFormLabelInput
                style={{
                  display: 'none'
                }}
                type="file"
                onChange={ (el) => {
                  updateFilePortfolio(el.target.files)
                }}
              />
              <FormContainerFormLabelText>portfolio<svg className="svg-file svg-file__file" style={{marginLeft: '5px'}}  width="15.562" height="31.123" viewBox="0 0 15.562 31.123">
              <path d="M13.44 7.071V23.34a5.659 5.659 0 1 1-11.318 0V5.659a3.537 3.537 0 0 1 7.074 0v14.854a1.415 1.415 0 0 1-2.83 0V7.071H4.244v13.44a3.537 3.537 0 0 0 7.074 0V5.659A5.659 5.659 0 0 0 0 5.659v17.683a7.781 7.781 0 0 0 15.562 0V7.071z" fill="#005FFF"/>
            </svg></FormContainerFormLabelText>
            </FormContainerFormLabel>
            .</p>
          <p>piszcie na <FormContainerFormLabel>
              <FormContainerFormLabelInput
                placeholder="sajmon@cytrynka.pl"
                onClick={(e)=> {
                  e.target.placeholder= ''
                }}
                onBlur={(e) => {
                  e.target.placeholder= 'sajmon@cytrynka.pl'
                }}
                ref={refEmailField}
                onInput={ (el) => clicedSend > 0 &&  isValid( el.target.value, 'email', refEmail)}
                onKeyUp={ (el) => {
                  updateWidth(el.target)
                  updateEmail(el.target.value)
                }}
                onFocus={() => hideError(refEmail)}
              />
                <FormBug
                  ref={refEmail}
                  color={color}
                >
                  <svg width="19.293" height="25.006" viewBox="0 0 19.293 25.006">
                    <path d="M0,18.115,12.086,0,24.174,18.115" transform="translate(0.9 24.59) rotate(-90)" stroke="#232328" strokeWidth="1"/>
                  </svg>
                  Ups.. czegoś<br/>zapomniałeś!
                </FormBug>
              </FormContainerFormLabel>.</p>
        </FormContainerForm>
        <FormWrapButtons
          className="animated fadeInUp faster" 
          style={{
            animationDelay: isMobileOnly ? '0s' : `3.05s`
          }}
        >
          <FormContainerSubmit
            onClick={send}
          >
            wyślij 
            <svg fill="#23282D" width="60.794" height="42.591" viewBox="0 0 60.794 42.591">
              <g>
                <path d="M60.794 42.591H0V0h60.794zM3.978 38.612h52.837V3.978H3.978z"/>
                <path d="M30.668 33.485L.578 3.395 3.391.583 30.668 27.86l27-27 2.813 2.813z"/>
              </g>
            </svg>
          </FormContainerSubmit>
        </FormWrapButtons>
        <FormContainerInfo
          className="animated fadeInUp faster" 
          style={{
            animationDelay: isMobileOnly ? '0s' : `3s`
          }}
        >
          <p>Wysyłając zgłoszenie rekrutacyjne do POMARAŃCZA Sp. z o.o., zgadzasz się na przetwarzanie przez Pracodawcę Twoich danych osobowych zawartych w zgłoszeniu rekrutacyjnym w celu prowadzenia rekrutacji na stanowisko wskazane w ogłoszeniu.</p>
          <p>Jeżeli chcesz, abyśmy zachowali Twoje CV w naszej bazie, umieść dodatkowo w CV następującą zgodę:</p>
          <p>„Zgadzam się na przetwarzanie przez POMARAŃCZA Sp. z o.o., danych osobowych zawartych w moim zgłoszeniu rekrutacyjnym dla celów przyszłych rekrutacji”.</p>
          <p>Jeżeli chcesz poznać pełną informację dotyczącą przetwarzania Twoich danych osobowych w procesie rekrutacji kliknij tutaj: <Link to="/klauzula-informacyjna/">klauzula informacyjna</Link></p> 
        </FormContainerInfo>
      </FormContainer>
      <FormRodo
        style={{
          display: showForm === 1 ? 'block' : 'none'
        }}
      >
        <FormContainer>
          <FormRodoContainer>
            <strong>RODO!</strong>
            <p>Wyrażam zgodę na przetwarzanie danych osobowych w zakresie podanym w formularzu kontaktowym w celu przekazania odpowiedzi na moją wiadomość/zapytanie. Zgoda może być w każdym czasie odwołana. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.</p>
          </FormRodoContainer>
          <FormRodoButtons>
            <FormRodoBack
              onClick={()=>{updateShowForm(0)}}
            >
            <ShowDesktop>
              <svg width="31.753" height="31.753" viewBox="0 0 31.753 31.753">
                <g fill="none" stroke="#232328" strokeWidth="4">
                  <path d="M5.496 15.851h25.792"/>
                  <path d="M17.29 30.339L2.82825211 15.87725211l14.462455-14.462455"/>
                </g>
              </svg>
            </ShowDesktop>
            <ShowMobile>
              <svg width="19.959" height="19.959" viewBox="0 0 19.959 19.959">
                <g fill="none" stroke="#232328" strokeWidth="4">
                  <path d="M4.408 9.966h15.276"/>
                  <path d="M11.393 18.546L2.82781556 9.98081556 11.3937071 1.414924"/>
                </g>
              </svg>
            </ShowMobile>
              powrót
            </FormRodoBack>
            <FormRodoSubmit
              onClick={ submit }
            >
              zgoda 
              <ShowDesktop>
                <svg width="39.571" height="32.54" viewBox="0 0 39.571 32.54">
                  <g>
                    <path d="M1.665 15.722l12.209 13.421S32.687 7.262 37.906 1.511" fill="none" stroke="#232328" strokeWidth="4.5"/>
                  </g>
                </svg>
              </ShowDesktop>
              <ShowMobile>
                <svg width="24.795" height="21.273" viewBox="0 0 24.795 21.273">
                  <g>
                    <path d="M1.664 9.929l7.231 7.949S20.038 4.918 23.128 1.512" fill="none" stroke="#232328" strokeWidth="4.5"/>
                  </g>
                </svg>
              </ShowMobile>
            </FormRodoSubmit>
          </FormRodoButtons>
        </FormContainer>
      </FormRodo>

      <FormRodo
        style={{
          display: showForm === 2 ? 'block' : 'none'
        }}
      >
        <FormContainer>
          <FormRodoContainer>
            <strong>pierwszy wspólny sukces!</strong>
            <p>Twój formularz poleciał już do nas.
              spodziewaj się odpowiedzi na adres {email}!</p>
          </FormRodoContainer>
        </FormContainer>
      </FormRodo>
    </FormWrap>
  )
}

export default connect(mapStateToProps, null)(Form);