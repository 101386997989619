import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Hrefs from '../../components/hrefs/hrefs';
import { HalfFull } from '../../components/grid/half/Half.styled';
import { Container } from '../../components/grid/container/Container.styled';
import MenuIcon from '../../components/menu-icon/menu-icon';
import Routes from '../../routes/routes';
import MainTitle from '../../components/ui/title/title';
import AboutUsCarousel from '../../components/about-us-carousel/about-us-carousel';
import AboutUsMobile from '../../components/about-us-mobile/about-us-mobile';
import { ContactText } from '../../components/ui/career-text/career-text.styled';
import { BG } from '../../components/grid/bg/bg.style';
import { MIN_ANIMATION } from '../../config/config';
import SEO from '../../components/seo/seo';
import Cookies from '../../components/cookies/Cookies';

const mapStateToProps = state => {
  return {
    colors: state.colors,
    activeAboutUs: state.activeAboutUs,
    aboutUsList : state.aboutUsList
  };
};
const AboutUs = ({colors, activeAboutUs, aboutUsList}) => {
  return (
    <BG 
      id="about-scroll"
      color={colors.onas ? colors.onas.color : ''}
      mobileMinHeight={true}
    >
      {
        Object.keys(aboutUsList).length > 0 && <SEO 
          title={aboutUsList.seo.title}
          description={aboutUsList.seo.description}
          keywords={aboutUsList.seo.keywords}
        />
      }
      <MenuIcon />
      <div
          className="animated fadeInLeft faster" 
          style={{
            animationDelay: `${MIN_ANIMATION + 0.5}s`,
            zIndex: 4,
            position: 'fixed',
          }}
      ><Link style={{animationDelay: `${MIN_ANIMATION + 0.7}s`}} className="contact-button--animation contact-button contact-button--dark" exact to={Routes.kontakt.slug}>kontakt</Link></div>
      <MainTitle 
        left='p'
        right='znajmy&nbsp;się!'
      />
      <Container forMobileAutoHeight={true}>
        <HalfFull hideMobile={true} fullHeight={true}>
          { 
            Object.keys(aboutUsList).length > 0 && 
              <AboutUsCarousel aboutUsList={aboutUsList} />
          }
          
        </HalfFull>
        <HalfFull mobileAuto={true}>
          { 
            Object.keys(aboutUsList).length > 0 && 
              <AboutUsMobile aboutUsList={aboutUsList} />
          }
          <div
            className={`animated fadeInUp faster`}
            style={{
              animationDelay: `${MIN_ANIMATION + 0.3}s`
            }}
          >
            {
              activeAboutUs !== null && <ContactText
              mobileHide={true}>
                <p><strong>Jestem <span>{Object.keys(aboutUsList).length > 0 && aboutUsList.persons[activeAboutUs].name}</span></strong></p>
                {Object.keys(aboutUsList).length > 0 && <Hrefs text={aboutUsList.persons[activeAboutUs].description} />}
              </ContactText>
            }
            {
              activeAboutUs === null && <ContactText mobileHide={true}>
                {Object.keys(aboutUsList).length > 0 && <Hrefs text={aboutUsList.description} />}
              </ContactText>
            }
          </div>
          
        </HalfFull>
      </Container>
      <Cookies
        color={'#232328'}
        background={colors.onas ? colors.onas.color : ''}
      />
    </BG>
  )
}

export default connect(mapStateToProps, null)(AboutUs);