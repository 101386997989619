import styled from 'styled-components';

export const CarouselCareersWrap = styled.div`
  width: 80%;
  margin: 0 auto;
  display: none;

  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 1023px){
    display: block;
  }

  .swiper-container {
    overflow: visible;
    height: auto;
  }
`

export const CarouselCareersItem = styled.div`
  display: block !important;

`

export const CarouselCareersItemText = styled.div`
  font-family: europa, sans-serif;
  font-weight: 700;
  color: #232328;
  text-align: center;
  margin-bottom: 11vw;

  p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.75px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  h1,h2,h3,h4,h5,h6,strong,b {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2px;
  }
`

export const CarouselCareersItemImg = styled.img`
  width: 100%;
  max-width: 100%;
  max-height: 45vh;
  height: auto;
`