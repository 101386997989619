import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { Link } from "react-router-dom";
import Player from '../../video/video';

import { TitleWrap, TitleCenter } from '../../../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, NiveaGlobal } from '../nivea/nivea.styled';
import { Title } from '../../title/Title.styled';
import { Text } from '../../text/text.styled';
import { SubTitle, SubTitleNumber } from '../../sub-title/sub-title.styled';
import BlockQuote from '../../block-quote/block-quote';
import ImgSection from '../../img-section/Img-section';

import DonutChart from '../../../ui/donat-chart/donat-chart';
import RightMenu from '../../right-menu/right-menu';
import Routes from '../../../../routes/routes';

import { Contact } from '../../button/button.styled';
import { PortfolioMobileLogo, PortfolioMobileText } from '../../portfolio-mobile/portfolio-mobile.styled';
import { BackMobile } from '../../../ui/back-mobile/back-mobile.styled';
import { Next } from '../../../ui/next/next.styled';

import Nutricia_01_A1 from '../../../../assets/case-study/nutricia/01/01A.png';
import Nutricia_01_B1 from '../../../../assets/case-study/nutricia/01/01B.png';
import Nutricia_01_C1 from '../../../../assets/case-study/nutricia/01/01C.png';

import Nutricia_02_A1 from '../../../../assets/case-study/nutricia/02/02A.png';
import Nutricia_02_B1 from '../../../../assets/case-study/nutricia/02/02B.png';
import Nutricia_02_C1 from '../../../../assets/case-study/nutricia/02/02C.png';

import Nutricia_03_A1 from '../../../../assets/case-study/nutricia/03/03A.png';
import Nutricia_03_B1 from '../../../../assets/case-study/nutricia/03/03B.png';
import Nutricia_03_C1 from '../../../../assets/case-study/nutricia/03/03C.png';

import Nutricia_04_A1 from '../../../../assets/case-study/nutricia/04/04A.png';
import Nutricia_04_B1 from '../../../../assets/case-study/nutricia/04/04B.png';
import Nutricia_04_C1 from '../../../../assets/case-study/nutricia/04/04C.png';

import Nutricia_05_A1 from '../../../../assets/case-study/nutricia/05/05A.png';
import Nutricia_05_B1 from '../../../../assets/case-study/nutricia/05/05B.png';
import Nutricia_05_C1 from '../../../../assets/case-study/nutricia/05/05C.png';

import Nutricia_06_A1 from '../../../../assets/case-study/nutricia/06/06A.png';
import Nutricia_06_B1 from '../../../../assets/case-study/nutricia/06/06B.png';
import Nutricia_06_C1 from '../../../../assets/case-study/nutricia/06/06C.png';

import Nutricia_07_A1 from '../../../../assets/case-study/nutricia/07/07A.png';
import Nutricia_07_B1 from '../../../../assets/case-study/nutricia/07/07B.png';

import Nutricia_08_A1 from '../../../../assets/case-study/nutricia/08/08A.png';
import Nutricia_08_B1 from '../../../../assets/case-study/nutricia/08/08B.png';
import Nutricia_08_C1 from '../../../../assets/case-study/nutricia/08/08C.png';

import Nutricia_09_A1 from '../../../../assets/case-study/nutricia/09/09.png';

import Nutricia_10_A1 from '../../../../assets/case-study/nutricia/10/10A.png';
import Nutricia_10_B1 from '../../../../assets/case-study/nutricia/10/10B.png';

import Nutricia_11_A1 from '../../../../assets/case-study/nutricia/11/11A.png';
import Nutricia_11_B1 from '../../../../assets/case-study/nutricia/11/11B.png';
import Nutricia_11_C1 from '../../../../assets/case-study/nutricia/11/11C.png';

import Nutricia_12_A1 from '../../../../assets/case-study/nutricia/12/12A.png';
import Nutricia_12_B1 from '../../../../assets/case-study/nutricia/12/12B.png';

import Nutricia_13_A1 from '../../../../assets/case-study/nutricia/13/13A.png';
import Nutricia_13_B1 from '../../../../assets/case-study/nutricia/13/13B.png';

import Nutricia_14_A1 from '../../../../assets/case-study/nutricia/14/14A.png';
import Nutricia_14_B1 from '../../../../assets/case-study/nutricia/14/14B.png';

import Nutricia_15_A1 from '../../../../assets/case-study/nutricia/15/15A.png';
import Nutricia_15_B1 from '../../../../assets/case-study/nutricia/15/15B.png';
import Nutricia_15_C1 from '../../../../assets/case-study/nutricia/15/15C.png';


import { NutriciaGlobal } from './nutricia.styled';
import Nutricia from '../../carousel/logo/nutricia';
import SEO from '../../../seo/seo';
import Cookies from '../../../cookies/Cookies';

function ProjectWrapper () {
  const [visibility, setVisibility] = useState(false);
  const color = Routes.portfolio.childs.nutricia.textColor;
  const colorBg = Routes.portfolio.childs.nutricia.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  useEffect(() => {
    document.documentElement.style.setProperty('--portfolio-color-active', '#ffffff' );
    document.documentElement.style.setProperty('--portfolio-color', color );
    document.body.style.background = colorBg
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
      // window.location.hash = event.target.dataset.slug;
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round((new Date(date)).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 5) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      <SEO
        title={Routes.portfolio.childs.nutricia.name}
        description={Routes.portfolio.childs.nutricia.description}
        keywords={Routes.portfolio.childs.nutricia.keywords}
      />
      <BackMobile>
        <Link to={Routes.portfolio.childs.home.slug}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
            <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke="#FFFFFF" strokeWidth="2.3"/>
          </svg>
        </Link>
      </BackMobile>
      <TitleWrap hideMobile={true} color="#F9F8F6">
        <TitleCenter color="#F9F8F6">
          <span style={{
              color: "#F9F8F6"
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <NiveaGlobal />
      <NutriciaGlobal />
      <RightMenu 
        color={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'intro',
          },
          {
            slug: 'kampania',
            name: 'kampania',
          },
          {
            slug: 'landing-page',
            name: 'landing page',
          },
          {
            slug: 'social-media',
            name: 'social media',
          },
          {
            slug: 'print',
            name: 'print',
          },
          {
            slug: 'pos',
            name: 'pos',
          },
          {
            slug: 'kampania-2',
            name: 'kampania',
          },
          {
            slug: 'video',
            name: 'video',
          },
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
          className="nivea-section__knowledge"
        >
          <PortfolioMobileLogo
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <Nutricia />
          </PortfolioMobileLogo>
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            zdrowe relacje, budujemy je z naszymi partnerami od lat. jednym z nich jest Nutricia - lider na europejskim rynku żywienia medycznego, dla którego zrealizowaliśmy dwie, docenione nagrodami na prestiżowych konkursach kampanie.
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title>
                  zdrowe relacje
                </Title>
                <Text>
                  budujemy je z naszymi partnerami od lat. jednym z nich jest Nutricia - lider na europejskim rynku żywienia medycznego, dla którego zrealizowaliśmy dwie, docenione nagrodami na prestiżowych konkursach kampanie. 
                </Text>
              </div>
              
              <div 
                className="nivea-intro__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2017}
                  max={new Date().getFullYear() - 2000}
                  undertext="lata"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2021-03-15', 107, 0.5)}
                  max={getProjects('2021-03-15', 107, 0.5)}
                  undertext="projektów"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={320}
                  max={420}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
              <div
                className="animated fadeInUp faster"
                style={{
                  animationDelay: `1.8s`
                }}
              >
                <BlockQuote 
                  
                  color={color}
                  content="Agencja Pomarańcza cechuje się wyjątkową dbałością o szczegóły, pomysłowością oraz znajomością trendów w zakresie realizowanych projektów, co znacząco przekłada się na pozytywny odbiór działań markowanych przez Nutricię Metabolics oraz w sposób istotny wpływa na kreowany wizerunek i atrakcyjność oferowanego serwisu. […] Na podstawie dotychczasowych doświadczeń mogę jednoznacznie stwierdzić, że kooperacja z agencją Pomarańcza to każdorazowa pewność, że droga od pomysłu do jakościowej realizacji nie jest długa, a jej przemierzanie to duża przyjemność."
                  author="Aleksandra Jeremicz"
                  company="Marketing Project Specialist"
                />
              </div>
              
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania"
          id="kampania"
          className="animated fadeInUp faster nutricia-section__kampania"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>1.</SubTitleNumber>
              fenymenalna kampania
            </SubTitle>
            <Text className="nutricia-section__kampania-text">
              poznajcie kampanię nagrodzoną złotą statuetką Golden Arrow w kategorii digital marketing – kampania internetowa. 
            </Text>
            <Text>
              fenymenalni to bohaterowie kampanii dla osób chorujących na fenyloketonurię (PKU), którzy mimo trudności osiągają małe i wielkie sukcesy. choć nie mogą jeść wszystkiego, to mogą… wszystko!
            </Text>
          </TextWrap>
          <div className="nutricia-section__kampania-img1">
            <ImgSection
              baseImg={Nutricia_01_A1}
              secondImg={Nutricia_01_B1}
              thirdImg={Nutricia_01_C1}
            />
          </div>
          <div className="nutricia-section__kampania-img2">
            <ImgSection
              baseImg={Nutricia_02_A1}
              secondImg={Nutricia_02_B1}
              thirdImg={Nutricia_02_C1}
            />
          </div>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="landing-page"
          id="landing-page"
          className="nutricia-section__pr"
        >
          <TextWrap>
            <SubTitle className="nutricia-section__landing-page-text1">
              <SubTitleNumber color={color}>2.</SubTitleNumber>
              inspiracją stały się prawdziwe historie
            </SubTitle>
            <Text className="nutricia-section__landing-page-text1">
              osób cierpiących na PKU, którzy mimo przeciwności losu<br/> realizują swoje pasje i marzenia.
            </Text>
          </TextWrap>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/432881002',
            }}
          />
          <div className="nutricia-section__landing-page-img1">
            <ImgSection
              baseImg={Nutricia_03_A1}
              secondImg={Nutricia_03_B1}
              thirdImg={Nutricia_03_C1}
            />
          </div>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="social-media"
          id="social-media"
          className="nutricia-section__social-media"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>3.</SubTitleNumber>
                wspieranie i motywowanie 
            </SubTitle>
            <Text className="nutricia-section__social-media-text1">
            chorych na PKU do pokonywania codziennych wyzwań i czerpania z życia to główne cele naszej kampanii.
            </Text>
            <Text>
            opracowaliśmy strategię komunikacji, ideę kreatywną, koncepcję graficzną, a następnie ich adaptacje.  odpowiadaliśmy również za koordynację kampanii, realizację sesji zdjęciowych oraz produkcję spotów wideo.
            </Text>
          </TextWrap>
          <div className="nutricia-section__social-media-img1">
            <ImgSection
              baseImg={Nutricia_05_C1}
              secondImg={Nutricia_05_B1}
              thirdImg={Nutricia_05_A1}
              layerUp={true}
            />
          </div>
          <TextWrap>
            <Text className="nutricia-section__social-media-text2">
              dodatkowo realizujemy i nadzorujemy kampanię oraz bieżącą komunikację w mediach społecznościowych, wspieramy także spółkę Nutricia w realizacji materiałów offline.
            </Text>
          </TextWrap>
          <div className="nutricia-section__social-media-img2">
            <ImgSection
              baseImg={Nutricia_04_A1}
              secondImg={Nutricia_04_B1}
              thirdImg={Nutricia_04_C1}
            />
          </div>
          </SectionProject>
      </Observer>
          <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="print"
          id="print"
        >
          <div className="nutricia-section__social-media-img3">
            <ImgSection
              baseImg={Nutricia_06_A1}
              secondImg={Nutricia_06_B1}
              thirdImg={Nutricia_06_C1}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="pos"
          id="pos"
          className="nutricia-section__pos"
        >
        <div
          className="nutricia-section__print"
        >

        
          <TextWrap>
            <SubTitle className="nutricia-section__print-text1">
              <SubTitleNumber color={color}>4.</SubTitleNumber>
              ekspozycja i edukacja
            </SubTitle>
            <Text className="nutricia-section__print-text1">
            wspólnie z Nutricią zrealizowaliśmy też projekt<br/> z zupełnie innej kategorii.
            </Text>
          </TextWrap>
          <div className="nutricia-section__print-img1">
            <ImgSection
              baseImg={Nutricia_07_A1}
              secondImg={Nutricia_07_B1}
            />
          </div>
        </div>
        

      
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>5.</SubTitleNumber>
              3 w 1
            </SubTitle>
            <Text>
            nasze zadanie polegało na zaprojektowaniu oraz wykonaniu wyjątkowego systemu dedykowanych nośników POS dla kategorii żywienia medycznego.             </Text>
          </TextWrap>
          <div className="nutricia-section__pos-img1">
            <ImgSection
              baseImg={Nutricia_08_A1}
              secondImg={Nutricia_08_B1}
              thirdImg={Nutricia_08_C1}
            />
          </div>
          <TextWrap>
            <Text className="nutricia-section__pos-text1">
            wyjątkowość systemu polegała na tym, że stanowisko poza ekspozycją produktu oraz budową kategorii w punkcie sprzedaży miało również wspierać edukację farmaceutów. 
            </Text>
          </TextWrap>
          <div className="nutricia-section__pos-img2">
            <ImgSection
              baseImg={Nutricia_09_A1}
            />
          </div>
          <div className="nutricia-section__pos-img3">
            <ImgSection
              baseImg={Nutricia_10_A1}
              secondImg={Nutricia_10_B1}
            />
          </div>
          
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania-2"
          id="kampania-2"
          className="nutricia-section__pos"
        >  
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>6.</SubTitleNumber>
              mówienie o emocjach. bez obaw
            </SubTitle>
            <Text>
            kolejnym wyzwaniem, przed jakim stanęliśmy, była komunikacja diety ketogennej skierowana do rodziców małych pacjentów z padaczką.</Text>
          </TextWrap>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/819415263?h=f23e678b11&amp;badge=0&amp;autopause=0&amp',
            }}
          />
          <Player
            nonMarginable={true}
            video={{
              src: 'https://player.vimeo.com/video/920361016?h=38ce9a7beb&amp;badge=0&amp;autopause=0&amp',
            }}
          />
          <TextWrap>
            <Text className="nutricia-section__kampania-2-text1">
            corem kampanii „Mniej napadów. Więcej chwil bez obaw.” stały się dwa filmy, w których pokazaliśmy prawdziwe historie młodych pacjentów – Wojtusia i Oliwki.
            </Text>
          </TextWrap>
          <div className="nutricia-section__kampania-2-img1">
            <ImgSection
              baseImg={Nutricia_11_A1}
              secondImg={Nutricia_11_B1}
              thirdImg={Nutricia_11_C1}
            />
          </div>
          <TextWrap>
            <Text className="nutricia-section__kampania-2-text2">
            przygotowaliśmy także key visuale kampanii, mailingi, wyjątkowy KetoPlaner dla rodziców i inne materiały promocyjne.
            </Text>
          </TextWrap>
          <div className="nutricia-section__kampania-2-img2">
            <ImgSection
              baseImg={Nutricia_12_A1}
              secondImg={Nutricia_12_B1}
            />
          </div>
          <TextWrap>
            <Text className="nutricia-section__kampania-2-text2">
            kampania poprzez silny, emocjonalny przekaz wyjaśniała odbiorcom, czym jest padaczka lekooporna i dlaczego może skutkować problemami rozwojowymi.</Text>
          </TextWrap>
          <div className="nutricia-section__kampania-2-img3">
            <ImgSection
              baseImg={Nutricia_13_A1}
              secondImg={Nutricia_13_B1}
            />
          </div>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="video"
          id="video"
          className="nutricia-section__pos"
        >  
          <TextWrap>
            <SubTitle className="nutricia-section__video-text1">
              <SubTitleNumber color={color}>7.</SubTitleNumber>
              fenymenalne rozmowy
            </SubTitle>
            <Text className="nutricia-section__video-text1">
            o fenymenalnej codzienności pełnej szans i możliwości. o rodzicielskich obawach, nadziei, oswajaniu choroby, łączeniu obowiązków i postrzeganiu diety PKU - właśnie o tym jest zrealizowany przez nas emocjonujący i inspirujący cykl rozmów, który możecie zobaczyć na kanale YouTube <a target="_blank" href="https://www.youtube.com/channel/UCyvPGFHTzE3qFbxRzoXwkow">@nutriciametabolicspolska.</a></Text>
          </TextWrap>
          <div className="nutricia-section__video-img1">
            <ImgSection
              baseImg={Nutricia_14_A1}
              secondImg={Nutricia_14_B1}
            />
          </div>
          
          <TextWrap>
            <Text className="nutricia-section__video-text2">
            fenymenalne rozmowy to cykl wywiadów prowadzonych przez Natalię Klimas-Szaflik, dyplomowaną dietetyk kliniczną chorującą na PKU oraz Joannę Krzyśpiak - przedstawicielkę marki Nutricia. wraz z wyjątkowymi gośćmi przybliżają widzom temat codziennego życia z fenyloketonurią.
            </Text>
          </TextWrap>
          <div className="nutricia-section__video-img2">
            <ImgSection
              baseImg={Nutricia_15_B1}
              secondImg={Nutricia_15_A1}
              thirdImg={Nutricia_15_C1}
              layerUp={true}
            />
          </div>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/872905757?h=06e3a6d838&amp;badge=0&amp',
            }}
          />
        </SectionProject>
      </Observer>
      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path id="Path_94" data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
          <Next color="#fff">
            <Link to={ Routes.portfolio.childs.kadefarm.slug }>
              następny klient<br/>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill="#fff"/>
              </svg>
            </Link>
          </Next>
        </TextWrap>
      </SectionProject>
      <Cookies
        fixed={true}
        color={'#ffffff'}
        background={colorBg}
      />
    </ProjectWrapperContainer>
  )
}

export default ProjectWrapper;