import { createGlobalStyle } from 'styled-components';


export const CoccodrilloGlobal = createGlobalStyle`

  .altax-section {

    &__kampania {
      margin-bottom: 6.25vw;
    }

    &__kampania-img1 {
      margin-top: 4.16vw;
      margin-bottom: 6.77vw
    }


    &__katalog {
      margin-bottom: 5.98vw;
    }

    &__katalog-text1 {
      text-align: right;
    }

    &__katalog-img1 {
      margin-top: 5.2vw;
      margin-bottom: 4.16vw;
    }

    &__katalog-text2 {

    }

    &__katalog-img2 {
      margin-top: 7.8125vw;

      @media (max-width: 1023px){
        margin-top: 0;
      }
    }


    &__outdoor {
      margin-bottom: 4.16vw;
    }

    &__outdoor-img1 {

    }

    &__outdoor-img2 {

    }


    &__platforma {
      margin-bottom: 8.33vw;
    }

    &__platforma-img1 {
      margin-top: 6.25vw;
      margin-bottom: 5.2vw;
    }

    &__platforma-img2 {

    }


    &__gadzety {
      margin-bottom: 7.8125vw;
    }

    &__gadzety-text1 {
      text-align: right;
    }

    &__gadzety-img1 {
      margin-top: 3.38vw;
      margin-right: -50px;
    }

    &__gadzety-img2 {
      margin-top: 7.8125vw;
    }

    &__gadzety-img3 {
      margin-top: 8.85vw;
    }


    &__aranzacje {
      margin-bottom: 10.4vw;
    }

    &__aranzacje-img1 {
      margin-top: 5.2vw;
    }


    &__branding {
      margin-bottom: 5.2vw;
    }

    &__branding-text1 {
      text-align: right;
    }

    &__branding-img1 {
      margin-top: 5.2vw;
    }


    &__pr {
      margin-bottom: 5.8125vw;
    }

    &__pr-img1 {
      margin-top: 4.125vw;
    }

    &__kruk {
      margin-bottom: 5.2vw;
    }

    &__scena-text1 {
      margin-bottom: 2vw;
    }

    &__scena-img1 {
      margin-top: 4.125vw;
    }

    &__scena-video {
      margin-top: 4.125vw;
      margin-bottom: 5.2vw;
      margin-right: 5.2vw;
      margin-left: 3.64vw;
    }
  }
`