import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';

import { ImgSectionContainer, ImgSectionBase, ImgSectionOther } from './Img-section.styled';

const  ImgSection = (props) => {

  let scene = React.createRef();


  return (
    
      <ImgSectionContainer>
        <Controller>
          <Scene
            ref={scene}
            indicators={false}
            duration="200%"
            triggerHook="1"
          >
            {(progress, event) => (
              <div>
                
                <ImgSectionBase 
                  src={props.baseImg}
                  layerUp={props.layerUp}
                />
                {props.animate && <ImgSectionBase
                  opacityScroll
                  style={{
                    opacity: progress * 2
                  }}
                  src={props.animate}
                  layerUp={props.layerUp}
                />}
                {
                  props.secondImg && <ImgSectionOther 
                    style={{
                      top: ((progress * (props.secondSpeed ? props.secondSpeed : 8)) - 5 ) + 'vw'
                    }}
                    src={props.secondImg}
                  />
                }
                {
                  props.thirdImg && <ImgSectionOther
                    style={{
                      top: (((progress * (props.thirdSpeed ? props.thirdSpeed : 8)) - 5 ) * -1) + 'vw'
                    }}
                    src={props.thirdImg}
                  />
                }
              </div>
            )}
          </Scene>
        </Controller>
        
      </ImgSectionContainer>
  )
}

export default ImgSection;