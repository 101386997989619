import { createGlobalStyle } from 'styled-components';


export const ValsparGlobal = createGlobalStyle`

  .valspar {

    &__knowledge {
      padding-bottom: 4vw;
    }

    &__section {
      margin-bottom: 5.8vw;
    }
    &__social {
      &__img-1 {
        margin-top: 7vh;
        margin-left: 3%;
        margin-right: 7%;
      }
    }

    &__print {
      &__img-1 {
        margin-top: -2vh;
        margin-right: -5%;
        margin-left: 3%;
      }
    }

    &__right {
      text-align: right !important;
    }

    &__castorama {
      &__img-1 {
        margin-top: 6vw;
        margin-right: 10%;
        margin-left: 7%;
      }
      &__img-2 {
        margin-right: 10%;
        margin-left: 7%;
      }
    }
  }
`