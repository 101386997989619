import styled from 'styled-components';


export const Address = styled.div`
  font-family: europa, sans-serif;
  color: #232328;
  letter-spacing: 1.6px;
  font-size: 1.09375vw;
  line-height: 2vw;

  .extra {
    margin-bottom: 1.5vw;
    display: block;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  @media (min-width: 1800px) {
    font-size: 0.82vw;
    line-height: 1.4vw;
  }
  
  @media (max-width: 1023px) {
    font-size: 4.44vw;
    line-height: 8.05vw;
    text-align: center;
    padding-top: 7.1vw;
  }

  @media (min-height:767px) and (max-width: 1023px) and (min-width: 450px) {
    padding-bottom: 8.33vw;
    padding-top: 0;
  }

  strong {
    font-size: 1.875vw;
    font-weight: 700;

    @media (max-width: 1023px) {
      font-size: 4.44vw;
      line-height: 8.05vw;
    }
  }

  a{
    color: #005FFF;
    text-decoration: none;
    margin-left: 0.9375vw;
    transition: color .3s;

    @media (max-width: 1023px) {
      margin: 0 1.94vw
    }

    &:hover {
      color: #232328;
    }
  }

  svg {
    margin-bottom: -5px;

    @media (max-width: 1023px) {
      width: 3.3728vw;
      height: 4.8vw;
      margin-right: -8px;
      margin-bottom: -3px;
    }

    &:hover {
      fill: #232328;
    }
  }
`