import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import { AnchorStyle } from './hrefs.styled';

const Hrefs = ({text, history}) => {
  let anchors = React.createRef();

  useEffect(() => {
    const links = anchors.current.querySelectorAll('a')

    for(let i = 0; i < links.length; i++) {
      links[i].addEventListener('click', el => {
        if (links[i].hostname === window.location.hostname) {
          el.preventDefault()
          history.push(el.target.href.replace(/.*\/\/[^\/]*/, ''))
        }
      })
    }
  }, [anchors])

  return (
    <AnchorStyle
      ref={anchors}
    >
      {ReactHtmlParser(text.replace(new RegExp('😀', 'g'), ':D').replace(new RegExp('😉', 'g'), ';)').replace(new RegExp('🙂', 'g'), ':)'))}
    </AnchorStyle>
  )
}

export default withRouter(Hrefs)