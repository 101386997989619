import { createGlobalStyle }  from 'styled-components';

export const Overflow = createGlobalStyle`

  .grecaptcha-badge { visibility: hidden; }
  body, html {
    width: 100vw;
    overflow-x: hidden;
    font-variant-ligatures: no-common-ligatures;
    color: #232328;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
    
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
    &::-webkit-scrollbar { /* WebKit */
      width: 0;
      height: 0;
    }
  }

  @keyframes fadeInUpCustom {
    from {
      opacity: 0;
      transform: translate3d(0, 1vh, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInUp {
    animation-name: fadeInUpCustom !important;
  }
`