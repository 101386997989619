import styled from 'styled-components'
import { isMobileSafari } from 'react-device-detect';


export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(34,34,37);
  width:100vw;
  ${isMobileSafari && `
      height: -webkit-fill-available;
    `
  }
  ${!isMobileSafari && `
      height:100vh;
    `
  }
  
  display:none;
  overflow: hidden;
  transform: translate3d(0,0,0);
  text-align: center;
  opacity: 1;
  z-index: 9999;

  @media (orientation: landscape) and (max-width: 992px) {
    display: block;
  }
`
