import styled from 'styled-components';

export const HomeMain = styled.div`
  padding: 0px 30px 0;
  flex-wrap: wrap;

  a {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 10.55vw;
      width: auto;
      fill: #232328;
    }
  }
  display: none;

  @media (max-width: 1023px){
    display: flex;
    margin: 3vh;

    a {
      height: 25px;
      margin: 2.5vh 0;

      svg {
        height: 7.55vw;
      }

      &.kruszwica {
        height: 50px;

        svg {
          height: 15vw;
        }
      }
    }
  }
`

export const HomeMainAnothers = styled.div`
  padding: 13.88vw 8.33vw 16.66vw;
  flex-wrap: wrap;

  div {
    width: 33.3%;
    height: 13.88vw;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;

    svg {
      height: 3.88vw;
      width: auto;
    }
  }
  display: none;

  @media (max-width: 1023px) {
    display: flex;
  }
`

export const HomeLinkMobile = styled.div`
  @media (min-width: 1023px) {
    display: none;
  }
  text-align: center;
  margin-bottom: 5vw;
`