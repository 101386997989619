import React from 'react'
import { Helmet } from "react-helmet";
import { SITE_URL } from '../../config/config';

const SEO = ({title, description, type, keywords}) => {
  return (
    <Helmet>
      <title itemProp="name" lang="pl">{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={SITE_URL + window.location.pathname} />
      <meta property="og:locale" content="pl_PL" />
      <meta property="og:type" content={type ? type : 'website'} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={SITE_URL + window.location.pathname} />
      <meta property="og:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
    </Helmet>
  )
}

export default SEO