import styled from 'styled-components';
import {isMobileSafari, isFirefox, isChrome, isEdge, isSafari, isMobile, isOpera} from 'react-device-detect';



export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  min-height: ${ props => props.isMinHeight ? '100%' : 'auto' };
  
  ${(isFirefox || isChrome || isEdge || isSafari || isOpera) && `
    min-height: 100vh;
    min-height: ${ isMobile ? 'calc(var(--vh, 1vh) * 100)' : '100vh'};
  `}

  

  @media (max-width: 1023px) {
    flex-wrap: wrap;
    flex-direction: ${ props => props.dontReverse ? '' : 'column-reverse'};
    min-height: 100vh;
    min-height: ${ isMobile ? 'calc(var(--vh, 1vh) * 100)' : '100vh'};

    ${isMobileSafari && 
      `
        min-height: -webkit-fill-available;
      `
    }

    ${
      props => props.forMobileAutoHeight && `
      min-height: auto;`
    }
  }
`