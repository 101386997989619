import React, { useEffect, useState} from 'react'
import { Link } from "react-router-dom";

import { COOKIESBOX } from '../../constants/action-types';
import { CookieBox, CookieBoxClose, CookieBoxText } from './cookies.styled';
import Routes from '../../routes/routes';

const Cookies = (props) => {
  const [show, showUpdate] = useState(true)

  const checkCookie = () => {
    const isCookie = localStorage.getItem(COOKIESBOX)
    return isCookie;
  }
  
  const setCookie = () => {
    showUpdate(true)
    localStorage.setItem(COOKIESBOX, true)
  }
  
  useEffect(() =>{
    showUpdate(checkCookie())
  })
  
  return(
    <CookieBox
      className="animated fadeIn faster"
      fixed={props.fixed}
      isActive={show}
      color={props.color}
      background={props.background}
      style={{
        animationDelay: `0.7s`
      }}
    >

      <svg className="cookie-frame-mobile" width="301" height="177.485" viewBox="0 0 301 177.485">
        <path fill={props.background} stroke={props.color} d="M.5.5h300v163.63H56.357l-8.624 12.475-8.624-12.475H.5z"/>
      </svg>
      <svg className="cookie-frame" width="780.794" height="137.885" viewBox="0 0 780.794 137.885">
        <path fill={props.background} stroke={props.color} d="M.5.5h779.794v119.305H46.743l-11.678 17.19-11.68-17.19H.5z" />
      </svg>
      <CookieBoxClose
        onClick={() => setCookie()}
      >
        <svg width="19.666" height="19.712" viewBox="0 0 19.666 19.712">
          <g fill={props.color}>
            <path d="M11.614 9.43l-.067-.067-.065-.065-.067-.067-8.9-8.9-.066-.067-.066-.065-.066-.067a.374.374 0 00-.527.021L.154 1.79a.374.374 0 00-.022.527l.067.066.065.066.067.066 8.9 8.9.067.067.065.065.067.067a.372.372 0 00.526-.021l1.637-1.637a.372.372 0 00.021-.526z"/>
            <path d="M8.052 9.43l.067-.067.065-.065.067-.067 8.9-8.9.067-.067.065-.065.067-.067a.373.373 0 01.526.021l1.637 1.637a.375.375 0 01.022.527l-.067.066-.065.066-.067.066-8.9 8.9-.067.067-.065.065-.067.067a.372.372 0 01-.526-.021L8.074 9.956a.373.373 0 01-.022-.526z"/>
            <path d="M11.614 10.284l-.067.066-.065.066-.067.067-8.9 8.9-.066.067-.066.065-.066.067a.374.374 0 01-.527-.021L.154 17.924a.372.372 0 01-.022-.526l.067-.067.065-.065.067-.067 8.9-8.9.067-.067.065-.065.067-.067a.372.372 0 01.526.022l1.637 1.636a.374.374 0 01.021.526z"/>
            <path d="M8.052 10.284l.067.066.065.066.067.067 8.9 8.9.067.067.065.065.067.067a.372.372 0 00.526-.021l1.637-1.637a.373.373 0 00.022-.526l-.067-.067-.065-.065-.067-.067-8.9-8.9-.067-.067-.065-.065-.067-.067a.372.372 0 00-.526.022L8.074 9.758a.375.375 0 00-.022.526z"/>
          </g>
        </svg>
      </CookieBoxClose>
      <CookieBoxText color={props.color}>
        Pliki cookie pomagają nam świadczyć, chronić i doskonalić usługi. Kontynuując korzystanie z naszej witryny, wyrażasz zgodę na nasze zasady dotyczące <Link to={Routes.rodo.slug}>Polityka prywatności</Link>.
      </CookieBoxText>
    </CookieBox>
  )
}

export default Cookies