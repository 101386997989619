import styled from 'styled-components';


export const CareersHideMobile = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`

export const SAR = styled.div`
  left: 90px;
  bottom: 25px;
  position: fixed;

  svg {
    max-width: 30vw;
    height: auto;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`