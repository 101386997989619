import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { connect } from "react-redux";


import { TitleWrap, TitleCenter } from '../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, NiveaGlobal } from './../portfolio/clients/nivea/nivea.styled';
import { Title } from './../portfolio/title/Title.styled';
import { Text } from './../portfolio/text/text.styled';
import { SubTitle, SubTitleNumber } from './../portfolio/sub-title/sub-title.styled';
import BlockQuote from './../portfolio/block-quote/block-quote';
import ImgSection from './../portfolio/img-section/Img-section';

import DonutChart from '../ui/donat-chart/donat-chart';
import RightMenu from './../portfolio/right-menu/right-menu';
import Routes from '../../routes/routes';

import { Contact } from './../portfolio/button/button.styled';
import {PortfolioMobileText } from './../portfolio/portfolio-mobile/portfolio-mobile.styled';
import SEO from '../seo/seo';

import section_1_a1 from '../../assets/produkcja/1/A1.jpg';
import section_1_a2 from '../../assets/produkcja/1/A2.jpg';
import section_1_a3 from '../../assets/produkcja/1/A3.jpg';
import section_1_a4 from '../../assets/produkcja/1/A4.jpg';
import section_1_a5 from '../../assets/produkcja/1/A5.jpg';

import section_1_b1 from '../../assets/produkcja/1/B1.jpg'
import section_1_b2 from '../../assets/produkcja/1/B2.jpg'
import section_1_b3 from '../../assets/produkcja/1/B3.jpg'
import section_1_b4 from '../../assets/produkcja/1/B4.jpg'
import section_1_b5 from '../../assets/produkcja/1/B5.jpg'

import section_2_b1 from '../../assets/produkcja/2/B1.jpg'
import section_2_b2 from '../../assets/produkcja/2/B2.jpg'
import section_2_b3 from '../../assets/produkcja/2/B3.jpg'

import section_2_c1 from '../../assets/produkcja/2/C1.jpg'
import section_2_c2 from '../../assets/produkcja/2/C2.jpg'

import section_2_d1 from '../../assets/produkcja/2/D1.jpg'

import section_3_a1 from '../../assets/produkcja/3/A1.jpg'
import section_3_a2 from '../../assets/produkcja/3/A2.jpg'
import section_3_a3 from '../../assets/produkcja/3/A3.jpg'
import section_3_a4 from '../../assets/produkcja/3/A4.jpg'
import section_3_a5 from '../../assets/produkcja/3/A5.jpg'
import section_3_a6 from '../../assets/produkcja/3/A6.jpg'

import section_3_b1 from '../../assets/produkcja/3/B1.jpg'
import section_3_b2 from '../../assets/produkcja/3/B2.jpg'

import section_3_c1 from '../../assets/produkcja/3/C1.jpg'
import section_3_c2 from '../../assets/produkcja/3/C2.jpg'
import section_3_c3 from '../../assets/produkcja/3/C3.jpg'
import section_3_c4 from '../../assets/produkcja/3/C4.jpg'

import section_4_a1 from '../../assets/produkcja/4/A1.jpg'

import section_4_b1 from '../../assets/produkcja/4/B1.jpg'
import section_4_b2 from '../../assets/produkcja/4/B2.jpg'
import section_4_b3 from '../../assets/produkcja/4/B3.jpg'

import section_4_c1 from '../../assets/produkcja/4/C1.jpg'
import section_4_c2 from '../../assets/produkcja/4/C2.jpg'
import section_4_c3 from '../../assets/produkcja/4/C3.jpg'
import section_4_c4 from '../../assets/produkcja/4/C4.jpg'

import section_5_a1 from '../../assets/produkcja/5/A1.jpg'
import section_5_a2 from '../../assets/produkcja/5/A2.jpg'
import section_5_a3 from '../../assets/produkcja/5/A3.jpg'

import section_5_b1 from '../../assets/produkcja/5/B1.jpg'
import section_5_b2 from '../../assets/produkcja/5/B2.jpg'
import section_5_b3 from '../../assets/produkcja/5/B3.jpg'
import section_5_b4 from '../../assets/produkcja/5/B4.jpg'

import section_6_a1 from '../../assets/produkcja/6/A1.jpg'
import section_6_a2 from '../../assets/produkcja/6/A2.jpg'
import section_6_a3 from '../../assets/produkcja/6/A3.jpg'
import section_6_a4 from '../../assets/produkcja/6/A4.jpg'
import section_6_a5 from '../../assets/produkcja/6/A5.jpg'

import section_6_b1 from '../../assets/produkcja/6/B1.jpg'
import section_6_b2 from '../../assets/produkcja/6/B2.jpg'
import section_6_b3 from '../../assets/produkcja/6/B3.jpg'

import section_7_a1 from '../../assets/produkcja/7/A1.jpg'
import section_7_a2 from '../../assets/produkcja/7/A2.jpg'

import section_8_a1 from '../../assets/produkcja/8/A1.jpg'

import { ProdukcjaGlobal } from './produkcja.styled';
import ImgSlider from '../portfolio/img-slider/imgSlider';
import Player from '../portfolio/video/video';

const mapStateToProps = state => {
  return {colors: state.colors, production: state.production};
};

function ProductionSection ({colors, seo}) {
  const [visibility, setVisibility] = useState(false);
  const color = colors.produkcja ? colors.produkcja.color : '';
  const colorBg = Routes.portfolio.childs.nivea.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]


  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  useEffect(() => {
    document.documentElement.style.setProperty('--portfolio-color-active', '#ffffff' );
    document.documentElement.style.setProperty('--production-color', color );
    document.body.style.background = colorBg
  },[])

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round((new Date(date)).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 7) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      { seo && <SEO 
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      /> }
      <TitleWrap hideMobile={true} color="#F9F8F6"
      >
        <TitleCenter color={color}
          className="animated fadeInUp faster"
          style={{
            animationDelay: `0.8s`
          }}
        >
          <span style={{
              color: color
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <NiveaGlobal />
      <ProdukcjaGlobal />
      <RightMenu 
        color={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'intro',
          },
          {
            slug: 'drukowane',
            name: 'drukowane',
          },
          {
            slug: 'POS',
            name: 'POS',
          },
          {
            slug: 'non-standard',
            name: 'non-standard',
          },
          {
            slug: 'z-tworzyw',
            name: 'z tworzyw',
          },
          {
            slug: 'ekspozycyjne',
            name: 'ekspozycyjne',
          },
          {
            slug: 'wzorniki',
            name: 'wzorniki',
          },
          {
            slug: 'opakowania',
            name: 'opakowania',
          },
          {
            slug: 'expo',
            name: 'expo',
          },
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
          className="nivea-section__knowledge"
        >
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
             wszystko, co możliwe. to niemożliwe też. nie pokażemy Ci tysięcy realizacji, bez obaw. zobacz nasz przekrój ważniejszych technologii, rozwiązań, nośników i zastosowań.
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title>
                  wszystko, co możliwe.<br/> to niemożliwe też.
                </Title>
                <Text>
                nie pokażemy Ci tysięcy realizacji, bez obaw. zobacz nasz przekrój ważniejszych technologii, rozwiązań, nośników i zastosowań.
                </Text>
              </div>
              
              <div 
                className="nivea-intro__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2000}
                  max={new Date().getFullYear() - 2000}
                  undertext="lat"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2019-11-19', 4600, 2)}
                  max={getProjects('2019-11-19', 4600, 2)}
                  undertext="zleceń"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={311}
                  max={360}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
              <div
                className="animated fadeInUp faster"
                style={{
                  animationDelay: `1.8s`
                }}
              >
                <BlockQuote
                  isProduction={true}
                  color={color}
                  content="Agencja Pomarańcza gwarantuje stałą, wysoką jakość druku, świetny serwis obsługi klienta oraz rzetelność i terminowość 
                  w zakresie realizacji zleceń."
                  author="Marta Kulikowska"
                  company="dyrektor marketingu Brita Polska sp. z o.o."
                />
              </div>
              
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="drukowane"
          id="drukowane"
          className="animated fadeInUp faster produkcja-section__print"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle className="produkcja-section__print-text">
              <SubTitleNumber color={color}>1.</SubTitleNumber>
              ekskluzywne wydawnictwa drukowane
            </SubTitle>
            <Text className="produkcja-section__print-text">
              czyli gdy specjalna okazja czyni… wyzwanie!  
            </Text>
          </TextWrap>
          
          <TextWrap>
            <div className="produkcja-section__img">
              <ImgSlider 
                time={200}
                images={[
                  section_1_b1,
                  section_1_b2,
                  section_1_b3,
                  section_1_b4,
                  section_1_b5
                ]}
              />
            </div>
            <Text className="produkcja-section__text-right">
              pamiątkowy album fotografii wydany z okazji budowy fabryki Volkswagen we Wrześni. estetyczny minimal w wersji premium.
            </Text>
            <div className="produkcja-section__img">
              <ImgSlider 
                time={500}
                images={[
                  section_1_a1,
                  section_1_a2,
                  section_1_a3,
                  section_1_a4,
                  section_1_a5
                ]}
              />
            </div>
            <Text className="produkcja-section__text">
              czegóż tu nie było?! hotstamping w dwóch kolorach, sztywna oprawa, dedykowane etui, starannie dobrane surowce i 164 strony druku w dwóch wersjach językowych. wszystko, by uczcić jubileusz 35-lecia Hasco-Lek.
            </Text>
          </TextWrap>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="POS"
          id="POS"
          className="produkcja-section__section"
        >
          <TextWrap>
            <SubTitle className="produkcja-section__pos-text">
              <SubTitleNumber color={color}>2.</SubTitleNumber>
              kreatywne materiały POS 
            </SubTitle>
            <Text className="produkcja-section__pos-text">
            jak wyróżnić, zwrócić uwagę, by świat był piękniejszy<br/> 
            (czyt.: sprzedać więcej) :)
            </Text>
            <div className="produkcja-section__img">
              <Player
                production
                video={{
                  src: 'https://player.vimeo.com/video/396395261',
                }}
              />
            </div>
            <Text className="produkcja-section__text-right">
                plakaty i wobblery w druku soczewkowym, zmieniającym obraz zależnie od kąta widzenia. angażujące posy przypółkowe wykonane z tworzyw, zachęcające klientów do interakcji w punkcie sprzedaży. taki before&after w wersji offline, dla farb Viva Garden i Viva Home Altaxu.
            </Text>
            <div className="produkcja-section__img">
              <ImgSlider 
                time={200}
                images={[
                  section_2_b1,
                  section_2_b2,
                  section_2_b3,
                ]}
              />
            </div>
            <Text className="produkcja-section__text">
              napółkowe, dedykowane pos wykonane z tworzywa dla Nutricia nie tylko wyróżniały produkt na półce, separowały smaki i zwracały uwagę dzięki podświetleniom, ale także ułatwiały pracę farmaceutom dzięki zintegrowanej kieszeni na ulotki.
            </Text>
            <div className="produkcja-section__img">
              <ImgSlider 
                time={500}
                images={[
                  section_2_c1,
                  section_2_c2,
                ]}
              />
            </div>
            <Text className="produkcja-section__text-right">
              stojaki ekspozycyjne ze stali do salonów odzieżowych Solar. i tabliczki z plexi umożliwiające personalizację komunikacji bieżących promocji i wyprzedaży. pos w wersji minimal. funkcjonalność w wersji maximal.
            </Text>
            <div className="produkcja-section__img">
              <ImgSection
                baseImg={section_2_d1}
              />
            </div>
            <Text className="produkcja-section__text">
              małe tubki produktów Altax na wielkiej tubie. i jak na wielką tubę przystało: trąbiła w punktach sprzedaży konkretnie. 
            </Text>
          </TextWrap>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="non-standard"
          id="non-standard"
          className="produkcja-section__section"
        >
          <TextWrap>
            <SubTitle className="produkcja-section__section3-text">
              <SubTitleNumber color={color}>3.</SubTitleNumber>
              niestandardowe materiały drukowane
            </SubTitle>
            <Text className="produkcja-section__section3-text">
              gdy ulotka w formacie DL nie wystarczy (czyli zawsze) 
            </Text>
            <div className="produkcja-section__img">
              <ImgSlider 
                time={200}
                images={[
                  section_3_c1,
                  section_3_c2,
                  section_3_c3,
                  section_3_c4,
                ]}
              />
            </div>
            <Text className="produkcja-section__text-right">
              Sample Euralis dba o dobry plon na polach, a nam daje pole do popisu. dzięki otwartości wydawcy topowego tytułu branżowego produkujemy najbardziej niestandardowe rozwiązania: wielostronicowe, kreatywne formy reklam prasowych.
            </Text>
            <div className="produkcja-section__img">
              <ImgSlider 
                time={500}
                images={[
                  section_3_a1,
                  section_3_a2,
                  section_3_a3,
                  section_3_a4,
                  section_3_a5,
                  section_3_a6,
                ]}
              />
            </div>
            <Text className="produkcja-section__text">
              Euralis dba o dobry plon na polach, a nam daje pole do popisu. dzięki otwartości wydawcy topowego tytułu branżowego produkujemy najbardziej niestandardowe rozwiązania: wielostronicowe, kreatywne formy reklam prasowych.
            </Text>
            <div className="produkcja-section__img">
              <ImgSlider 
                time={200}
                images={[
                  section_3_b1,
                  section_3_b2,
                ]}
              />
            </div>
            <Text className="produkcja-section__text-right">
              zestaw materiałów event wewnętrzny pracowników Hochland: smycze, identyfikatory, dokumentacja i notesy w twardej oprawie. wykorzystaliśmy syntetyczny papier, znany jako tyvek i zrobiliśmy z niego kopertę. szytą grubą nicią :)
            </Text>
          </TextWrap>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="z-tworzyw"
          id="z-tworzyw"
          className="produkcja-section__section"
        >
          <TextWrap>
            <SubTitle className="produkcja-section__section4-text">
              <SubTitleNumber color={color}>4.</SubTitleNumber>
              cudeńka z tworzyw
            </SubTitle>
            <div className="produkcja-section__img">
              <ImgSection
                baseImg={section_4_a1}
              />
            </div>
            <Text className="produkcja-section__text-right">
              niestandardowe nagrody, statuetki i medale to doskonały przykład mariażu kreatywności z technologią druku i laserowej obróbki tworzyw, z którego skorzystali m.in. Bambino, Malta-Decor, Drukarnia i Księgarnia Św. Wojciecha oraz 11 punkt.
            </Text>
            <div className="produkcja-section__img">
              <ImgSlider 
                time={200}
                images={[
                  section_4_b1,
                  section_4_b2,
                  section_4_b3,
                ]}
              />
            </div>
            <Text className="produkcja-section__text">
              marka Brita jest nasączona wodą, więc nasze zegary z plexi też musiały być. kreatywny gadżet z plexi z piękną, ręcznie robioną kropelką skapującą ze wskazówki. kropla filtrowanej wody Brita w morzu czasu :)
            </Text>
            <div className="produkcja-section__img">
              <ImgSlider 
                time={500}
                images={[
                  section_4_c1,
                  section_4_c2,
                  section_4_c3,
                  section_4_c4
                ]}
              />
            </div>
            <Text className="produkcja-section__text-right">
              jeśli - tak jak w NIVEA - pracujesz w open space wiesz, jak trudno skupić się na jednej rzeczy. a najgorzej, jeśli jesteś miły i nie odmawiasz swojej uwagi. ale gdy poinformujesz o swoim aktualnym statusie, niczym w social mediach - problem się rozwiąże.
            </Text>
          </TextWrap>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="ekspozycyjne"
          id="ekspozycyjne"
          className="produkcja-section__section"
        >
          <TextWrap>
            <SubTitle className="produkcja-section__section5-text">
              <SubTitleNumber color={color}>5.</SubTitleNumber>
              zabudowy ekspozycyjne
            </SubTitle>
            <div className="produkcja-section__img">
              <ImgSlider 
                time={200}
                images={[
                  section_5_a1,
                  section_5_a2,
                  section_5_a3,
                ]}
              />
            </div>
            <Text className="produkcja-section__text-right">
              Amica, która na rynku gruzińskim występuje pod marką Hansa zamówiła u nas ekspozytory sprzętu agd do tamtejszych elektromarketów. zaprojektowaliśmy, wyprodukowaliśmy i dostarczyliśmy do Gruzji. veni, vidi, vici.
            </Text>
            <div className="produkcja-section__img">
              <ImgSlider 
                time={500}
                images={[
                  section_5_b1,
                  section_5_b2,
                  section_5_b3,
                  section_5_b4,
                ]}
              />
            </div>
            <Text className="produkcja-section__text">
              Amica ponownie. ekskluzywna prezentacja nowości produktowych w ambasadzie polskiej w Paryżu. produkcja, transport, montaż w niełatwych, bo zabytkowych wnętrzach pałacowych? I… voila!
            </Text>
          </TextWrap>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="wzorniki"
          id="wzorniki"
          className="produkcja-section__section"
        >
          <TextWrap>
            <SubTitle className="produkcja-section__section6-text">
              <SubTitleNumber color={color}>6.</SubTitleNumber>
              wzorniki materiałów
            </SubTitle>
            <div className="produkcja-section__img">
              <ImgSlider 
                time={200}
                images={[
                  section_6_a1,
                  section_6_a2,
                  section_6_a3,
                  section_6_a4,
                  section_6_a5
                ]}
              />
            </div>
            <Text className="produkcja-section__text-right">
              wzornik, który jednocześnie jest folderem? folder, który jednocześnie jest wzornikiem? wyzwanie introligatorskie zakończone pełnym sukcesem.
            </Text>
            <div className="produkcja-section__img">
              <ImgSlider 
                time={500}
                images={[
                  section_6_b1,
                  section_6_b2,
                  section_6_b3,
                ]}
              />
            </div>
            <Text className="produkcja-section__text">
              jak pokazać szkło wykorzystane w szybach piekarnika Amica? ano tak!
            </Text>
          </TextWrap>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="opakowania"
          id="opakowania"
          className="produkcja-section__section"
        >
          <TextWrap>
            <SubTitle className="produkcja-section__section7-text">
              <SubTitleNumber color={color}>7.</SubTitleNumber>
              opakowania, showpacki
            </SubTitle>
            <div className="produkcja-section__img">
              <ImgSlider 
                time={200}
                images={[
                  section_7_a1,
                  section_7_a2,
                ]}
              />
            </div>
            <Text className="produkcja-section__text-right">
              showpacki, opakowania demonstracyjne, podarunkowe i wysyłkowe, wykorzystywane jako gift, opakowanie wysyłki pr albo narzędzie sprzedaży m.in. przez NIVEA, tesa, COCCODRILLO, Beyond i wielu, wielu innych.
            </Text>
          </TextWrap>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="expo"
          id="expo"
          className="produkcja-section__section"
        >
          <TextWrap>
            <SubTitle className="produkcja-section__section8-text">
              <SubTitleNumber color={color}>8.</SubTitleNumber>
              ścianki i zabudowy ekspozycyjne i eventowe
            </SubTitle>
            <div className="produkcja-section__section8-img1">
              <ImgSection
                baseImg={section_8_a1}
              />
            </div>
            <Text className="produkcja-section__text-right">
              clever frame, czyli mądre ramy :) to łatwy i prosty w montażu system zabudowy wystawienniczej, w którym formę buduje się jak z klocków. i nie trzeba kupować, bo taniej wypożyczyć od nas. 
            </Text>
          </TextWrap>
        </SectionProject>
      </Observer>
      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
        </TextWrap>
      </SectionProject>
    </ProjectWrapperContainer>
  )
}

export default connect(mapStateToProps, null)(ProductionSection);