import React, { useState, useEffect} from 'react';
import MainTitle from '../../components/ui/title/title';

import { BG } from '../../components/grid/bg/bg.style';
import { Link } from "react-router-dom";
import { MIN_ANIMATION } from '../../config/config';
import MenuIcon from '../../components/menu-icon/menu-icon';
import Routes from '../../routes/routes';
import { Container } from '../../components/grid/container/Container.styled';
import { HalfFull, Half } from '../../components/grid/half/Half.styled';
import { NotFoundText, NotFoundTextMobile, NotFoundIco, NotFoundMobileback, NotFoundIcoWrap } from './not-found.styled';
import SEO from '../../components/seo/seo';
import { More } from '../../components/ui/more/more.styled';
import Cookies from '../../components/cookies/Cookies';

const NotFound = () => {
  const [show, updateShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      updateShow(true)
    }, 500)
  }, [])

  return (
    <div>
      { show && <BG
        color={ `#00EAEA` }
        forMobileMiddle={true}
        overflow="hidden"
      >
        <SEO
          title={Routes.notfound.name}
          description={Routes.notfound.description}
          keywords={Routes.notfound.keywords}
        />
        <MenuIcon notFound />
        <MainTitle
          delay={1}
          notFound={true}
          right='ooops!'
        />
        <div
          className="animated fadeIn faster" 
          style={{
            animationDelay: `${1.2}s`,
            zIndex: 4,
          }}
        >
          <NotFoundTextMobile>
            Taka strona nie istnieje :(
          </NotFoundTextMobile>
        </div>
        <NotFoundIcoWrap>
          <NotFoundIco width="109.637" height="175.521" viewBox="0 0 109.637 175.521">
            <path fill="#232328" d="M54.818 32.884a54.819 54.819 0 1054.819 54.813 54.877 54.877 0 00-54.819-54.813zm0 87.7a32.892 32.892 0 1132.9-32.891 32.919 32.919 0 01-32.9 32.895z"/>
            <path
            fill="#232328" d="M101.603 0H8.013a2.647 2.647 0 00-2.53 2.747v16.418a2.647 2.647 0 002.53 2.747h93.59a2.645 2.645 0 002.531-2.747V2.747A2.645 2.645 0 00101.603 0z"/>
          </NotFoundIco>
        </NotFoundIcoWrap>
        
        <div
          className="animated fadeIn faster" 
          style={{
            animationDelay: `${1.2}s`,
            zIndex: 4,
          }}
        >
          <NotFoundMobileback>
            <More absolute={true} color={'#005FFF'}>
              <Link to={ Routes.home.slug }>
                wróć na home <br/>
                <svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
                  <path d="M-117.576,178.894h-19.641l3.35-3.657-1.969-1.893-6.035,6.956,6.035,6.956,1.969-1.893-3.35-3.657h19.641Z" transform="translate(141.871 -173.345)" fill={'#005FFF'}/>
                </svg>
              </Link>
            </More>
          </NotFoundMobileback>
        </div>
        <Container forMobileAutoHeight={true}>
          <HalfFull fullHeight={true}>
            <NotFoundText
              className="animated fadeIn faster" 
              style={{
                animationDelay: `${1.6}s`,
                zIndex: 4,
              }}
            >
              Taka strona nie istnieje :(<br/>
              <Link to={Routes.home.slug} >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.8 31.8">
                  <path fill="#005FFF" d="M31.329 17.843h-23.7l11.1 11.1-2.8 2.8-15.9-15.9 15.9-15.8 2.8 2.8-11 11h23.6z"/>
                </svg> wróć na home
              </Link>
            </NotFoundText>
          </HalfFull>
          <Half></Half>
        </Container>
      </BG>}
      <Cookies
        fixed={true}
        color={'#232328'}
        background={`#00EAEA`}
      />
    </div>
  )
}

export default NotFound