import styled from 'styled-components';

export const ChartInside = styled.div`
  position: relative;
  width: ${props => props.size / 19.2}vw;

  @media (max-width: 1024px){
    width: 20vw;
  }
`

export const ChartText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: europa, sans-serif;
  font-weight: 700;
  font-size: 1.354vw;
  line-height: 2.6vw;
  color: ${ props => props.color ? props.color : '#F9F8F6'};
  transform: translate(-50%, -50%);
  margin-top: -3px;

  @media (max-width: 1024px){ 
    font-size: 3.88vw;
    line-height: 5vw;
  }
`;

export const ChartUnderText = styled.div`
  text-align: center;
  font-family: europa, sans-serif;
  font-weight: 400;
  font-size: 1.09375vw;
  line-height: 1.875vw;
  letter-spacing: 1.75px;
  color: ${ props => props.color ? props.color : '#F9F8F6'};
  margin-top: 0.26vw;

  @media (min-width: 1800px) {
    font-size: 0.82vw;
    line-height: 1.4vw;
  }

  @media (max-width: 1024px){
    font-size: 2.77vw;
    line-height: 3.33vw;
  }
`;
