import styled from 'styled-components';

export const AboutUsWrap = styled.div`
  @media (max-width: 1023px){
    display: none;
  }
`;

export const AboutUsWrapCarousel = styled.div`
  
`;

export const AboutUsSlide = styled.div`

`;

export const AboutUsImg = styled.img`
  width: 50%;
  transition: .3s;
  opacity: 0.9;
  margin-top: -10%;

  &.second-style {
    float: right;
    width: 50%;
    margin-right: 10%;

    &.bigger {
      margin-right: 4%;
    }
  }

  &.smaller {
    width: 50%;
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
  }

  &.bigger {
    width: 60%;
    margin-top: -15%;
    margin-left: -4%;
    &:hover {
      cursor: initial;
    }
  }
`;
