import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";


import MainTitle from '../../../ui/title/title';
import { TextText } from '../../../text-text/text-text.styled';
import Routes from '../../../../routes/routes';
import { HomeMain, HomeLinkMobile } from './home.styled';
import SEO from '../../../seo/seo';
import { More } from '../../../ui/more/more.styled';
import Cookies from '../../../cookies/Cookies';

const mapStateToProps = state => {
  return {colors: state.colors};
};

const Home = ( {colors} ) => {
  const childs = Routes.portfolio.childs
  const colorBg = colors.portfolio ? colors.portfolio.color : '';

  useEffect(() => {
    document.documentElement.style.setProperty('--portfolio-color-active', '#232328' );
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })
  return (
    <div>
      <SEO
        title={Routes.portfolio.childs.home.name}
        description={Routes.portfolio.childs.home.description}
        keywords={Routes.portfolio.childs.home.keywords}
      />
      <MainTitle
        absolute={true}
        left={'M'}
        right='C&nbsp;PROJEKTÓW'
      />
      <TextText mobileExtra={true} bold={true} 
        className="animated fadeInUp faster" 
        style={{
          animationDelay: `3s`
        }}
        left="0%">
         <p>…i przegląd współpracy z wybranymi klientami, czyli krótka relacja z długofalowych relacji.
    będą kolejne, wszak nie ma się co ograniczać!!</p>
      </TextText>

      <HomeMain>
          {
            Object.keys(childs).map((el, i) => {
              let l = 3.2 + (i /10)
              if ( i > 0 && i < Object.keys(childs).length - 1) {
                return (
                  <Link
                    to={childs[el].slug}
                    className={`carousel__item animated fadeInUp faster ${childs[el].flat}`}
                    style={{
                      animationDelay: `${l}s`
                    }}
                  >
                    { childs[el].logo }
                  </Link>
                )
              }

              return false
            })
          }
          
      </HomeMain>
      <HomeLinkMobile>
        <More 
          className="carousel__item animated fadeInUp faster"

          style={{
            animationDelay: `4s`
          }}
          dontHideBr={true}>
          <Link
            style={{
              color: '#005FFF',
            }}
            to={'/#klienci'}>zobacz pozostałych <br/>klientów <svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
            <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={'#005FFF'}/>
            </svg>
          </Link>
        </More>
      </HomeLinkMobile>
      <Cookies
        fixed={true}
        color={'#232328'}
        background={colorBg}
      />
    </div>
  )
}

export default connect(mapStateToProps, null)(Home)