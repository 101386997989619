import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import HomeIntro from '../../components/home-intro/home-intro';
import Contact from '../../components/contact/contact';

import { WrapAllHome } from './home.style';
import HomeMain from '../../components/home-main/home-main';
import { homePosition, homeScroll } from '../../actions';
import Clients from '../../components/clients/clients';
const mapStateToProps = state => {
  return { articles: state.articles, menuState: state.menu, homePosition: state.homePosition, intro: state.intro, homeScroll: state.homeScroll};
};

function mapDispatchToProps(dispatch) {
  return {
    homePositionUpdate: colors => dispatch(homePosition(colors)),
    homeScrollUpdate: colors => dispatch(homeScroll(colors)),
  };
}

const Home = ( { articles, menuState, homePosition, homePositionUpdate, intro, homeScrollUpdate, homeScroll } ) => {
  const [scroll, updateScroll] = useState(false);
  const [oldTempState, updateOldTempState] = useState(0);
  const [anchors, updateAnchors] = useState(['home', 'klienci', 'kontakt']);
  // const [first, updateFirst] = useEffect(0)


  useEffect(() => {
    let urls = anchors
    
    setTimeout(() => {
      const ur = []
      if( intro.articles_list ) {
        Object.keys(intro).length > 0 && intro.articles_list.map((e, j) => {
          articles.map((el, i) => {
            if( e === el.id) {
              ur.push(el.acf.link)
              // const found = urls.find( (element) =>  { 
              //   return element === el.acf.link; 
              // }); 
              // if(!found) {
              //   urls.splice(2, 0, el.acf.link)
              // }
            }
          })
        })
        ur.map((el, i) => {
          urls.splice(2 + i, 0, el)
        })
        
        // console.log('articles', intro.articles_list)
        // console.log('reverse()', intro.articles_list.reverse())
        // console.log('urls', urls)
        updateAnchors(urls)

        const hash = window.location.hash.replace('#','')
        if(hash) {
            urls.forEach((el, i) => {
              if(hash === el) {
                homePositionUpdate(i)
                // updateFirst(i)
              }
            })
        }
      }
    }, 0) // was 1500
    
  }, [intro, articles])

  useEffect(() => {
    let letScrollRoutes = true
    let touchStartPosition = {
      x: null,
      y: null
    }
    const hash = window.location.hash.replace('#','')
    let position = hash ? hash : 0
    // if(hash) {
    //   homePositionUpdate(hash)
    // }

    const changeRoute = (to) => {
      if (homeScroll) {
        updateScroll(true)
        homeScrollUpdate(false)
        let temp = parseInt(document.querySelector('[data-nr]').dataset.nr)
        if(to){
          homePositionUpdate(parseInt(document.querySelector('[data-nr]').dataset.nr) + 1)
          position = temp + 1

          window.location.hash = anchors[position]
        } else {
          homePositionUpdate(parseInt(document.querySelector('[data-nr]').dataset.nr) - 1)
          position = temp - 1

          window.location.hash = anchors[position]
        }

        window.setTimeout(() => {
          homeScrollUpdate(true)
        }, 1500)
      }
    }


    const touchStart = (event) => {
      touchStartPosition.x = event.changedTouches[0].clientX
      touchStartPosition.y = event.changedTouches[0].clientY
    }
    const touchEnd = (event) => {
      if(touchStartPosition.y !== event.changedTouches[0].clientY) {
        onScroll({
          deltaY: touchStartPosition.y - event.changedTouches[0].clientY
        })
      }
     
    }

    const scrollDown = () => {
      const len = parseInt(document.querySelector('[data-len]') && document.querySelector('[data-len]').dataset.len) + 2
      if (len > parseInt(document.querySelector('[data-nr]') && document.querySelector('[data-nr]').dataset.nr)) {
        changeRoute(true)
      }
    }
    const scrollUp = () => {
      if (parseInt(document.querySelector('[data-nr]') && document.querySelector('[data-nr]').dataset.nr) > 0) {
        changeRoute(false)
      }
    }


    const onScroll = (event) => {
      
      if (event.deltaY === undefined || event.deltaY === null) {
        if (event.detail > 0) {
          scrollDown()
        } else {
          scrollUp()
        }
      } else {
        if (event.deltaY > 0) {
          scrollDown()
        } else {
          scrollUp()
        }
      }

    }
      window.addEventListener('mousewheel', onScroll)
      window.addEventListener('DOMMouseScroll', onScroll)
      window.addEventListener('touchstart', touchStart)
      window.addEventListener('touchend', touchEnd)
    
      return function () {
        window.removeEventListener('mousewheel', onScroll)
        window.removeEventListener('DOMMouseScroll', onScroll)
        window.removeEventListener('touchstart', touchStart)
        window.removeEventListener('touchend', touchEnd)
      }
  }, [homeScroll, homeScrollUpdate, anchors])

  return (
    <WrapAllHome data-len={Object.keys(intro).length > 0 ? intro.articles_list.length : 6} data-nr={homePosition}>
      {
        
        articles.length > 0 && <div>
          <HomeMain
            className="home-side"
            showHide={homePosition === 0}
          />
          <Clients
            className="home-side"
            firstTime={scroll}
            showHide={homePosition === 1}
            // isFirst={first === 1}
          >

          </Clients>
          {
             
             Object.keys(intro).length > 0 && intro.articles_list.map((e, j) => {
                  return articles.map((el, i) => {
                    if( e === el.id) {
                      return(
                        <HomeIntro
                          className="home-side"
                          showHide={homePosition === j + 2}
                          animationOut={oldTempState === j + 2}
                          id={j + 1}
                          key={j}
                          dark={el.acf.dark}
                          color={el.acf.color}
                          img={el.acf.img}
                          description={el.acf.content}
                          link={el.acf.link}
                          title={el.acf.title}
                          teaser={el.acf.teaser}
                          imgFullMobile={el.acf.img_full_mobile}
                          imgMobile={el.acf.mobile_img}
                          mobileHeadlineVimeo={el.acf.mobile_headline_vimeo}
                          headlineVimeo={el.acf.headline_vimeo}
                        />
                      )
                    }
                    
                  })
                })
          }
          <Contact
            className="home-side"
            isHome={true}
            showHide={homePosition === (Object.keys(intro).length > 0 && intro.articles_list.length) + 2}
            animationOut={oldTempState === (Object.keys(intro).length > 0 && intro.articles_list.length) + 2}
          />
        </div>
      }
    </WrapAllHome>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);