import React, { useState } from 'react';
import Swiper from 'react-id-swiper';

import { colors } from '../../colors/colors';
import { AboutUsMobileWrap, AboutUsMobileCarousel, AboutUsMobileText, AboutUsMobileCarouselItem, AboutUsMobileCarouselItemImg, AboutUsMobileTextPerson } from './about-us-mobile.styled';
import { MIN_ANIMATION } from '../../config/config';
import Hrefs from '../hrefs/hrefs';

const AboutUsMobile = (props) => {
  const [index, updateIndex] = useState(null);
  const [start, updateStart] = useState('start');

  const Main = React.createRef()


  const params = {
    loop: true,
    centeredSlides: true,
    slidesPerView: 3,
    longSwipes: false,
    height: 400,
    on: {
      click: function () {
        updateStart(false)

        if ( this.clickedIndex  < this.activeIndex ) {
          this.slidePrev()
        } else if ( this.clickedIndex  > this.activeIndex ) {
          this.slideNext()
        }
      },
      init: function () {
        updateIndex(null)
      },
      touchStart: function () {
        const isNotStart = document.getElementById('aboutusmobile').classList.contains('start')
        if(isNotStart) {
          this.slideTo(5)
        }
        updateStart(false)
      },
      slideChange: function(){
          updateIndex(this.realIndex)
          const rand = Math.floor((Math.random() * 6))
          
          const isNotStart = document.getElementById('aboutusmobile').classList.contains('start')
          if (! isNotStart) {
            document.querySelector('#about-scroll').style.background = colors[rand].color
          }
          
          Object.keys(this.slides).forEach(item => {
            const element = this.slides[item].childNodes
            if ( element ) {
              const n = element[0].parentNode.dataset.swiperSlideIndex
              if ( parseInt(n) === parseInt(this.realIndex)) {
                element[0].classList.add('bigger')
                element[0].classList.remove('smaller')
                element[0].src = props.aboutUsList.persons[n].foto_3.url
              } else {
                element[0].classList.add('smaller')
                element[0].classList.remove('bigger')
                element[0].src = props.aboutUsList.persons[n].foto_1.url
              }
            }
          })
      
      }
    }
  }
  return (
    <AboutUsMobileWrap>
      <AboutUsMobileCarousel
        id="aboutusmobile"
        ref={Main}
        className={`${start} animated fadeInUp faster`}
        style={{
          animationDelay:`${MIN_ANIMATION + 0.3}s`
        }}
      >
        <Swiper {...params} className="carousel">
          {
            props.aboutUsList.persons.map((el, i) => {
              return (
                <AboutUsMobileCarouselItem
                  key={i}
                  data-id={i}
                >
                  <AboutUsMobileCarouselItemImg
                    src={el.foto_1.url}
                    alt={el.foto_3.alt}
                  />
                </AboutUsMobileCarouselItem>
              )
            })
          }
        </Swiper>
      </AboutUsMobileCarousel>
      <AboutUsMobileText
        className={`animated fadeInUp faster`}
        style={{
          animationDelay: `${MIN_ANIMATION + 0.6}s`
        }}
      >
          {
            index !== null && <AboutUsMobileTextPerson>
              <strong>Jestem <span>{props.aboutUsList.persons[index].name}</span></strong>
              <Hrefs text={props.aboutUsList.persons[index].description} />
            </AboutUsMobileTextPerson>
          }
          {
            index === null && <AboutUsMobileTextPerson>
              <Hrefs text={props.aboutUsList.description} />
            </AboutUsMobileTextPerson>
          }
          
      </AboutUsMobileText>
    </AboutUsMobileWrap>
  )
}

export default AboutUsMobile;