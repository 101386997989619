import React, { useEffect, useState } from 'react';
import { ChartText, ChartUnderText, ChartInside } from './dontat-chart.styled';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { TweenLite } from 'gsap';


const DonutChart = (props) => {
  let refObject = React.createRef();
  const [color, updateColor] = useState(0)
  const [status, updateStatus] = useState(0)
  const [percent, updatePercent] = useState(0)

  useEffect(()=> {
    const interval = setInterval(() => {
      if ( color > 6 - 1 ) {
        updateColor( 0 );
      } else {
        updateColor(color + 1 );
      }
    }, 2000)

    return () => clearInterval(interval);
  }, [color])


  useEffect(()=> {
    const up = () => {
      let text = props.text < 101 ? props.text * 10 : props.text
      let d1=[0],d2=[text]
      d2.roundProps="0,1,2,3";
      d2.onUpdate=update;
      setTimeout(()=> {
        TweenLite.to(d1,5,d2)
      }, 1000)
      function update(){
        updateStatus(props.text < 101 ? parseInt(d1[0] / 10) : d1[0])

        const p = d1[0] / (props.max < 101 ? props.max * 10 : props.max) * 100
        updatePercent(p)
      };
    }

    up()

    return () => up()
  }, [])
  

  return (
    <div>
      <ChartInside
        size={props.size}
      >
        <CircularProgressbar
          className="progress" 
          value={percent} 
          strokeWidth={10}
          styles={buildStyles({
            pathColor: props.stroke
          })}
        />
        <ChartText
          color={props.textColor}
          ref={refObject}
        >
          { status }{props.specialChar ? props.specialChar : '' }
        </ChartText>
      </ChartInside>
      
      <ChartUnderText
        color={props.textColor}
      >
        {props.undertext}
      </ChartUnderText>
      
    </div>
    
  )
}

export default DonutChart;