import { ADD_COLORS, TOGGLE_MENU, ARTICLES, POMARANCZA, PRODUCTION, RODO, ABOUTUSLIST, ACTIVEABOUTUS, CAREERS, HOMEANIMATION, LOADING, MENUICON, LANDINGS, INTRO, CONTACT, HOMEPOSITION, LOGOS, HOMESCROLL, ONSCROLLPRODUCTION, MENUCLOSE, CLIENTS } from "../constants/action-types";
const initialState = {
  careers: [],
  colors: [],
  articles: [],
  production: [],
  pomarancza: [],
  rodo: [],
  clients: [],
  landingsList: [],
  menu: false,
  menuIcon: false,
  activeAboutUs: null,
  aboutUsList: [],
  dontShowHomeAnimation: false,
  loading: true,
  contact: [],
  intro: [],
  homePosition: 0,
  logos: [],
  homeScroll: true,
  onScrollProduction: false,
  menuClose: false,
};
function rootReducer(state = initialState, action) {
  switch (action.type) {
    case MENUCLOSE:
      return Object.assign({}, state, {
        menuClose: action.payload
      });
    case ONSCROLLPRODUCTION:
      return Object.assign({}, state, {
        onScrollProduction: action.payload
      });
    case HOMESCROLL:
      return Object.assign({}, state, {
        homeScroll: action.payload
      });
    case LOGOS:
      return Object.assign({}, state, {
        logos: action.payload
      });
    case HOMEPOSITION:
      return Object.assign({}, state, {
        homePosition: action.payload
      });
    case CONTACT:
      return Object.assign({}, state, {
        contact: action.payload
      });
    case INTRO:
      return Object.assign({}, state, {
        intro: action.payload
      });
    case CLIENTS:
      return Object.assign({}, state, {
        clients: action.payload
      });  
    case LANDINGS:
      return Object.assign({}, state, {
        landingsList: action.payload
      });
    case MENUICON:
      return Object.assign({}, state, {
        menuIcon: action.payload
      });
    case HOMEANIMATION:
      return Object.assign({}, state, {
        dontShowHomeAnimation: action.payload
      });
    case LOADING:
      return Object.assign({}, state, {
        loading: action.payload
      });
    case CAREERS:
      return Object.assign({}, state, {
        careers: action.payload
      });
    case ABOUTUSLIST:
      return Object.assign({}, state, {
        aboutUsList: action.payload
      });
    case ACTIVEABOUTUS:
      return Object.assign({}, state, {
        activeAboutUs: action.payload
      });
    case RODO:
      return Object.assign({}, state, {
        rodo: action.payload
      });
    case PRODUCTION:
      return Object.assign({}, state, {
        production: action.payload
      });
    case POMARANCZA:
      return Object.assign({}, state, {
        pomarancza: action.payload
      });
    case ARTICLES:
      return Object.assign({}, state, {
        articles: action.payload
      });
    case ADD_COLORS:
      return Object.assign({}, state, {
        colors: action.payload
      });
    case TOGGLE_MENU:
      return { ...state, menu: action.payload }
    default:
      return state;
  }
}
export default rootReducer;