import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import { CarouselCareersWrap, CarouselCareersItem, CarouselCareersItemText, CarouselCareersItemImg } from './carousel-careers.styled';
import { MIN_ANIMATION } from '../../config/config';
import Hrefs from '../hrefs/hrefs';


const CarouselCareers = ( props ) => {
  const [index, updateIndex] = useState(0);

  useEffect(() => {
    document.body.style.background = props.color
  }, [props.color])

  const params = {
    // loop: true,
    centeredSlides: true,
    slidesPerView: 1,
    spaceBetween: 20,
    on: {
      slideChange: function(e){
        updateIndex(this.realIndex)
      }
    }
  }
  return (
    <CarouselCareersWrap
      className="animated fadeInUp faster"
      style={{
        animationDelay: `${MIN_ANIMATION+ 0.4}s`
      }}
    >
      <CarouselCareersItemText>
        <Hrefs text={props.content[index].text}/>
      </CarouselCareersItemText>
      <Swiper {...params} className="carousel">
        {
          props.content.map((el,i) => {
            return (
              <CarouselCareersItem
                key={i}
              >
                <CarouselCareersItemImg src={el.img.url} alt={el.img.alt} />
              </CarouselCareersItem>
            )
          })
        }
      </Swiper>
    </CarouselCareersWrap>
  )
}

export default CarouselCareers