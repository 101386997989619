import styled from 'styled-components';

export const NotFoundText = styled.div`
  font-family: europa, sans-serif;
  font-weight: 700;
  font-size: 2.5vw;
  line-height: 3.125vw;
  text-align: right;
  padding-right: 7.8125vw;
  margin-top: calc(50vh - 2.125vw);

  a {
    color: #005FFF;
    text-decoration: none;
  }

  svg {
    width: 1.66vw;
    height: 1.66vw;
  }

  @media (max-width: 1023px){
    display: none;
  }
`

export const NotFoundTextMobile = styled.div`
  font-family: europa, sans-serif;
  font-weight: 700;
  font-size: 6.25vw;
  line-height: 7.8125vw;
  letter-spacing: 1.5px;
  text-align: center;
  top: 20vh;
  position: absolute;
  left: 0;
  right: 0;
  display: none;
  @media (max-width: 1023px){
    display: block;
  }
`

export const NotFoundIco = styled.svg`
  width: 34vw;
  height: 54.6vw;
  animation-delay: 0.2s;
  animation-name: signet;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;

  @keyframes signet {
    0% {transform: rotate(0deg)}
    100% {transform: rotate(-180deg)}
  }
`;

export const NotFoundIcoWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  @media (max-width: 1023px){
    display: block;
  }
`

export const NotFoundMobileback = styled.div`
  display: none;
  @media (max-width: 1023px){
    display: block;
  }
`