import styled, { createGlobalStyle } from 'styled-components';

export const ProjectWrapperContainer = styled.div`
  // padding-left: 70px;
  // padding-right: 100px;
  // padding-top: 180px;
`;

export const SectionProject = styled.div`
`

export const TextWrap = styled.div`
  padding-left: ${props => props.leftZero ? '0' : '3.64vw'}; //70px
  padding-right: ${props => props.rightZero ? '0' : '5.2vw'}; //100px
  
  @media (max-width: 1023px){
    padding: 0 30px;
  }
`

export const TopFirst = styled.div`
  // padding-top: 8.7vh;
`

export const BritaGlobal = createGlobalStyle`

  .brita-section {
    &__intro {
      padding-bottom: 10.729vw;

      @media (max-width: 1023px){
        padding-bottom: 81px;
      }
    }

    &__pos {
      padding-top: 5.729vw;

      @media (max-width: 1023px){
        padding-top: 90px;
      }
    }

    &__print {
      margin-top: -2vw;

      @media (max-width: 1023px){
        margin-top: 0px;
      }
    }

    &__copywritting {
      @media (max-width: 1023px){
        margin-top: 50px;
      }
    }
  }

  .brita-copywritting {
    &__title {
      
    }

    &__text {
      padding-left: 15% !important;

      @media (max-width: 1023px){
        padding-left: 0 !important;
      }
    }

    &__img {
      padding-top: 2vw;
      padding-left: 6%;
      padding-right: 11%;
    }
  }

  .brita-print {
    &__text-1 {
      padding-right: 15% !important;

      @media (max-width: 1023px){
        padding-right: 0 !important;
      }
    }

    &__img-1 {
      padding-right: 5%;
    }
  }

  .brita-kampanie {
    &__text-1 {
      padding-right: 35% !important;

      @media (max-width: 1023px){
        padding-right: 0 !important;
      }
    }

    &__text-2 {
      padding-left: 10%;
      width: 35%;

      @media (max-width: 1023px){
        padding-left: 0;
        width: 100%;
        padding-bottom:
      }
    }

    &__img-1 {
      padding-left: 25%;
      margin-bottom: -25.64vh;

      @media (max-width: 1023px){
        padding-left: 0 !important;
        margin-bottom: -8.64vh;
      }
    }

    &__img-2 {
      padding-left: 25%;
      padding-right: 15%;
    }
  }

  .brita-pos {
    &__title {
      padding-left: 0% !important;

      @media (max-width: 1023px){
        padding-left: 0% !important;;
      }
    }

    &__img-1 {
      padding-top: 6.5625vw !important;
      padding-right: 15%;
      padding-left: 15%;

      @media (max-width: 1023px){
        padding-top: 50px !important;
        padding-bottom: 50px !important;
      }
    }

    &__img-2 {
      @media (max-width: 1023px){
        padding-top: 50px !important;
        padding-bottom: 50px !important;
      }
    }
  }

  .nivea-intro {
    &__titles {
      position: absolute;
      padding-right: 5.2vw;
      padding-top: 10vh;

      @media (max-width: 1023px){
        display: none;
      }
    }

    &__donuts {
      display: flex;
      justify-content: space-around;
      padding-top: calc(50vh - 3.3vw);
      margin-bottom: 5.729vw;

      @media (max-width: 1023px){
        padding-top: 40px;
        margin-bottom: 45px;
      }
    }
  }
`