import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import Swiper from 'react-id-swiper';
import { withRouter } from 'react-router-dom'

import '../../../assets/css/swiper.css';
import { SwiperSlide, Plus, WrapCarousel } from './carousel.styled';
import Routes, { setActiveSlidePortfolio } from '../../../routes/routes';
import { MIN_ANIMATION } from '../../../config/config';

const mapStateToProps = state => {
  return {colors: state.colors};
};

const Carousel = ( {colors, history} ) => {
  const [swiper, updateSwiper] = useState(null);
  const [isWhite, updateIsWhite] = useState(false);
  const [isActive, updateIsActive] = useState(false);

  const childs = Routes.portfolio.childs
  childs.home.color = colors.portfolio && colors.portfolio.color

  useEffect(() => {
    let url = window.location.pathname.split('/')
    if (swiper !== null) {
      if(!url[2]) {
        swiper.slideTo(0)
      } else {
        swiper.slideTo(childs[url[2]].id)
      }
    }
  })
  
  const goTo = () => {
    if (swiper !== null) {
      swiper.slideTo(swiper.clickedIndex);
    }
  };

  const params = {
    direction: 'vertical',
    mousewheel: true,
    initialSlide: setActiveSlidePortfolio(),
    // loop: true,
    centeredSlides: true,
    slidesPerView: 7,
    on: {
      slideChange: function () {
        if( this.slides[this.realIndex] ) {
          const data = this.slides[this.realIndex].dataset
          const color = childs[data.id].color ? childs[data.id].color : childs['home'].color
          document.querySelector('.mainClassOfCaseStudy').style.background = color;
          history.push(`${data.url}`)
          window.scrollTo(0,0)
          updateIsActive(this.realIndex)

          if(data.id === 'altax') {
            updateIsWhite(true)
          } else {
            updateIsWhite(false)
          }
        }
      },
      init: function () {
        if( this.slides[this.realIndex] ) {
          const data = this.slides[this.realIndex].dataset
          updateIsActive(this.realIndex)

          if(data.id === 'altax') {
            updateIsWhite(true)
          } else {
            updateIsWhite(false)
          }
          setTimeout(()=>{
            const color = childs[data.id].color ? childs[data.id].color : childs['home'].color
            document.querySelector('.mainClassOfCaseStudy').style.background = color;
          }, 100)
        }
        
      }
    },
  }
  return (
    <WrapCarousel>
      { (setActiveSlidePortfolio() > 0 && setActiveSlidePortfolio() < Object.keys(Routes.portfolio.childs).length - 1 ) && <Plus>
        <svg xmlns="http://www.w3.org/2000/svg" width="44.44" height="44.439" viewBox="0 0 44.44 44.439">
          <g id="Group_38" transform="translate(-771.5 -71)">
            <line x2="44.44" className="custom-plus" transform="translate(771.5 93.22)" fill="none" stroke="#f9f8f6" strokeWidth="10"/>
            <line x2="44.44" className="custom-plus" transform="translate(793.72 71) rotate(90)" fill="none" stroke="#f9f8f6" strokeWidth="10"/>
          </g>
        </svg>
      </Plus>}
      <Swiper {...params} getSwiper={updateSwiper} id="carousel-clients" className="carousel">
        {
          Object.keys(childs).map((el, i) => {
            let l = MIN_ANIMATION + 0.2 + (i / 10)
            return (
              <SwiperSlide 
                data-id={childs[el].flat} 
                data-url={childs[el].slug}
                key={el}
                onClick={goTo}
                isWhite={isWhite}
                isActive={parseInt(isActive) === i}
                className={`carousel__item animated fadeInUp faster ${childs[el].flat}`}
                  style={{
                    animationDelay: `${l}s`
                  }}
              >
                { childs[el].logo }
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </WrapCarousel>
    
  )
}

export default withRouter(connect(mapStateToProps, null)(Carousel));