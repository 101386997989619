import React, { useState, useEffect } from 'react';
import { TitleWrap, TitleSide, TitleCenter, TitleSideRight, TitleMobile } from './title.styled';
import splitter from 'text-split'
import { fromLetterAnimation, toLetterAnimation } from '../../../letter-animation/letter-animation';
import { ShowDesktop, ShowMobile } from '../../grid/showHide/showHide.style';

const MainTitle = (props) => {
  const [titleShow, updateTitleShow] = useState(false);
  const [leftWords, updateLeftWords] = useState(false);
  const [rightWords, updateRightWords] = useState(false);
  const [mobileWords, updateMobileWords] = useState(false);
  let leftRef = React.createRef();
  let rightRef = React.createRef();
  let mobileRef = React.createRef();

  useEffect(() => {
    updateLeftWords(props.right ? splitter(rightRef.current) : false)
    updateRightWords(props.left ? splitter(leftRef.current) : false)
    updateMobileWords(props.right  ? splitter(mobileRef.current) : false )
  }, [props.colors])

  useEffect(() => {
    // console.log(props.delay)
    setTimeout(()=> {
      updateTitleShow(true)
    }, 0)

    if ( props.slideOut ) {
      if ( leftWords ) {
        toLetterAnimation(leftWords, false, true)
      }
      
      if ( rightWords ) {
        toLetterAnimation(rightWords, false, false)
      }
    } else {
      if ( leftWords ) {
        fromLetterAnimation(leftWords, props.delay ? props.delay : 2.5, true)
      }
      
      if ( rightWords ) {
        fromLetterAnimation(rightWords, props.delay ? props.delay : 2.5, false)
      }

      if( mobileWords ) {
        fromLetterAnimation(mobileWords, props.delay ? props.delay : 2.5, true)
      }
      
    }
    
    if( props.notFound ) {
      setTimeout(()=> {
        if(document.getElementById('center-circle-wrap')) {
          document.getElementById('center-circle-wrap').classList.add('start-animation')
        }
        
      }, 0)
    }
    
  }, [props.colors, props.slideOut, leftWords, rightWords, mobileWords, props.delay]);
  return(
    <TitleWrap color={props.color} notFound={props.notFound} id="center-circle-wrap" absolute={props.absolute ? false : true}>
        <ShowDesktop>
          { props.left && <TitleSide
            ref={leftRef}
            titleShow={titleShow}
            lessLetterSpace={props.lessLetterSpace ? true : false }
          >
            { props.left }
          </TitleSide> }
          <TitleCenter
            className={`animated fadeIn faster`}
            notFound={props.notFound}
            id="center-circle"
          >
            <span style={{
              color: props.sygnetColor
            }} className="icon-signet"></span>
            <strong>o</strong>
          </TitleCenter>
          { props.right && <TitleSideRight
            ref={rightRef}
            titleShow={titleShow}
            lessLetterSpace={props.lessLetterSpace ? true : false }
          >
            { props.right }
          </TitleSideRight> }
        </ShowDesktop>
        <ShowMobile
          
        >
          <TitleMobile
            ref={mobileRef}
            notFound={props.notFound}
          >
          { props.left }o{props.right}
          </TitleMobile>
        </ShowMobile>
      </TitleWrap>
  )
}

export default MainTitle