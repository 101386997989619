import styled  from 'styled-components';

export const ShowDesktop = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`

export const ShowMobile = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`