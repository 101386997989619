import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Half } from '../grid/half/Half.styled';
import { Container } from '../grid/container/Container.styled';
import { Email, EmialIcon, PhoneIcon } from '../ui/email/email.styled';
import { Address } from '../ui/address/address';
import MainTitle from '../ui/title/title';
import { TextCenter } from '../ui/text-center/text-center';
import MenuIcon from '../menu-icon/menu-icon';
import Routes from '../../routes/routes';
import { BG } from '../grid/bg/bg.style';
import { isMobile } from 'react-device-detect';
import { MIN_ANIMATION } from '../../config/config';
import Cookies from '../cookies/Cookies';

const mapStateToProps = state => {
  return {colors: state.colors};
};

const Contact = ({colors, isHome, showHide, animationOut}) => {
  const display = () => {
   if(isHome) {
    return showHide ? 'block' : 'none'
   } else {
    return true
   }
  }

  return (
    <BG 
      className={`
        ${showHide && isHome ? 'animated fadeIn faster' : ''}
        ${animationOut && isHome ? 'animated fadeOut faster' : ''}
      `}
      style={{
        display: display()
      }}
      color={ colors.kontakt ? colors.kontakt.color : '' }
      overflow="auto"
    > 
      <MenuIcon 
        isAbsolute={true}
        color={ colors.kontakt ? colors.kontakt.color : '' }
      />
      <MainTitle 
        left='p'
        right='rozmawiajmy!'
        colors={colors}
        delay={isHome ? 0.1 : false}
      />
      <Container forMobileAutoHeight={true}>
        <Half notFixed={true} fullHeight={true}>
          <TextCenter>
            <Email 
              className="animated fadeInUp faster" 
              style={{
                animationDelay: `${ isHome ? 0.3 : MIN_ANIMATION + 0.2}s`
              }}
              first={true}
            >
              <EmialIcon>
                <svg width="60.794" height="42.591" viewBox="0 0 60.794 42.591">
                  <g fill="#005FFF">
                    <path d="M60.794 42.591H0V0h60.794zM3.978 38.612h52.837V3.978H3.978z"/>
                    <path d="M30.668 33.485L.578 3.395 3.391.583 30.668 27.86l27-27 2.813 2.813z"/>
                  </g>
                </svg>
              </EmialIcon>
              <a href="mailto:hello@pomarancza.pl">hello@pomarancza.pl <svg width="60.794" height="42.591" viewBox="0 0 60.794 42.591">
                <g fill="#005FFF">
                  <path d="M60.794 42.591H0V0h60.794zM3.978 38.612h52.837V3.978H3.978z"/>
                  <path d="M30.668 33.485L.578 3.395 3.391.583 30.668 27.86l27-27 2.813 2.813z"/>
                </g>
              </svg></a>
            </Email>
            <Email 
              className="animated fadeInUp faster" 
              style={{
                animationDelay: `${isHome ? 0.4 : MIN_ANIMATION + 0.3}s`
              }}
            
              mobile={true}
            >
              <PhoneIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="27.688" height="27.644" viewBox="0 0 27.688 27.644">
                  <g transform="translate(-12.975 -16.992)">
                    <path d="M44.85,78.2s3.457,3.408,4.865,3.679L52.6,78.99a1.685,1.685,0,0,1,2.346.025l4.716,4.716s.765,1.408-.123,2.272l-2.3,2.3S53.2,90.892,46.7,86.744a26.665,26.665,0,0,1-5.9-4.494" transform="translate(-20.275 -45.365)" fill="none" stroke="#005FFF" strokeMiterlimit="10" strokeWidth="1.8"/>
                    <path d="M24.821,33.082s-3.408-3.457-3.7-4.865l2.889-2.889a1.685,1.685,0,0,0-.025-2.346l-4.741-4.716s-1.408-.765-2.272.123l-2.3,2.3s-2.593,4.05,1.58,10.544a26.345,26.345,0,0,0,4.519,5.9" fill="none" stroke="#005FFF" strokeMiterlimit="10" strokeWidth="1.8"/>
                  </g>
                </svg>
              </PhoneIcon>
              <div style={{width:'100%', textAlign: 'center'}}>Radek Obek</div>
              <a href="tel:+48530080680">+48 530 080 680</a>
            </Email>
            <Address
              className="animated fadeInUp faster" 
              style={{
                animationDelay: `${isHome ? 0.5 : MIN_ANIMATION + 0.5}s`
              }}
            >
              <strong class="extra">Radek Obek, +48&nbsp;530&nbsp;080&nbsp;680</strong>
              Pomarańcza sp. z o.o.<br/>
              {isMobile && <a href="https://www.google.com/maps/place/Pomarańcza/@52.424596,16.8560484,17z/data=!3m1!4b1!4m5!3m4!1s0x4704448bd511fec9:0x1d98bba225b8e76c!8m2!3d52.424596!4d16.8582371">
              <svg width="19.035" height="27.119" fill="#005FFF" viewBox="0 0 19.035 27.119">
                <g transform="translate(-1645.046 -722.291)" >
                  <path d="M1653.741 748.438c-2.614-3.077-8.694-10.857-8.694-16.629a9.517 9.517 0 1 1 19.034 0c0 5.755-6.079 13.547-8.693 16.629l-.823.972zm.824-23.987a7.366 7.366 0 0 0-7.357 7.359c0 4.531 4.888 11.15 6.99 13.786l.367.459.365-.46c2.1-2.639 6.99-9.264 6.99-13.784a7.366 7.366 0 0 0-7.356-7.36z"/>
                  <circle cx="2.653" cy="2.653" r="2.653" transform="translate(1651.911 729.781)"/>
                </g>
              </svg></a> }
              {!isMobile && <Link to={Routes.kontakt.childs.map.slug}>
              <svg width="19.035" height="27.119" fill="#005FFF" viewBox="0 0 19.035 27.119">
                <g transform="translate(-1645.046 -722.291)" >
                  <path d="M1653.741 748.438c-2.614-3.077-8.694-10.857-8.694-16.629a9.517 9.517 0 1 1 19.034 0c0 5.755-6.079 13.547-8.693 16.629l-.823.972zm.824-23.987a7.366 7.366 0 0 0-7.357 7.359c0 4.531 4.888 11.15 6.99 13.786l.367.459.365-.46c2.1-2.639 6.99-9.264 6.99-13.784a7.366 7.366 0 0 0-7.356-7.36z"/>
                  <circle cx="2.653" cy="2.653" r="2.653" transform="translate(1651.911 729.781)"/>
                </g>
              </svg></Link> }
              {' '}
              ul. Przelot 17, 60-408 Poznań<br/>
              NIP&nbsp;7811987297, KRS&nbsp;0000766064<br/>
              <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/AgencjaReklamowaPomarancza">fb</a> | 
              <a target="_blank" rel="noopener noreferrer" href="https://pl.linkedin.com/company/pomarancza">linkedin</a> | 
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/pomarancza_poznan/">insta</a>
            </Address>
          </TextCenter>
          
        </Half>
        <Half hideMobile={true} ></Half>
      </Container>
      <Cookies
        color={'#232328'}
        background={colors.kontakt ? colors.kontakt.color : ''}
      />
    </BG>
  )
}

export default connect(mapStateToProps, null)(Contact);