import React from 'react';
import { BlockQuoteContainer, BlockQuoteContent, BlockQuoteAutor, BlockQuoteCompany, BlockQuoteAutorWrap, BlockQuoteAutorName } from './block-quote.style';
import { ShowDesktop, ShowMobile } from '../../grid/showHide/showHide.style';

function BlockQuote(props) {
  return(
    <BlockQuoteContainer>
      <BlockQuoteContent isRight={props.isRight} color={props.color}>
        {props.content}
      </BlockQuoteContent>
      { props.author && <BlockQuoteAutor color={props.color}>
        <BlockQuoteAutorWrap><BlockQuoteAutorName isProduction={props.isProduction}>{props.author}</BlockQuoteAutorName><BlockQuoteCompany>{ props.company }</BlockQuoteCompany></BlockQuoteAutorWrap>
        <BlockQuoteAutorWrap>
          <ShowDesktop>
            <svg width="53.543" height="37.452" viewBox="0 0 53.543 37.452">
              <g >
                <path d="M52.543 1v26.642l-9.658-.008-4.539 7.942-5.2-7.548H1V1z" fill="none" stroke={props.color} strokeMiterlimit="10" strokeWidth="2"/>
                <g transform="translate(13.876 12.417)" fill={props.color}>
                  <circle cx="2.518" cy="2.518" r="2.518"/>
                  <circle cx="2.518" cy="2.518" r="2.518" transform="translate(10.378)"/>
                  <circle cx="2.518" cy="2.518" r="2.518" transform="translate(20.755)"/>
                </g>
              </g>
            </svg>
          </ShowDesktop>
          <ShowMobile>
            <svg width="39.622" height="28.114" viewBox="0 0 39.622 28.114">
              <g data-name="Group 210">
                <path data-name="Path 93" d="M38.622 1v19.442l-15.049-.006-3.313 5.8-3.8-5.509H.996V1z" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
                <g data-name="Group 62" transform="translate(10.398 9.334)" fill="#fff">
                  <circle data-name="Ellipse 1" cx="1.838" cy="1.838" r="1.838"/>
                  <circle data-name="Ellipse 2" cx="1.838" cy="1.838" r="1.838" transform="translate(7.575)"/>
                  <circle data-name="Ellipse 3" cx="1.838" cy="1.838" r="1.838" transform="translate(15.15)"/>
                </g>
              </g>
            </svg>
          </ShowMobile>
        </BlockQuoteAutorWrap>
      </BlockQuoteAutor>}
      
    </BlockQuoteContainer>
  )
}

export default BlockQuote;