import styled  from 'styled-components';


export const CookieBox = styled.div`
  position: ${props => props.fixed ? 'fixed' : 'absolute'};
  bottom: 1.5vw;
  right: 5%;
  z-index: 4;
  display: ${props => props.isActive ? 'none' : 'block'};

  @media (max-width: 1023px) {
    right: initial;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 7vw;
  }

  .cookie-frame-mobile {
    @media (min-width: 1023px) {
      display: none;
    }

    path {
      transition: stroke .3s ease;
    }
    width: 94vw;
    height: 55.46vw;
  }

  .cookie-frame {
    width: 39.65625vw;
    height: 7.540575vw;

    path {
      transition: stroke .3s ease;
    }
    @media (max-width: 1023px) {
      display: none;
    }
  }
`

export const CookieBoxClose = styled.button`
  position: absolute;
  top: 1.2vw;
  right 0.6vw;
  background: transparent;
  border: transparent;
  z-index: 1;
  cursor: pointer;
  outline: 0;

  @media (max-width: 1023px) {
    top: 3.2vw;
    right 2.2vw;
  }

  svg {
    pointer-events: none;
    width: 1vw;
    height: 1vw;

    g {
      transition: fill .3s ease;
    }

    @media (max-width: 1023px) {
      width: 4.375vw;
      height: 4.375vw;
    }
  }
`

export const CookieBoxText = styled.p`
  position: absolute;
  font-family: europa, sans-serif;
  font-size: 1.03375vw; //1.09375vw
  line-height: 1.5625vw;
  letter-spacing: 1.75px;
  color: ${props => props.color};
  top:0;
  left: 0;
  padding-left: 1.04vw;
  padding-right: 3.04vw;
  transition: color .3s ease;

  @media (min-width: 1800px) {
    font-size: 0.82vw;
    line-height: 1.4vw;
  }

  @media (max-width: 1023px) {
    font-size: 5vw;
    line-height: 6vw;
    padding-left: 3.125vw;
    padding-right: 6.04vw;
  }

  a {
    color: ${props => props.color};
    font-weight: 700;
    transition: color .3s ease;
  }
`