import { createGlobalStyle } from 'styled-components';


export const NutriciaGlobal = createGlobalStyle`

  .nutricia-section {
    &__kampania {
      margin-bottom: 6.5vw;
    }

    &__kampania-text {
      margin-bottom: 1.5vw;
    }

    &__kampania-img1 {
      margin-top: 5.2vw;
      margin-bottom: 5.2vw;
      padding-left: 6%;
      padding-right: 2%;
    }

    &__kampania-img2 {
      margin-top: 5.2vw;
      margin-bottom: 5.2vw;
      padding-left: 7%;
      padding-right: 20%;

      @media (max-width: 1023px){
        padding-right: 7%;
      }
    }

    &__landing-page {
      margin-bottom: 6.5vw;
    }

    &__landing-page-text1 {
      text-align: right;
    }

    &__landing-page-img1 {
      margin-bottom: 5.2vw;
      padding-left: 4%;
      padding-right: 10%;

      @media (max-width: 1023px){
        padding-right: 4%;
      }
    }

    &__social-media {
      margin-bottom: 7.8125vw;
    }

    &__social-media-text1 {
      margin-bottom: 1.5vw;
    }

    &__social-media-img1 {
      margin-top: 4.5vw;
      margin-bottom: 2vw;
      padding-right: 8%;

      @media (max-width: 1023px){
        padding-right: 0%;
      }
    }

    &__social-media-text2 {
      text-align: right;
    }

    &__social-media-img2 {
      margin-top: 2vw;
      padding-right: 6%;

      @media (max-width: 1023px){
        padding-right: 0%;
      }
    }

    &__social-media-img3 {
      margin-top: 4vw;
      margin-bottom: 8vw;
    }

    &__print{
      margin-bottom: 4.5vw;
    }

    &__print-text1{
      text-align: right;
    }

    &__print-img1 {
      margin-top: 3.125vw;
      padding-left: 15%;
      padding-right: 15%;
    }

    &__pos {
      margin-bottom: 6.5vw;
    }

    &__pos-img1 {
      margin-top: 3.125vw;
      margin-bottom: 5vw;
      padding-left: 2%;
      padding-right: 10%;

      @media (max-width: 1023px){
        padding-left: 5%;
        padding-right: 5%;
      }
    }

    &__pos-text1 {
      text-align: right;
    }

    &__pos-img2 {
      margin-top: 3.125vw;
      margin-bottom: 5vw;
      padding-left: 2%;
      padding-right: 10%;

      @media (max-width: 1023px){
        padding-left: 5%;
        padding-right: 5%;
      }
    }

    &__pos-img3 {
      padding-left: 20%;
      padding-right: 30%;

      @media (max-width: 1023px){
        padding-right: 20%;
      }
    }

    &__kampania-2-text1 {
      padding-top: 1.5vw;
      padding-right: 32% !important;

      @media (max-width: 1023px){
        padding-right: 0 !important;
      }
    }

    &__kampania-2-img1 {
      padding-top: 1.5vw;
      margin-bottom: 5.2vw;
      padding-left: 6%;
      padding-right: 5%;

      @media (max-width: 1023px){
        padding-right: 0%;
      }
    }

    &__kampania-2-text2 {
      padding-right: 20% !important;

      @media (max-width: 1023px){
        padding-right: 0 !important;
      }
    }

    &__kampania-2-img2 {
      padding-top: 2.5vw;
      margin-bottom: 3vw;
      padding-left: 4%;
      padding-right: 10%;

      @media (max-width: 1023px){
        padding-right: 4%;
      }
    }

    &__kampania-2-img3 {
      padding-top: 2.5vw;
      margin-bottom: 3vw;
      padding-left: 4%;
      padding-right: 4%;

      @media (max-width: 1023px){
        padding-right: 0%;
      }
    }
    &__video-text1{
      text-align: right;

      a {
        color: #fff;
      }
    }

    &__video-img1 {
      padding-top: 2.5vw;
      margin-bottom: 3vw;
      padding-left: 4%;
      padding-right: 10%;

      @media (max-width: 1023px){
        padding-right: 4%;
      }
    }

    &__video-text2{
      @media (min-width: 1024px){
        width: 50%;
      }
    }

    &__video-img2 {
      @media (min-width: 1024px){
        width: 38%;
        margin-left: 50%;
        margin-top: -40%;
      }
    }

  }
  
`