import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import { withRouter } from 'react-router-dom'
import '../../assets/css/swiper.css';
import slugify from 'slugify';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MainTitle from '../ui/title/title';
import { More } from '../ui/more/more.styled';
import { HalfFull, Half } from '../../components/grid/half/Half.styled';
import { Container } from '../../components/grid/container/Container.styled';
import { CarouselText, CarouselTextInside, Carousel } from '../../components/carousel-texts/carousel-texts.styled';
import { TextText } from '../../components/text-text/text-text.styled';
import { ShowDesktop } from '../grid/showHide/showHide.style';
import { MIN_ANIMATION } from '../../config/config';
import Hrefs from '../hrefs/hrefs';
import SEO from '../seo/seo';
import ProductionSection from '../produkcja/produkcja';
import Routes from '../../routes/routes';
import { onScrollProduction } from '../../actions';
import styled from 'styled-components';

export const Plus = styled.div`
  position: absolute;
  top: 50vh;
  left: 40vw;
  transform: translate(0, -50%);

  svg {
    width: 2.29vw;
    height: 2.29vw;
  }
`

function mapDispatchToProps(dispatch) {
  return {
    onScrollProduction: animation => dispatch(onScrollProduction(animation))
  };
}

const TextDesktopInfo = ( {history, list, spaceBetween, onScrollProduction, contact} ) => {
  const [position, updatePosition] = useState(0);
  const [swiper, updateSwiper] = useState(null);
  const [start, updateStart] = useState('start');
  const [onLoad, updateOnLoad] = useState(MIN_ANIMATION);

  let url = window.location.pathname.split('/')

  useEffect(() => {
    onScrollProduction(false)
  },[])

  const goTo = (el) => {
    if (el !== null) {
      swiper.slideTo(el.target.dataset.id);
    }
  };

  useEffect(() => {
    const childs = list
    childs.map((el, i) => {
      if( slugify(el.title, '-') === url[2] ) {
        updatePosition(i);
        if(url[2] === 'kreatywna-produkcja') {
          document.getElementById('wrapBg').classList.add('production-section')
        }
      }
    })
  }, [updatePosition, url, list])

  const setActiveSlideText = () => {
    
    const childs = list
    let current = 0
    childs.map((el, i) => {
      if( slugify(el.title, '-') === url[2] ) {
        current = i;
      }
    })
    // console.log(current)
    return current
  }

  const params = {
    direction: 'vertical',
    mousewheel: {
      eventsTarged: '.container-for-events',
    },
    touchEventsTarget: '.container-for-events',
    simulateTouch: true,
    centeredSlides: true,
    initialSlide: setActiveSlideText(),
    slidesPerView: 5,
    spaceBetween: spaceBetween,
    on: {
      init: function () {
        if( this.slides[this.realIndex] ) {
          const data = this.slides[this.realIndex].dataset

          if(contact && this.realIndex == 0) {

          } else {
            history.push(`${data.slug}`)
          }
          

          if(url[2] === 'kreatywna-produkcja') {
            document.getElementById('wrapBg').classList.add('production-section')
          }
        }
        // setActiveSlidePortfolio()
        setTimeout(() => {
          updateStart(false)
        }, 1000)
      },
      slideChange: function () {
        if( this.slides[this.realIndex] ) {
          const data = this.slides[this.realIndex].dataset
          updateOnLoad(0.3)
          onScrollProduction(true)
          if(contact && this.realIndex == 0) {
            history.push('/kontakt/')
          } else {
            history.push(`${data.slug}`)
          }
          let url = window.location.pathname.split('/')
          if(url[1] === Routes.produkcja.flat) {
            if(url[2] === 'kreatywna-produkcja') {
              document.getElementById('wrapBg').classList.add('production-section')
            } else {
              document.getElementById('wrapBg').classList.remove('production-section')
            }
          }
          
        }
        updatePosition(this.realIndex)
      }
    }
  }


  return (
    <ShowDesktop>
      <Container>
        {contact && position !== 0 && (
          <Plus>
            <svg xmlns="http://www.w3.org/2000/svg" width="44.44" height="44.439" viewBox="0 0 44.44 44.439">
              <g id="Group_38" transform="translate(-771.5 -71)">
                <line x2="44.44" transform="translate(771.5 93.22)" fill="none" stroke="#232328" strokeWidth="10"/>
                <line x2="44.44" transform="translate(793.72 71) rotate(90)" fill="none" stroke="#232328" strokeWidth="10"/>
              </g>
            </svg>
          </Plus>
        )}
        {contact && (
          <MainTitle 
            left={position == 0 ? 'P' : ''}
            right={position == 0 ? 'ROZMAWIAJMY!' : ''}
          />
        )}
        
        <HalfFull
          style={{
            position: 'fixed',
            top: '0',
            left: '0'
          }}
        hideMobile={true} className="container-for-events" fullHeight={true}>
          <Carousel>
            <Swiper {...params} getSwiper={updateSwiper}>
              {
                list.map((el, i) => {
                  const li = onLoad + 0.1 + (i / 10)
                  return (
                    <CarouselText
                      className="slider-produtction"
                      key={i}
                      data-slug={`/${url[1]}/${slugify(el.title, '-')}`}
                    >
                      
                      <CarouselTextInside
                        isStart={start}
                        className="animated fadeInUp faster" 
                        style={{
                          animationDelay: `${li}s`
                        }}
                        onClick={goTo}
                        data-id={i}
                        data-slug={`/${url[1]}/${slugify(el.title, '-')}`}
                      >
                        { el.title }
                      </CarouselTextInside>
                    </CarouselText>
                  )
                })
              }
            </Swiper>
          </Carousel>
        </HalfFull>
          <Half
            style={{
              opacity: (position === 0 && url[1] === Routes.produkcja.flat ) ? 1 : 0,
              visibility: (position === 0 && url[1] === Routes.produkcja.flat ) ? 'visible' : 'hidden'
            }}
          hideMobile={true}>
          <ProductionSection seo={list[position].seo}/>
        </Half>
        <Half
          style={{
            visibility: (position > 0 || url[1] !== Routes.produkcja.flat ) ? 'visible' : 'hidden',
            position: 'fixed',
            opacity:  (position > 0 || url[1] !== Routes.produkcja.flat ) ? 1 : 0,
            top: 0,
            right: 0
          }}
        className="container-for-events" hideMobile={true}>
            <TextText
              className="animated fadeInUp faster" 
              style={{
                animationDelay: `${onLoad + 0.4}s`
              }}
              isSmallTitle={list[position].small_title}
            >
            <SEO 
              title={list[position].seo.title}
              description={list[position].seo.description}
              keywords={list[position].seo.keywords}
            />
            {!contact && <Hrefs  text={list[position].descriptio} />}
            {contact && list[position].descriptio}
            {list[position].link && <More 
              dontHideBr={true} color="#005FFF">
              <Link
                to={list[position].link}>czytaj dalej! <svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={'#005FFF'}/>
              </svg>
              </Link>
            </More>}
          </TextText>
        </Half>
      </Container>
    </ShowDesktop>
  )
}

export default connect(false, mapDispatchToProps)(withRouter(TextDesktopInfo))