import styled from 'styled-components';

export const AnchorStyle = styled.div`
  a {
    text-decoration: none;
    font-weight: 700;
    color: #005FFF;

    &:visited {
      color: #005FFF;
    }
  }
`