import { ADD_COLORS, TOGGLE_MENU, ARTICLES, POMARANCZA, PRODUCTION, RODO, ACTIVEABOUTUS, ABOUTUSLIST, CAREERS, HOMEANIMATION, LOADING, MENUICON, LANDINGS, INTRO, CONTACT, HOMEPOSITION, LOGOS, HOMESCROLL, ONSCROLLPRODUCTION, MENUCLOSE, CLIENTS } from "../constants/action-types";

export function homePosition(payload) {
  return { type: HOMEPOSITION, payload };
}

export function contact(payload) {
  return { type: CONTACT, payload };
}

export function intro(payload) {
  return { type: INTRO, payload };
}

export function menuIcon(payload) {
  return { type: MENUICON, payload };
}

export function homeAnimation(payload) {
  return { type: HOMEANIMATION, payload };
}

export function loading(payload) {
  return { type: LOADING, payload };
}

export function careers(payload) {
  return { type: CAREERS, payload };
}

export function addColors(payload) {
  return { type: ADD_COLORS, payload };
}

export function aboutUsList(payload) {
  return { type: ABOUTUSLIST, payload };
}

export function aboutUs(payload) {
  return { type: ACTIVEABOUTUS, payload };
}

export function toggleMenu(payload) {
  return { type: TOGGLE_MENU, payload };
}

export function clients(payload) {
  return { type: CLIENTS, payload };
}

export function articles(payload) {
  return { type: ARTICLES, payload };
}

export function pomarancza(payload) {
  return { type: POMARANCZA, payload };
}

export function production(payload) {
  return { type: PRODUCTION, payload };
}

export function rodo(payload) {
  return { type: RODO, payload };
}

export function landings(payload) {
  return { type: LANDINGS, payload };
}

export function logos(payload) {
  return { type: LOGOS, payload };
}

export function homeScroll(payload) {
  return { type: HOMESCROLL, payload };
}

export function menuClose(payload) {
  return { type: MENUCLOSE, payload };
}

export function onScrollProduction(payload) {
  return { type: ONSCROLLPRODUCTION, payload };
}