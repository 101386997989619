import styled from 'styled-components'

export const HomeWrapText = styled.div`
  position: absolute;
  /* bottom: 13%; */
  right: 5%;
  left: 0;
  /* top: 60%; */
  @media (min-width: 1023px) {
    top: 55%;
  }
  @media (max-width: 1023px) {
    right: 0;
    padding: 2vw;
    /* bottom: 8%; */
  }

  /* @media (min-width: 1800px) {
    top: 60%;
    bottom: initial;
  } */
`

export const HomeInsideText = styled.div`
  position: relative;
`

export const HomeText = styled.div`
  color: #F9F8F6;
  font-family: europa, sans-serif;
  font-weight: ${props => props.bold ? '700' : '300'};
  font-size: 1.09375vw;
  line-height: 1.875vw;
  letter-spacing: 1.75px;
  transition: .3s;

  br {
    @media (max-width: 640px) {
      display: none;
    }
  }

  p {
    margin-top: 0;
  }

  span {
    font-size: 1.5vw;
    line-height: 1.3;
    margin-bottom: 0.2vw;
    display: block;

    @media (max-width: 1023px) {
      font-size: 5vw; 
      margin-bottom: 1em;
    }
  }

  @media (min-width: 1800px) {
    font-size: 0.82vw;
    line-height: 1.4vw;
  }

  @media (max-width: 1023px) {
    font-size: ${props => props.isBott ? '5vw' : '4.25vw'}; 
    line-height: ${props => props.isBott ? '1.3' : '7.33vw'};
    /* padding: 0 8.33vw; */
    text-align: center;
    position: initial;
    top: initial;
    right: initial;
    /* padding-top: ${props => props.homeMobileSmall ? '0' : '8.33vw'}; */
    display: ${props => props.mobileHide ? 'none' : 'block'};

    ${ props => props.homeMobileSmall && `
      position: absolute;
      top: calc(50% - 83px);
    `}

    ${
      props => props.mobileExtra && `
        display: block;
        line-height: 5.8vw;
        margin-top: -4vh;
      `
    }
  }

  @media (min-height:767px) and (max-width: 1023px) {
    padding-top: 2vw;
  }

  @media (max-width: 1023px) and (orientation: landscape) {
    padding-top: 0;
  }

  @media (max-width: 359px){
    padding-top: 0;
    font-size: 12px;
  }

  strong, b {
    display: block;
    font-weight: 700;
  }
  a {
    color: #005FFF;
    text-decoration: none;
  }

  ${ props => props.main && `
    a {
      color: #fff;
      text-decoration: underline;
    }
  `}
`
