import styled from 'styled-components';
import {isMobileSafari, isMobile, osName} from 'react-device-detect';


export const MenuWrap = styled.div`
  position: fixed;
  opacity: ${ props => props.showmenu ? '1' : '0' };
  visibility: ${ props => props.isVisibility ? 'visible' : 'hidden' };
  left: 0;
  right: 0;
  height: 100vh;
  height: ${ isMobile ? 'calc(var(--vh, 1vh) * 100)' : '100vh'};
  overflow: hidden;
  background: rgb(35, 35, 40);
  z-index: 6;
  transition: opacity 0.2s;
  // display: flex;
  // align-items: center;

  ${isMobileSafari && 
    `
      height: 100%;
      min-height: -webkit-fill-available;
    `
  }

  .swiper-container {
    height: 50vh;
    overflow: visible;
    top: 53vh;
    transform: translateY(-50%);

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .swiper-slide {
    // height: auto !important;
  }
  
`;

export const MenuItems = styled.div`
  position: absolute;
  transform: translate(-50%, calc(${props => props.index} * -2.6vw));
  top: calc(50% - ${osName === 'Mac OS' ? '3.8vw' : '3.6vw'});
  left: 50%;

  @media (min-width: 1500px) {
    top: calc(50% - ${osName === 'Mac OS' ? '4vw' : '3.65vw'});
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

export const MenuItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: rgba(0, 0, 0, 1);
  height: ${ props => props.active ? '5.5vw' : '2.6vw'} !important;
  cursor: pointer;
  width: 65vw;
  padding-top: ${ props => props.active ? '0.8vw' : '0'}; // 0.6vw 0.95vw
  
  &.newmenu-home {
    margin-left: -0.11vw;
    .icon-signet {
      padding: 0 0.21vw 0 0.21vw;
    }
  }

  &.newmenu-kompetencje {
    .icon-signet {
      padding: 0 0.21vw 0 0;
    }
  }

  &.newmenu-portfolio {
    margin-left: -0.055vw;
    .icon-signet {
      padding: 0 0.21vw 0 0.11vw;
    }
  }

  &.newmenu-produkcja {
    padding-top: ${ props => props.active ? `${osName === 'Mac OS' ? 'calc(0.8vw + 0.5px)' : 'calc(0.8vw + 0.5px)'}` : '0'}; // 0.6vw 0.95vw
    margin-left: -0.055vw;
    .icon-signet {
      padding: 0 0.21vw 0 0.11vw;
    }
  }

  &.newmenu-onas {
    margin-left: -0.055vw;
    .icon-signet {
      padding: 0 0.21vw 0 0.11vw;
    }
  }

  &.newmenu-kogoszukamy {
    margin-left: -0.11vw;
    .icon-signet {
      padding: 0 0.21vw 0 0.21vw;
      
    }
  }

  &.newmenu-kontakt {
    margin-left: -0.055vw;
    .icon-signet {
      padding: 0 0.21vw 0 0.11vw;
    }
  }

  .menu-item {

    background: ${props => props.showSecond && 'red'};
    ${ props => props.active && `
      // transition: color .3s ease-in-out;
      font-size: 5.2vw;
      line-height: 6.8vw;
      // transition: color .3s ease-in;
      .center {
        margin-top: ${ osName === 'Mac OS' ? '-4px' : '0'};
      }
      animation-name: example-ds;
      animation-delay: 0s;
      animation-duration: 0.2s;
      animation-fill-mode: forwards;
      @keyframes example-ds {
        0% {color: var(--menu-prev-color);}
        100% {color: var(--menu-current-color);}
      }
    `}

    //previousColor
    strong {
      display: ${ props => props.active ? 'none' : 'black'}
    }

    .center {
      span {
        display: ${ props => props.active ? 'block' : 'none'}
      }
    }
    
    // color: ${props => props.active ? 'var(--menu-prev-color)' : ''} !important;
   
  }
`;

export const SAR = styled.div`
  left: 90px;
  bottom: 25px;
  position: absolute;

  svg {
    max-width: 30vw;
    height: auto;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

export const LikeUs = styled.div`
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  position: absolute;
  font-family: europa, sans-serif;
  font-weight: 300;
  font-size: 0.9375vw;
  line-height: 1.125vw; 
  color: #000000;
  letter-spacing: 1.75px;
  color: ${props => props.color};
  a {
    color: ${props => props.color};
    text-decoration: none;
    transition: .3s;

    strong {
      font-weight: 700;
    }

    &:hover {
      color: #000000;
    }
  }

  @media (max-width: 1023px) {
    font-size: 4.44vw;
    line-height: 5.83vw;
    bottom: 16.94vw;
  }

  @media (max-width: 1023px) and (orientation: landscape) {
    bottom: 8.61vw;
  }
`

export const CloseMenu = styled.button`
  position: absolute;
  color: ${props => props.color};
  width: 35px;
  height: 35px;
  padding: 0;
  margin: 0;
  apperance: none;
  border: 0;
  top: 2.6vw;
  left: calc(50vw - 17px);
  cursor: pointer;
  background: transparent;
  font-variant-ligatures: none;
  z-index: ${props => props.zIndex};

  @media (min-width: 1800px) { 
    top: 1.2vw;
  }

  svg {
    transition: .6s;
    width: 1.82vw;
    height: 1.82vw;
  }

  &:hover {
    svg {
      transform: rotate(-90deg);
    }
  }

  @media (max-width: 1023px) {
    top: 6.94vw;

    svg {
      width: 5.55vw;
      height: 5.55vw;
    }
  }

  ${props => props.hideOnDesktop && `
    @media (min-width: 1023px) {
      display: none;
    }
  `}
`

export const PolicyAndCopyrights = styled.div`
  position: absolute;
  right: 90px;
  bottom: 30px;
  font-family: europa, sans-serif;
  font-weight: 300;
  font-size: 0.9375vw;
  line-height: 1.125vw; 
  color: #000000;
  letter-spacing: 1.75px;

  a {
    color: #000000;
    text-decoration: none;
    transition: .3s;

    &:hover {
      color: ${props => props.color}
    }
  }

  @media (max-width: 1023px) {
    font-size: 2.77vw;
    line-height: 3.33w;
    left: 50%;
    transform: translateX(-50%);
    bottom: 8.33vw;
    right: auto;
  }

  @media (max-width: 1023px) and (orientation: landscape) {
    bottom: 2.77vw;
  }
`

export const PolicyAndCopyrightsMobileHide = styled.span`
  @media (max-width: 1023px) {
    display: none;
  }
`

export const MenuMobile = styled.div`
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`;

export const MenuMobileRow = styled.div`
  text-align: center;
`;

export const MenuMobileItem = styled.button`
  font-family: Intro, sans-serif;
  font-weight: 700;
  font-size: 8.88vw;
  line-height: 12.27vw;
  text-transform: uppercase;
  apperance: none;
  border: none;
  background: transparent;
  color: ${props => props.active ? 'var(--menu-current-color)' : 'rgba(0, 0, 0, 1)'} !important;
  font-variant-ligatures: none;

  @media (max-width: 1023px) and (orientation: landscape) {
    font-size: 5vw;
    line-height: 6.38vw;
  }
`

export const MenuMobileAnimation = styled.span`

  span {
    opacity: 1;
  }

`