import styled from 'styled-components';
import { osName, isSafari } from 'react-device-detect';

export const RightMenuWrap = styled.div`
  position: fixed;
  top: 50%;
  right: 2.08vw; //40px
  transform: translateY(-50%);
  z-index: 4;
`;

export const RightMenuText = styled.div`
  font-family: europa, sans-serif;
  font-size: 1.09375vw;
  line-height: 1.4458vw;
  letter-spacing: 1.75px;
  color: #F9F8F6;
  text-transform: lowercase;
  position: absolute;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  height: 50vh;
  top: 0;
  transform: translateY(-100%) translateX(${ osName === 'Mac OS' && isSafari ? '0' : '-10%'});
  margin-right: -7px;
  text-align: right;
`;

export const RightMenuContainer = styled.div`
  z-index: 1;
  position: relative;
`;
// const Link = ({ className, red, text, ...props }) => (
//   <a {...props} className={className}>
//     {text}
//   </a>
// )

export const RightMenuItem = styled.button`
  display: flex;
  width: 0.78125vw;
  height: 3px;
  apperance: none;
  border: none;
  background: ${ props => props.isActive ? '#F9F8F6' : props.color };
  margin: 0.572vw 0;
  z-index: 2;
  border: none;
  cursor: pointer;
  outline: none;
  font-variant-ligatures: none;
`;