export const colors = [
  {
    color: '#c437ff',
    colorText: '#62008c',
    colorRoad: '#CC5AFF',
    colorWather: '#d779ff'
  },
  {
    color: '#CFE500',
    colorText: '#697500',
    colorRoad: '#aec100',
    colorWather: '#e1fb00'
  },
  {
    color: '#03EA86',
    colorText: '#019254',
    colorRoad: '#02c874',
    colorWather: '#42fdad'
  },
  {
    color: '#FF007A',
    colorText: '#a4004e',
    colorRoad: '#FF007A',
    colorWather: '#ff4297'
  },
  {
    color: '#00EAEA',
    colorText: '#008282',
    colorRoad: '#00cece',
    colorWather: '#00ffff'
  },
  {
    color: '#FFE500',
    colorText: '#938400',
    colorRoad: '#FFE500',
    colorWather: '#fff862'
  },
  {
    color: '#FF4B4B',
    colorText: '#9d0000',
    colorRoad: '#FF4B4B',
    colorWather: '#ff5555'
  }
]

const pages = {
  home: {}, kompetencje: {}, portfolio: {}, produkcja: {}, onas: {}, kogoszukamy: {}, kontakt: {}
}

export const randColors = () => {
  function shuffle (array) {
    let currentIndex = array.length
    let temporaryValue, randomIndex

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    Object.keys(pages).forEach((el, index)=>{
      pages[el].color = array[index].color
      pages[el].colorText = array[index].colorText
      pages[el].colorRoad = array[index].colorRoad
      pages[el].colorWather = array[index].colorWather
    })
  
    return pages
  }
  return shuffle(colors)

}