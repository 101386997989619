import styled  from 'styled-components';


export const ClientsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 75vh;
  max-width: 70%;
  margin 12.5vh auto;

  @media (max-width: 1023px) {
    margin-top: 0;
    height: auto;
    max-width: 90%;
  }
`

export const ClientsItemImg = styled.img`
 position: absolute;
 max-width: 80%;
 max-height: 95%;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 pointer-events: none;
 opacity: ${props => props.active ? '1' : '0.25'};
 transition: 0.3s;
`

export const ClientsItem = styled.div`
  width: 33%;
  height: 12.5vh;
  position: relative;
  @media (max-width: 1023px) {
    height: 10vh;
  }
  cursor: pointer;

  &:hover ${ClientsItemImg} {
   opacity: 1;
 }
`

export const ClientsLogoMobile = styled.div`
  @media (min-width: 1023px) {
    display: none;
  }
  margin-top: 8vh;
  /* height: 20vh; */
  margin-bottom: -2vh;
  text-align: center;
  position: relative;

  ${props => props.mobile && `
    img {
      margin: 4vw 0;
    }
  `}

  img {
    /* position: absolute; */
    max-width: 80%;
    max-height: 95%;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none; */
  }
`

export const ClientsText = styled.div`
  font-family: europa, sans-serif;
  font-weight: 300;
  color: #F9F8F6;
  font-size: 1.09375vw;
  line-height: 1.875vw;
  letter-spacing: 1.75px;

  @media (min-width: 1800px) {
    font-size: 0.82vw;
    line-height: 1.4vw;
  }

  @media (max-width: 1023px){
    font-size: 3.28vw;
    line-height: 4.6vw;
    ${
      props => props.center && `text-align: center;`
    }
  }

  p {
    font-size: 1.09375vw;
    line-height: 1.875vw;
    letter-spacing: 1.75px;

    @media (min-width: 1800px) {
      font-size: 0.82vw;
      line-height: 1.4vw;
    }
    @media (max-width: 1023px){
      font-size: 3.28vw;
      line-height: 4.6vw;
      ${
        props => props.center && `text-align: center;`
      }
    }
  }

  h1,h2,h3,h4,h5,h6,strong,b {
    font-size: 1.09375vw;
    line-height: 1.875vw;
    letter-spacing: 2px;
    display: block;
    margin-bottom: 0.5208333333vw;
    font-weight: 700;
    @media (min-width: 1800px) {
      font-size: 0.82vw;
      line-height: 1.4vw;
    }
  }
`
