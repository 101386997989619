import styled from 'styled-components';

export const Text = styled.div`
  font-family: europa, sans-serif;
  font-weight: 400;
  font-size: 1.15vw; //21px
  letter-spacing: 1.75px;
  line-height: 1.875vw; //36px
  color: ${ props => props.color ? props.color : '#F9F8F6'};
  text-align: ${ props => props.alignRight ? 'right' : 'left'};
  width: ${ props => props.width ? props.width : 'auto'};
  padding-left: ${ props => props.paddingLeft ? props.paddingLeft : '0%'};
  position: relative;
  z-index: 4;
  
  @media (max-width: 1023px){
    font-size: 3.88vw;
    line-height: 5.55vw;
    ${
      props => props.center && `text-align: center;`
    }
  }
   a {
    text-decoration: none;
    color: #005FFF;

    &:visited {
      color: #005FFF;
    }
   }
`; 

export const TextAlign = styled.div`
  text-align: ${ props => props.alignRight ? 'right' : 'left'};
  width: ${ props => props.width ? props.width : '100%'};
  float: ${ props => props.alignRight ? 'right' : 'left'};
`;