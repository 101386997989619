import React from 'react';

const Amica = () => {
  return (
    <svg width="260.368" height="70.955" viewBox="0 0 260.368 70.955">
      <g data-name="Group 113">
        <path data-name="Path 109" d="M143.919 11.39a11.346 11.346 0 1111.344 11.343 11.363 11.363 0 01-11.344-11.343"/>
        <path data-name="Path 110" d="M84.893 39.2l-.049 30.883h-15.1V30.235c10.173-2.379 13.879-2.673 20.448-2.625a33.8 33.8 0 0114.375 3.994 36.527 36.527 0 0120.987-3.747c9.737 1.412 14.61 8.619 14.61 17.337v24.89h-15.139V43.776c0-2.575-2.582-7.4-12.081-4.234l-.483.144.044 30.4h-15.1v-26.31c0-2.575-3.062-7.734-12.514-4.576"/>
        <path data-name="Path 111" d="M211.033 30.187v11.4s-7.256-3.9-13.585-3.992c-6.97-.149-12.422 3.8-12.909 10.521-.581 7.547 5.163 12.027 13.146 11.83 6.428-.142 13.348-3.553 13.348-3.553v11.738l-.441.144a40.178 40.178 0 01-14.1 2.679h-2.319c-6.179-.151-13.677-1.8-18.551-5.89a20.126 20.126 0 01-7.447-15.928 19.655 19.655 0 017.355-15.731c5.405-4.527 12.953-5.748 19.192-5.9 5.447-.1 13 1.322 15.87 2.485z"/>
        <path data-name="Path 112" d="M218.922 41.154V30.29s11.493-2.68 20.7-2.632c12.227.049 20.748 4.92 20.748 16.46v24.356c-8.622 2.09-21.091 3.064-29.316 2.09-7.986-.919-16.562-5.207-16.268-13.973.386-11.885 16.164-14.706 28.68-12.62l.882.147v-2.479c0-3.357-2.582-4.288-7.943-4.192a49.413 49.413 0 00-10.564 1.555c-2.049.541-5.508 1.659-6.918 2.152m18.747 20.212a24.381 24.381 0 006.678-.931v-7.454l-.444-.19c-6.234-2.295-13.39-.63-13.733 3.411-.337 3.7 3.364 5.163 7.5 5.163"/>
        <path data-name="Rectangle 18" d="M147.715 28.637h14.416v41.446h-14.416z"/>
        <path data-name="Path 113" d="M25.815 41.153h14.564L33.07 19.137zm18.262 11.293l-.151.2H22.063l-5.8 17.438H0L23.863 3.645h18.462l23.861 66.439H49.871z"/>
      </g>
    </svg>
  )
}

export default Amica