import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Wrap, Side, SideRight, Center } from './items.styled';
import splitter from 'text-split'
import { connect } from "react-redux";
import { fromLetterAnimation, toLetterAnimation } from '../../../letter-animation/letter-animation';


const mapStateToProps = state => {
  return {colors: state.colors, menuState: state.menu };
};

const MenuPosition = ( {menuState, right, left, center, slideOut, colors, active, opened, start } ) => {
  const [titleShow, updateTitleShow] = useState(false);
  const [leftWords, updateLeftWords] = useState(false);
  const [rightWords, updateRightWords] = useState(false);
  let leftRef = React.createRef();
  let rightRef = React.createRef();
  
  useEffect(() => {
    updateLeftWords(right ? splitter(rightRef.current) : false)
    updateRightWords(left ? splitter(leftRef.current) : false)
  }, [colors])

  useEffect(() => {
 
    setTimeout(()=> {
      updateTitleShow(true)
    }, 0)

    if ( !opened ) {
      if ( leftWords ) {
        if ( !menuState && active ) {
          toLetterAnimation(leftWords, 0, true)

          setTimeout(()=> {
            leftWords.map(el => {
              el.style.opacity = '1'
            })
          }, 3000)
        }
      }
      if ( rightWords ) {
        if ( !menuState && active ) {
          toLetterAnimation(rightWords, 0, false)

          setTimeout(()=> {
            rightWords.map(el => {
              el.style.opacity = '1'
            })
          }, 3000)
        }
      }
    } else {
      if ( leftWords ) {
        if ( menuState && start ) {
          fromLetterAnimation(leftWords, 0, true)
        }
      }
      
      if ( rightWords ) {
        if( menuState && start) {
          fromLetterAnimation(rightWords, 0, false)
        }
      }
    }
    
  }, [colors, slideOut, menuState, leftWords, rightWords, active, start, opened]);

  return (
    <Wrap className="menu-item">
      {left && <Side titleShow={titleShow} ref={leftRef}>{ReactHtmlParser(left)}</Side>}
      <Center className="center">
        <strong>{center}</strong>
        <span className="icon-signet"></span>
      </Center>
      <SideRight titleShow={titleShow} ref={rightRef}>
        {ReactHtmlParser(right)}
      </SideRight>
    </Wrap>
  )
};

export default connect(mapStateToProps, null)(MenuPosition)