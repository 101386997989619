import styled from 'styled-components';

export const SubTitle = styled.div`
  font-family: europa, sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 1.875vw; //36px
  line-height: 2.29vw; //44px
  color: #F9F8F6;
  padding-bottom: 1.3vw; //25px
  text-align: ${ props => props.alignRight ? 'right' : 'left'};

  @media (max-width: 1023px){
    text-align: left !important;
  }

  @media (max-width: 1023px){
    font-size: 5.55vw;
    line-height: 6.94vw;
    padding-bottom: 3.61vw;
  }
`

export const SubTitleNumber = styled.div`
  display: inline-block;
  color: ${ props => props.color ? props.color : '#F9F8F6'};
  padding-right: 0.5em;
`
