export const ADD_COLORS = "ADD_COLORS";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const ARTICLES = "ARTICLES";
export const POMARANCZA = "POMARANCZA";
export const PRODUCTION = "PRODUCTION";
export const RODO = "RODO";
export const ABOUTUSLIST = "ABOUTUSLIST";
export const ACTIVEABOUTUS = "ACTIVEABOUTUS";
export const CAREERS = "CAREERS";
export const HOMEANIMATION = "HOMEANIMATION";
export const LOADING = "LOADING";
export const MENUICON = "MENUICON";
export const LANDINGS = "LANDINGS";
export const CONTACT = "CONTACT";
export const INTRO = "INTRO";
export const HOMEPOSITION = "HOMEPOSITION";
export const LOGOS = "LOGOS";
export const HOMESCROLL = "HOMESCROLL";
export const MENUCLOSE = "MENUCLOSE";
export const ONSCROLLPRODUCTION = "ONSCROLLPRODUCTION";
export const COOKIESBOX = "COOKIESBOX";
export const CLIENTS = "CLIENTS";