export const API_KEY_GOOGLE_MAPS = 'AIzaSyAKti9S2dgpszkgY95SrSGDWGtHond2Wu8';
export const INITIAL_COORDINATES_GOOGLE_MAPS = {
  lat: 52.4251063,
  lng: 16.8593464
};
export const POMARANCZA_COORDINATES = {
  lat: 52.4246063,
  lng: 16.8581633,
}
export const API_URL = 'https://wp.pomarancza.pl/'
export const API_PAGES = '/wp-json/acf/v3/pages'
export const API_CLIENTS = '/wp-json/acf/v3/clients?per_page=100'
export const API_ARTICLES = '/wp-json/acf/v3/posts?per_page=100'
export const API_POMARANCZA = '/wp-json/acf/v3/pages/13'
export const API_PRODUCTION = '/wp-json/acf/v3/pages/15'
export const API_LANDINGS = '/wp-json/acf/v3/landings?per_page=100'

export const SITE_URL = 'https://pomarancza.pl/'

export const MIN_ANIMATION = 2.4