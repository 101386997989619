import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export const WrapAllHome = styled.div`
  height: ${ isMobile ? 'calc(var(--vh, 1vh) * 100)' : '100vh'};
  overflow: hidden;
  overscroll-behavior: none;
`

export const HomeMobile = styled.div`
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${ props => props.slideUp ? 'calc(-100px)' : '35vh' };
  transition: .5s;
  display: none;
  @media (max-width: 1023px) {
    display: flex;
  }

  svg {
    width: 21.11vw;
    height: 27.77vw;
    transition: .5s;
    transform: ${ props => props.slideUp ? 'translateY(55.5vw)' : '0' };
    opacity: ${ props => props.slideUp ? '0' : '1' }
  }
`

export const HomeMobileTitle = styled.div`
  font-family: europa, sans-serif;
  font-size: 9.16vw;
  line-height: 1.4;
  text-align: center;
  width: 100%;
  padding-top: 17.7vw;
  color: #F9F8F6;
`

export const HomeMobileTitleMain = styled.div`
  font-family: Intro, sans-serif;
  color: ${ props => props.color ? props.color : '#F9F8F6' };
  transition: .3s;
`

export const HomeMobileBottom = styled.div`
  position: absolute;
  bottom: ${ props => props.slideUp ? '6.94vw' : '-50vh' };
  transition: bottom .3s;
  font-family: europa, sans-serif;
  font-weight: 700;
  font-size: 4.16vw;
  line-height: 5.8vw;
  color: ${ props => props.color ? props.color : '#F9F8F6' }
  text-align: center;
  width: 100%;
  display: none;

  svg {
    width: 3.33vw;
    height:5.83vw;
  }

  @media (max-width: 1023px){
    display: block;
  }

  @media (max-width: 1023px) and (orientation: landscape) {
    display: none;
  }
`;

export const HomeMobileBottomText = styled.div`
  padding-bottom: 2.05vw;

  @media (min-height:767px) and (max-width: 1023px) {
    padding-bottom: 2vw;
  }
`;