import styled  from 'styled-components';

export const MenuIconWiz = styled.div`
  width: 2.135vw;
  height: 1.4vw;
  position: ${ props => props.isAbsolute ? 'absolute' : 'fixed'};
  top: 2.6vw;
  left: 50vw;
  transform: translateX(-50%);
  z-index: 5; 
  cursor: pointer;
  // mix-blend-mode: difference;

  @media (min-width: 1800px) { 
    top: 1.2vw;
  }

  @media (max-width: 1023px) {
    top: 8.33vw;
    width: 8.33vw;
    height: 2.77vw;
  }

  svg {
    width: 2.135vw;
    height: 1.4vw;

    @media (max-width: 1023px) {
      width: 8.33vw;
      height: 2.77vw;
    }

    .menu-1 {
      // width: 42px;
      width: ${ props => props.isOpened ? '42px' : '0'};
    }

    .menu-2 {
      // width: 32px;
      width: ${ props => props.isOpened ? '32px' : '0'};
    }

    &:hover {

      .menu-1 {
        width: 32px;
      }
  
      .menu-2 {
        width 42px;
      }
    }

    rect {
      transition: width ${props => props.menuClose ? '0' : '.3s'};
      fill: ${ props => props.isWhite ? '#F9F8F6' : '#232328'};
      ${
        props => props.isPortfolio && `
          fill: var(--portfolio-color-active) !important;
          transition: fill .3s, width .3s;
        `
      }
      
    }
  }

  
`;
