
import React from 'react';

const Kadefarm = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="262.369" height="87.212" viewBox="0 0 262.369 87.212">
      <g id="Group_136" data-name="Group 136" transform="translate(-320.5 -541.764)">
        <path id="Subtraction_1" data-name="Subtraction 1" d="M92.529,86.213H0V0H92.529V86.212ZM60.113,7.3,32.053,43.27l28.06,35.973H83.926L55.866,43.27,83.926,7.3H60.113ZM8.6,6.97V79.025H30.42V6.97Z" transform="translate(321 542.264)"  stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
        <path id="Path_116" data-name="Path 116" d="M156.974,0,143.907,15.5V0H131.527V40.947h12.379v-9.91l6.5-6.825,8.422,16.735h15.282L158.789,15.391,173.491,0Z" transform="translate(292.166 542.228)" />
        <path id="Path_117" data-name="Path 117" d="M207.231,10.646,211.7,25.383h-8.93ZM200.37.046l-15.282,40.91h12.923l2.032-6.751h14.339l2.033,6.751H229.7L214.272.046Z" transform="translate(280.423 542.218)" />
        <path id="Path_118" data-name="Path 118" d="M257.784,9.257c3.3,0,5.7.835,7.115,2.432s2.142,4.574,2.142,8.893c0,3.3-.327,5.663-.98,7.115a6,6,0,0,1-2.614,3.122,13.688,13.688,0,0,1-5.7.835h-3.122V9.257Zm-15.79,31.69h18.84a28.638,28.638,0,0,0,7.55-1.125,13.737,13.737,0,0,0,5.736-3.268,16.986,16.986,0,0,0,4.138-6.026c1.017-2.432,1.488-5.807,1.488-10.2a29.559,29.559,0,0,0-1.016-7.913,17.678,17.678,0,0,0-3.231-6.57,14.589,14.589,0,0,0-5.663-4.32,22.444,22.444,0,0,0-9-1.525h-18.84Z" transform="translate(267.948 542.228)"  fill-rule="evenodd"/>
        <path id="Path_119" data-name="Path 119" d="M292.625,40.946h33.4V31.654H304.966V23.595h19.022V15.246H304.966v-6.5h20.946V0H292.625Z" transform="translate(256.848 542.228)"  fill-rule="evenodd"/>
        <path id="Path_120" data-name="Path 120" d="M131.528,99.026h12.7V82.292h15.9V73.979h-15.9V66.936h18.622v-8.82H131.528Z" transform="translate(292.165 529.486)"  fill-rule="evenodd"/>
        <path id="Path_121" data-name="Path 121" d="M176.427,98.99l2.032-6.715h14.3l2.068,6.715h13.286L192.689,58.116h-13.9L163.468,98.99Zm9.22-30.274,4.464,14.7h-8.966Z" transform="translate(285.163 529.486)"  fill-rule="evenodd"/>
        <path id="Path_122" data-name="Path 122" d="M222.235,98.979h12.741V82.39H236.1a5.547,5.547,0,0,1,3.122.944,8.753,8.753,0,0,1,2.287,3.158l6.825,12.487h14.3l-6.207-11.8a14.492,14.492,0,0,0-1.743-2.577,14.007,14.007,0,0,0-2.032-2.178,13.82,13.82,0,0,0-3.3-1.489,15.626,15.626,0,0,0,4.464-1.633,11.907,11.907,0,0,0,3.558-16.516,9.64,9.64,0,0,0-5.009-3.7,30.172,30.172,0,0,0-9-1.017H222.235Zm18.3-32.6c2.323,0,3.884.363,4.755,1.052a3.71,3.71,0,0,1,1.271,3.013,3.547,3.547,0,0,1-.8,2.324,3.474,3.474,0,0,1-2.069,1.27,19.8,19.8,0,0,1-3.376.544h-5.337v-8.2Z" transform="translate(272.28 529.497)"  fill-rule="evenodd"/>
        <path id="Path_123" data-name="Path 123" d="M274.958,99.026H285.63V67.772l8.058,31.254h9.656L311.4,67.772V99.026h10.672V58.116h-17.1l-6.425,24.865-6.425-24.865h-17.17Z" transform="translate(260.721 529.486)"  fill-rule="evenodd"/>
      </g>
    </svg>
  )
}

export default Kadefarm