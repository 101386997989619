import { createGlobalStyle } from 'styled-components';


export const AmicaGlobal = createGlobalStyle`

  .kadefarm {

    &__knowledge {
      padding-bottom: 4vw;
    }
    &__donuts {
      display: flex;
      justify-content: space-around;
      padding-top: calc(65vh - 3.3vw);
      margin-bottom: 5.729vw;

      @media (max-width: 1023px){
        padding-top: 40px;
        margin-bottom: 45px;
      }
    }

    &__right {
      text-align: right !important;
    }

    &__section {
      margin-bottom: 5.8vw;
    }

    &__ranisilver {

      &__img-1 {
        margin-right: 10%;
        margin-left: 5%;
        margin-top: 4vh;
        margin-bottom: 4vh;
      }

      &__img-2 {
        margin-right: 20%;
        margin-left: 15%;
      }

      &__img-3 {
        margin-left: 7%;
        margin-right: -12%;
      }
    }

    &__declare {
      &__img-1 {
        margin-top: 1vw;
        margin-right: 10%;
        margin-left: 4%;
        margin-bottom: -6vh;
      }
    }
    
    &__posterisan {
      &__img-1 {
        margin-top: 3vw;
        margin-right: 0%;
        margin-left: 4%;
      }
    }

    &__logo {
      &__img-1 {
        margin-top: 6vw;
        margin-right: 10%;
        margin-left: 7%;
      }

      &__img-2 {
        margin-top: 6vh;
        margin-right: -5%;
        margin-left: 3%;
      }
    }
  }

  .kruszwica {

    &__kujawski {
      margin-bottom: 5.8vw;

      &__img-1 {
        margin-left: 22%;
        margin-top: 1vw;
      }

      &__text-1 {
        max-width: 40%;
        margin-top: -5vw;
      }
    }
  }

`