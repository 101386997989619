import React from 'react';

function Coccodrillo () {
  return (
    <svg version="1.1"
	 viewBox="0 0 405.4 67.5" width="405" height="67">
    <path id="Path_95" d="M219.3,41.9c-0.7,1.4-1.5,2.6-2.5,3.8c-1,1.1-2.1,2-3.4,2.8l0,0v0c-0.3,0.2-0.7,0.4-1.1,0.6
      c-0.4,0.2-0.7,0.4-1.1,0.5l0,0c-2.1,0.8-4.3,1.2-6.5,1.2l0,0c-9.2,0-16.6-7.6-16.6-17.2l0,0c0-1.1,0.1-2.2,0.3-3.3
      c0-0.2,0.1-0.3,0.1-0.5c1.3-5.3,5-9.6,9.9-11.7l0,0c1-0.4,2-0.7,3-0.9c1.1-0.2,2.1-0.3,3.2-0.3c1.6,0,3.2,0.2,4.7,0.6
      c1.5,0.4,2.8,1,4.1,1.8c1.3,0.8,2.4,1.7,3.3,2.9c1,1.2,1.8,2.5,2.4,3.9l0.2,0.4h-6l-0.1-0.1l0,0c-0.1-0.2-0.3-0.4-0.4-0.6
      c-0.1-0.2-0.3-0.3-0.5-0.5c-0.8-0.8-1.7-1.5-2.8-2l0,0c-0.7-0.4-1.5-0.6-2.3-0.8c-0.9-0.2-1.7-0.3-2.6-0.3c-2.9,0-5.7,1.2-7.7,3.3
      c-3.3,3.5-4.3,8.5-2.6,13l0,0c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6c0.6,1.1,1.4,2.2,2.3,3.1c0.2,0.1,0.3,0.3,0.5,0.4
      l0,0c4.5,3.7,11.9,3.6,15.4-1.3l0.1-0.1h6.3L219.3,41.9L219.3,41.9z M185.4,41.9c-0.7,1.4-1.5,2.6-2.5,3.8c-1,1.1-2.1,2-3.4,2.8l0,0
      v0c-0.3,0.2-0.7,0.4-1.1,0.6c-0.4,0.2-0.7,0.4-1.1,0.5l0,0c-2.1,0.8-4.3,1.2-6.5,1.2l0,0c-9.2,0-16.6-7.6-16.6-17.2l0,0
      c0-1.1,0.1-2.2,0.3-3.3c0-0.2,0.1-0.3,0.1-0.5c1.3-5.3,5-9.6,9.9-11.7l0,0c1-0.4,2-0.7,3-0.9c1.1-0.2,2.1-0.3,3.2-0.3
      c1.6,0,3.2,0.2,4.7,0.6c1.5,0.4,2.8,1,4.1,1.8c1.3,0.8,2.4,1.7,3.3,2.9c1,1.2,1.8,2.5,2.4,3.9l0.2,0.4h-6l-0.1-0.1l0,0
      c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.3-0.3-0.5-0.5c-0.8-0.8-1.7-1.5-2.8-2l0,0c-0.7-0.4-1.5-0.6-2.3-0.8c-0.9-0.2-1.7-0.3-2.6-0.3
      c-2.9,0-5.7,1.2-7.7,3.3c-3.3,3.5-4.3,8.5-2.6,13l0,0c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6c0.6,1.1,1.4,2.2,2.3,3.1
      c0.1,0.1,0.3,0.3,0.5,0.4l0,0c4.5,3.7,11.9,3.6,15.5-1.3l0.1-0.1h6.3L185.4,41.9L185.4,41.9z M116,41.9c-0.7,1.4-1.5,2.6-2.5,3.8
      c-1,1.1-2.1,2-3.4,2.8l0,0v0c-0.3,0.2-0.7,0.4-1.1,0.6c-0.4,0.2-0.7,0.4-1.1,0.5l0,0c-2.1,0.8-4.3,1.2-6.5,1.2l0,0
      c-9.2,0-16.6-7.6-16.6-17.2l0,0c0-1.1,0.1-2.2,0.3-3.3c0-0.2,0.1-0.3,0.1-0.5c1.3-5.3,5-9.6,9.9-11.7l0,0c1-0.4,2-0.7,3-0.9
      c1.1-0.2,2.1-0.3,3.2-0.3c1.6,0,3.2,0.2,4.7,0.6c1.5,0.4,2.8,1,4.1,1.8c1.3,0.8,2.4,1.7,3.3,2.9c1,1.2,1.8,2.5,2.4,3.9l0.2,0.4h-6
      l-0.1-0.1l0,0c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.3-0.3-0.5-0.5c-0.8-0.8-1.7-1.5-2.8-2l0,0c-0.7-0.4-1.5-0.6-2.3-0.8
      c-0.9-0.2-1.7-0.3-2.6-0.3c-2.9,0-5.7,1.2-7.7,3.3c-3.3,3.5-4.3,8.5-2.6,13l0,0c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6
      c0.6,1.1,1.4,2.2,2.3,3.1c0.1,0.1,0.3,0.3,0.5,0.4l0,0c4.5,3.7,11.9,3.6,15.4-1.3l0.1-0.1h6.3L116,41.9L116,41.9z M264,45.2h5.4
      c1.6,0,3.2-0.2,4.7-0.8c1.3-0.5,2.6-1.3,3.6-2.3l0,0c0.3-0.3,0.5-0.6,0.8-0.9c0.2-0.3,0.5-0.6,0.7-1c1.1-1.9,1.6-4.2,1.6-6.4
      c0-1.7-0.2-3.3-0.8-4.9c-0.5-1.4-1.3-2.6-2.4-3.6l0,0v0l0,0v0v0l0,0c-0.3-0.3-0.6-0.5-0.9-0.8c-0.3-0.2-0.7-0.5-1-0.7
      c-2.1-1.1-4.5-1.7-7-1.6H264L264,45.2L264,45.2z M268.2,50.1h-9.3V17.4h10.6c2.3,0,4.5,0.4,6.6,1.1c2,0.8,3.8,1.9,5.2,3.4v0v0l0,0
      c0.4,0.4,0.8,0.9,1.2,1.3c0.4,0.5,0.7,0.9,1,1.4c1.6,2.8,2.4,5.9,2.3,9.1l0,0c0,1.3-0.1,2.7-0.4,4c0,0.2-0.1,0.4-0.1,0.6
      c-0.3,1-0.6,2-1.1,3l0,0c-0.6,1.1-1.3,2.2-2.1,3.2c-0.8,1-1.8,1.8-2.8,2.6c-0.7,0.5-1.5,0.9-2.2,1.3c-0.8,0.4-1.6,0.7-2.4,0.9l0,0
      c-0.9,0.2-1.9,0.4-2.9,0.5C270.6,50,269.4,50.1,268.2,50.1L268.2,50.1z M294.8,34.1h2.1c1.4,0,2.8-0.1,4.1-0.4
      c0.2,0,0.4-0.1,0.6-0.1c0.8-0.2,1.6-0.5,2.3-1c0.7-0.5,1.3-1.1,1.7-1.9c0.4-0.8,0.6-1.7,0.6-2.7v0c0-0.9-0.1-1.8-0.5-2.6l-0.2-0.3
      l0,0c-0.3-0.6-0.7-1-1.3-1.4l0,0c-0.8-0.5-1.6-0.9-2.5-1c-1.2-0.3-2.5-0.4-3.7-0.4h-3.2L294.8,34.1L294.8,34.1z M294.5,50.1h-4.9
      V17.4h9.5c1.7,0,3.5,0.2,5.2,0.7l0,0c0.2,0.1,0.5,0.2,0.7,0.2c1.1,0.4,2.2,1,3.1,1.8l0,0c0.3,0.3,0.6,0.5,0.8,0.8
      c0.2,0.3,0.5,0.6,0.7,0.9c1.1,1.9,1.7,4,1.6,6.2c0,1.3-0.2,2.5-0.7,3.7c-0.4,1.1-1.1,2.2-2,3.1v0l0,0c-0.2,0.2-0.5,0.5-0.8,0.7
      c-0.3,0.2-0.5,0.4-0.8,0.6c-1.4,0.9-3,1.4-4.6,1.6l8.8,11.9l0.3,0.5H305l-0.1-0.1l-10.1-14.2v14.3L294.5,50.1z M320,50.1h-4.9V17.4
      h5.2v32.7L320,50.1z M340.8,50.1h-15.4V17.4h5.2v27.7h10.5v5L340.8,50.1z M359.7,50.1h-15.4V17.4h5.2v27.7H360v5L359.7,50.1z
      M365.2,33.6c0,1.6,0.3,3.1,0.9,4.6v0c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6c0.5,1,1.1,1.8,1.9,2.6v0h0
      c0.2,0.2,0.3,0.3,0.5,0.4s0.3,0.3,0.5,0.4l0,0c0.9,0.7,1.8,1.3,2.9,1.7l0,0c0.2,0.1,0.4,0.2,0.6,0.2s0.4,0.1,0.6,0.2
      c1,0.3,2.1,0.5,3.2,0.5l0,0c1.6,0,3.1-0.3,4.6-0.9c0.5-0.2,0.9-0.4,1.4-0.7c0.9-0.5,1.7-1.2,2.4-1.9l0,0c1.1-1.1,2-2.4,2.5-3.8
      c0.6-1.5,0.9-3.1,0.8-4.7l0,0c0-0.7-0.1-1.4-0.2-2.1c0-0.1,0-0.2-0.1-0.3c-0.1-0.6-0.3-1.1-0.5-1.7l0,0c-0.1-0.2-0.2-0.4-0.2-0.5
      s-0.2-0.3-0.3-0.5c-0.5-0.9-1.1-1.8-1.9-2.5l0,0c-0.6-0.6-1.2-1.2-1.9-1.7c-0.6-0.5-1.3-0.8-2-1.2l0,0l0,0l0,0
      c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4-0.1-0.6-0.2l0,0c-1.1-0.3-2.3-0.5-3.4-0.5c-1.5,0-3,0.3-4.4,0.8l0,0c-0.2,0.1-0.4,0.2-0.6,0.2
      s-0.4,0.2-0.6,0.3c-0.9,0.5-1.8,1.1-2.6,1.9c-0.6,0.5-1.1,1.1-1.5,1.8c-0.4,0.6-0.8,1.3-1.1,2c-0.3,0.7-0.5,1.4-0.7,2.2
      C365.3,32.1,365.2,32.8,365.2,33.6L365.2,33.6z M359.9,33.5c0-1.1,0.1-2.2,0.3-3.3c0.2-1.1,0.6-2.1,1-3.2c0.4-1,1-2,1.6-2.9
      c0.7-0.9,1.4-1.8,2.2-2.6l0,0l0,0l0,0c0.2-0.2,0.5-0.4,0.7-0.6s0.5-0.4,0.7-0.6c1.2-1,2.5-1.7,4-2.3l0,0c1-0.4,2-0.7,3.1-0.9
      c1.1-0.2,2.2-0.3,3.3-0.3l0,0c1.1,0,2.2,0.1,3.3,0.3c0.2,0,0.3,0.1,0.5,0.1c0.9,0.2,1.7,0.5,2.6,0.8l0,0c0.3,0.1,0.6,0.2,0.8,0.4
      c0.3,0.1,0.5,0.3,0.8,0.4c1.4,0.8,2.7,1.7,3.8,2.8c0.8,0.8,1.6,1.7,2.2,2.6c0.6,0.9,1.2,1.9,1.6,2.9c0.4,1,0.7,2.1,1,3.1
      c0.2,1.1,0.3,2.2,0.3,3.4v0c0,1.1-0.1,2.2-0.3,3.3c0,0.2-0.1,0.4-0.1,0.5c-0.2,0.9-0.4,1.7-0.7,2.5l0,0c-0.1,0.3-0.2,0.6-0.3,0.8
      s-0.2,0.5-0.4,0.8c-0.7,1.3-1.6,2.6-2.6,3.7l0,0c-0.8,0.9-1.7,1.7-2.7,2.4c-0.9,0.7-2,1.3-3,1.7l0,0c-1.1,0.5-2.2,0.8-3.3,1.1
      c-1.2,0.2-2.4,0.3-3.6,0.3c-1.2,0-2.4-0.1-3.6-0.4c-1.1-0.2-2.2-0.6-3.3-1.1v0c-1.1-0.5-2.1-1.1-3-1.7c-1-0.7-1.9-1.5-2.7-2.4l0,0
      c-0.2-0.2-0.4-0.4-0.6-0.7s-0.4-0.5-0.5-0.7c-0.9-1.2-1.6-2.5-2.1-3.9l0,0c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.2-0.6-0.2-0.8
      C360.1,36.7,359.9,35.1,359.9,33.5L359.9,33.5z M226.8,33.6c0,1.6,0.3,3.1,0.9,4.6v0c0.1,0.2,0.2,0.4,0.2,0.6
      c0.1,0.2,0.2,0.4,0.3,0.6c0.5,1,1.1,1.8,1.9,2.6v0l0,0c0.2,0.2,0.3,0.3,0.5,0.4s0.3,0.3,0.5,0.4l0,0c0.9,0.7,1.8,1.3,2.9,1.7l0,0
      c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0.1,0.4,0.1,0.6,0.2c1,0.3,2.1,0.5,3.2,0.5l0,0c1.6,0,3.1-0.3,4.6-0.9c0.5-0.2,0.9-0.4,1.4-0.7
      c0.9-0.5,1.7-1.2,2.4-1.9l0,0c1.1-1.1,2-2.4,2.5-3.8c0.6-1.5,0.9-3.1,0.8-4.7l0,0c0-0.7-0.1-1.4-0.2-2.1c0-0.1,0-0.2-0.1-0.3
      c-0.1-0.6-0.3-1.1-0.5-1.7l0,0c-0.1-0.2-0.2-0.4-0.2-0.5s-0.2-0.3-0.3-0.5c-0.5-0.9-1.1-1.8-1.9-2.5l0,0c-0.6-0.6-1.2-1.2-1.9-1.7
      c-0.6-0.5-1.3-0.8-2-1.2l0,0h0l0,0c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4-0.1-0.6-0.2l0,0c-1.1-0.3-2.3-0.5-3.4-0.5c-1.5,0-3,0.3-4.4,0.8
      l0,0c-0.2,0.1-0.4,0.2-0.6,0.2s-0.4,0.2-0.6,0.3c-0.9,0.5-1.8,1.1-2.5,1.9c-1.1,1.1-2,2.4-2.6,3.8c-0.3,0.7-0.5,1.4-0.7,2.2
      C226.9,32.1,226.8,32.8,226.8,33.6L226.8,33.6z M221.5,33.5c0-1.1,0.1-2.2,0.3-3.3c0.2-1.1,0.6-2.1,1-3.2c0.4-1,1-2,1.6-2.9
      c0.7-0.9,1.4-1.8,2.2-2.6l0,0l0,0l0,0c0.2-0.2,0.5-0.4,0.7-0.6s0.5-0.4,0.7-0.6c1.2-1,2.5-1.7,4-2.3l0,0c1-0.4,2-0.7,3.1-0.9
      c1.1-0.2,2.2-0.3,3.3-0.3l0,0c1.1,0,2.2,0.1,3.3,0.3c0.2,0,0.3,0.1,0.5,0.1c0.9,0.2,1.7,0.5,2.6,0.8l0,0c0.3,0.1,0.6,0.2,0.8,0.4
      c0.3,0.1,0.5,0.3,0.8,0.4c1.4,0.8,2.7,1.7,3.8,2.8c0.8,0.8,1.6,1.7,2.2,2.6c0.6,0.9,1.2,1.9,1.6,2.9c0.4,1,0.7,2.1,1,3.1
      c0.2,1.1,0.3,2.2,0.3,3.4v0c0,1.1-0.1,2.2-0.3,3.3c0,0.2-0.1,0.4-0.1,0.5c-0.2,0.9-0.4,1.7-0.7,2.5l0,0c-0.1,0.3-0.2,0.6-0.3,0.8
      c-0.1,0.3-0.2,0.5-0.4,0.8c-0.7,1.3-1.5,2.6-2.6,3.7l0,0c-0.8,0.9-1.7,1.7-2.7,2.4c-0.9,0.7-2,1.3-3,1.7l0,0
      c-1.1,0.5-2.2,0.8-3.3,1.1c-1.2,0.2-2.4,0.3-3.6,0.3c-1.2,0-2.4-0.1-3.6-0.4c-1.1-0.2-2.2-0.6-3.3-1.1v0c-1.1-0.5-2.1-1.1-3-1.7
      c-1-0.7-1.9-1.5-2.7-2.4l0,0c-0.2-0.2-0.4-0.4-0.6-0.7s-0.4-0.5-0.5-0.7c-0.9-1.2-1.6-2.5-2.1-3.9l0,0c-0.1-0.3-0.2-0.5-0.3-0.8
      c-0.1-0.3-0.2-0.6-0.2-0.8C221.7,36.7,221.5,35.1,221.5,33.5L221.5,33.5z M123.4,33.6c0,1.6,0.3,3.1,0.8,4.6v0
      c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.3,0.6c0.5,1,1.1,1.8,1.9,2.6v0l0,0c0.2,0.2,0.3,0.3,0.5,0.4s0.3,0.3,0.5,0.4l0,0
      c0.9,0.7,1.8,1.3,2.9,1.7l0,0c0.2,0.1,0.4,0.2,0.6,0.2l0.6,0.2c1,0.3,2.1,0.5,3.2,0.5l0,0c1.6,0,3.1-0.3,4.6-0.9
      c0.5-0.2,0.9-0.4,1.4-0.7c0.9-0.5,1.7-1.2,2.4-1.9l0,0c1.1-1.1,2-2.4,2.5-3.8c0.6-1.5,0.9-3.1,0.8-4.7l0,0c0-0.7-0.1-1.4-0.2-2.1
      l-0.1-0.3c-0.1-0.6-0.3-1.1-0.5-1.7l0,0c-0.1-0.2-0.2-0.4-0.2-0.5s-0.2-0.3-0.3-0.5c-0.5-0.9-1.1-1.8-1.9-2.5l0,0
      c-0.6-0.6-1.2-1.2-1.9-1.7c-0.6-0.5-1.3-0.8-2-1.2l0,0h0l0,0c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2-0.1-0.4-0.1-0.6-0.2l0,0
      c-1.1-0.3-2.3-0.5-3.4-0.5c-1.5,0-3,0.3-4.4,0.8l0,0c-0.2,0.1-0.4,0.2-0.6,0.2s-0.4,0.2-0.6,0.3c-0.9,0.5-1.8,1.1-2.5,1.9
      c-1.1,1.1-2,2.4-2.6,3.8c-0.3,0.7-0.5,1.4-0.7,2.2C123.5,32.1,123.4,32.8,123.4,33.6L123.4,33.6z M118.1,33.5c0-1.1,0.1-2.2,0.3-3.3
      c0.2-1.1,0.6-2.1,1-3.2c0.4-1,1-2,1.6-2.9c0.7-0.9,1.4-1.8,2.2-2.6l0,0l0,0l0,0c0.2-0.2,0.5-0.4,0.7-0.6s0.5-0.4,0.7-0.6
      c1.2-1,2.5-1.7,4-2.3l0,0c1-0.4,2-0.7,3.1-0.9c1.1-0.2,2.2-0.3,3.3-0.3l0,0c1.1,0,2.2,0.1,3.3,0.3c0.2,0,0.3,0.1,0.5,0.1
      c0.9,0.2,1.7,0.5,2.6,0.8l0,0c0.3,0.1,0.6,0.2,0.8,0.4c0.3,0.1,0.5,0.3,0.8,0.4c1.4,0.8,2.7,1.7,3.8,2.8c0.8,0.8,1.6,1.7,2.2,2.6
      c0.6,0.9,1.2,1.9,1.6,2.9c0.4,1,0.7,2.1,1,3.1c0.2,1.1,0.3,2.2,0.3,3.4v0c0,1.1-0.1,2.2-0.3,3.3c0,0.2-0.1,0.4-0.1,0.5
      c-0.2,0.9-0.4,1.7-0.7,2.5l0,0c-0.1,0.3-0.2,0.6-0.3,0.8c-0.1,0.3-0.2,0.5-0.4,0.8c-0.7,1.3-1.6,2.6-2.6,3.7l0,0
      c-0.8,0.9-1.7,1.7-2.7,2.4c-0.9,0.7-2,1.3-3,1.7l0,0c-1.1,0.5-2.2,0.8-3.3,1c-1.2,0.2-2.4,0.3-3.6,0.3c-1.2,0-2.4-0.1-3.6-0.4
      c-1.1-0.2-2.2-0.6-3.3-1.1v0c-1.1-0.5-2.1-1.1-3-1.7c-1-0.7-1.9-1.5-2.7-2.4l0,0c-0.2-0.2-0.4-0.4-0.6-0.7s-0.4-0.5-0.5-0.7
      c-0.9-1.2-1.6-2.5-2.1-3.9l0,0c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.2-0.6-0.2-0.8C118.3,36.6,118.1,35.1,118.1,33.5L118.1,33.5z"/>
    <path d="M0,0v67.5h67.5V0H0z M64.4,64.4H3.1l0-61.3h61.3V64.4z"/>
    <path d="M25.4,57.8l6.1-19.2h-3.4c0,0.2,0,0.4,0,0.6c0,2.9-1.5,5.2-3.3,5.2c-1.8,0-3.3-2.4-3.3-5.2c0-0.2,0-0.4,0-0.6h-0.4
      c0,0.2,0,0.4,0,0.6c0,2.9-1.5,5.2-3.3,5.2c-1.8,0-3.3-2.4-3.3-5.2c0-0.2,0-0.4,0-0.6h-3.1l6.1,19.2H25.4z"/>
    <polygon points="50.1,20.3 55.9,9.7 37.3,20.3 31.6,38.7 50.1,38.7 "/>
    <path id="Path_103" d="M402.2,20.7c-1.7,0-3.2-1.4-3.2-3.2c0-1.7,1.4-3.2,3.2-3.2c1.7,0,3.2,1.4,3.2,3.2c0,0,0,0,0,0
      C405.4,19.3,403.9,20.7,402.2,20.7z M402.2,15c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5
      C404.7,16.2,403.6,15,402.2,15z"/>
    <path id="Path_104" d="M403.2,18.3c-0.1-0.2-0.2-0.3-0.4-0.5l-0.1-0.1c0.2,0,0.4-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.7
      c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.3-0.4-0.4c-0.2-0.1-0.5-0.1-0.8-0.1h-1.6v3.7h0.6v-1.6h0.5c0.1,0,0.2,0,0.2,0
      c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.2,0.2,0.3,0.3,0.5l0.5,0.8h0.8L403.2,18.3z M402.8,17.1
      c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0-0.3,0.1-0.5,0.1h-0.9v-1h1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.2,0.4
      C402.9,16.9,402.9,17,402.8,17.1z"/>
    <path d="M17.9,34.6L17.9,34.6c-1.6,0-2.9,2.1-2.9,4.6c0,2.5,1.3,4.6,2.9,4.6s2.9-2.1,2.9-4.6S19.5,34.6,17.9,34.6z M18.7,41.8
      c-0.6,0-1-0.9-1-2c0-1.1,0.5-2,1-2c0.6,0,1,0.9,1,2S19.3,41.8,18.7,41.8z"/>
    <path d="M24.9,34.6c-1.6,0-2.9,2.1-2.9,4.6s1.3,4.6,2.9,4.6c1.6,0,2.9-2.1,2.9-4.6S26.5,34.6,24.9,34.6z M24.1,41.8
      c-0.6,0-1-0.9-1-2s0.5-2,1-2s1,0.9,1,2C25.1,40.9,24.7,41.8,24.1,41.8z"/>
    </svg>
  )
}

export default Coccodrillo;