import React from 'react';

function Altax () {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="195.063" height="60.612" viewBox="0 0 195.063 60.612">
      <path id="Path_107" data-name="Path 107" d="M277.12,407l-16.135,58.481h15.793l2.988-11.952h15.452l3.075,11.952h15.79L297.779,407H277.12m5.122,35.43,5.122-19.379h.087l5.206,19.379Zm49.364,9.65V405.721H317.942V452.08a31.758,31.758,0,0,0,.685,7.211,7.552,7.552,0,0,0,2.775,4.441,12.081,12.081,0,0,0,5.849,2.049,57.858,57.858,0,0,0,9.9.127V455.237q-3.5,0-4.526-.6C331.946,454.242,331.606,453.388,331.606,452.08Zm25.557-41.237h-7L343.5,422.8l-5.72,2.134v8.194h5.72v20.236a17.838,17.838,0,0,0,.725,5.206,10.224,10.224,0,0,0,2.39,4.141,10.89,10.89,0,0,0,4.227,2.685,18.4,18.4,0,0,0,6.317.941,19.743,19.743,0,0,0,4.995-.552q2.1-.556,4.65-1.5v-9.046h-5.8a4.2,4.2,0,0,1-2.946-.854,3.032,3.032,0,0,1-.9-2.3V433.123h9.645V422.8h-9.645Zm46.786,13.915a13.538,13.538,0,0,0-5.467-2.221,40.15,40.15,0,0,0-7.3-.6,69.1,69.1,0,0,0-8.495.556q-4.486.553-9.091,1.493v9.049h15.964c2.164,0,3.591.329,4.27.981a3.9,3.9,0,0,1,1.026,2.946v2.476h-9.732a23.648,23.648,0,0,0-4.781.512,12.27,12.27,0,0,0-4.568,1.965,11.016,11.016,0,0,0-3.458,4.056,14.561,14.561,0,0,0-1.368,6.784,16.7,16.7,0,0,0,.81,5.209,13.307,13.307,0,0,0,2.35,4.354,10.727,10.727,0,0,0,3.843,2.948,12.522,12.522,0,0,0,5.3,1.063,16.8,16.8,0,0,0,6.439-1.108,11.842,11.842,0,0,0,4.993-4.352h.176l1.277,4.608h12.38V436.367a18.943,18.943,0,0,0-1.153-7.211A9.977,9.977,0,0,0,403.949,424.758Zm-9.091,25.611a5.828,5.828,0,0,1-.554,2.692,5,5,0,0,1-1.408,1.706,5.315,5.315,0,0,1-1.84.894,6.969,6.969,0,0,1-1.746.258,6.623,6.623,0,0,1-3.2-.81c-.993-.545-1.5-1.636-1.5-3.291a3.754,3.754,0,0,1,1.284-3.242,5.808,5.808,0,0,1,3.5-.939h5.467ZM441.7,442.6,454.771,422.8H440.682l-6.49,10.842L428.047,422.8H412.51L425.57,442.6l-14.26,22.879h14.347l7.169-12.551,7.34,12.551h15.882Z" transform="translate(-260.985 -405.721)"/>
    </svg>
  )
}

export default Altax;